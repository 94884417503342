@charset "UTF-8";
/*!
 * Bootstrap v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}
.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #0062cc;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}
.alert-primary hr {
  border-top-color: #9fcdff;
}
.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #fff !important;
  }

  .table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
.lar,
.las,
.lab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

@font-face {
  font-family: Line Awesome Brands;
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url("../fonts/la-brands-400.eot");
  src: url("../fonts/la-brands-400.eot?#iefix") format("embedded-opentype"), url("../fonts/la-brands-400.woff2") format("woff2"), url("../fonts/la-brands-400.woff") format("woff"), url("../fonts/la-brands-400.ttf") format("truetype"), url("../fonts/la-brands-400.svg#lineawesome") format("svg");
}
.lab {
  font-family: Line Awesome Brands;
  font-weight: 400;
}

@font-face {
  font-family: Line Awesome Free;
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url("../fonts/la-regular-400.eot");
  src: url("../fonts/la-regular-400.eot?#iefix") format("embedded-opentype"), url("../fonts/la-regular-400.woff2") format("woff2"), url("../fonts/la-regular-400.woff") format("woff"), url("../fonts/la-regular-400.ttf") format("truetype"), url("../fonts/la-regular-400.svg#lineawesome") format("svg");
}
.lar {
  font-family: Line Awesome Free;
  font-weight: 400;
}

@font-face {
  font-family: Line Awesome Free;
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url("../fonts/la-solid-900.eot");
  src: url("../fonts/la-solid-900.eot?#iefix") format("embedded-opentype"), url("../fonts/la-solid-900.woff2") format("woff2"), url("../fonts/la-solid-900.woff") format("woff"), url("../fonts/la-solid-900.ttf") format("truetype"), url("../fonts/la-solid-900.svg#lineawesome") format("svg");
}
.las {
  font-family: Line Awesome Free;
  font-weight: 900;
}

.la-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -0.0667em;
}

.la-xs {
  font-size: 0.75em;
}

.la-2x {
  font-size: 1em;
}

.la-2x {
  font-size: 2em;
}

.la-3x {
  font-size: 3em;
}

.la-4x {
  font-size: 4em;
}

.la-5x {
  font-size: 5em;
}

.la-6x {
  font-size: 6em;
}

.la-7x {
  font-size: 7em;
}

.la-8x {
  font-size: 8em;
}

.la-9x {
  font-size: 9em;
}

.la-10x {
  font-size: 10em;
}

.la-fw {
  text-align: center;
  width: 1.25em;
}

.la-fw {
  width: 1.25em;
  text-align: center;
}

.la-ul {
  padding-left: 0;
  margin-left: 1.4285714286em;
  list-style-type: none;
}
.la-ul > li {
  position: relative;
}

.la-li {
  position: absolute;
  left: -2em;
  text-align: center;
  width: 1.4285714286em;
  line-height: inherit;
}
.la-li.la-lg {
  left: -1.1428571429em;
}

.la-border {
  border: solid 0.08em #eee;
  border-radius: 0.1em;
  padding: 0.2em 0.25em 0.15em;
}

.la-pull-left {
  float: left;
}

.la-pull-right {
  float: right;
}

.la.la-pull-left {
  margin-right: 0.3em;
}
.la.la-pull-right {
  margin-left: 0.3em;
}

.la.pull-left {
  margin-right: 0.3em;
}
.la.pull-right {
  margin-left: 0.3em;
}

.la-pull-left {
  float: left;
}

.la-pull-right {
  float: right;
}

.la.la-pull-left,
.las.la-pull-left,
.lar.la-pull-left,
.lal.la-pull-left,
.lab.la-pull-left {
  margin-right: 0.3em;
}

.la.la-pull-right,
.las.la-pull-right,
.lar.la-pull-right,
.lal.la-pull-right,
.lab.la-pull-right {
  margin-left: 0.3em;
}

.la-spin {
  -webkit-animation: la-spin 2s infinite linear;
  animation: la-spin 2s infinite linear;
}

.la-pulse {
  -webkit-animation: la-spin 1s infinite steps(8);
  animation: la-spin 1s infinite steps(8);
}

@-webkit-keyframes la-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes la-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.la-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.la-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.la-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

.la-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.la-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(1, -1);
  transform: scale(1, -1);
}

.la-flip-both, .la-flip-horizontal.la-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(-1, -1);
  transform: scale(-1, -1);
}

:root .la-rotate-90,
:root .la-rotate-180,
:root .la-rotate-270,
:root .la-flip-horizontal,
:root .la-flip-vertical,
:root .la-flip-both {
  -webkit-filter: none;
  filter: none;
}

.la-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em;
}

.la-stack-1x,
.la-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}

.la-stack-1x {
  line-height: inherit;
}

.la-stack-2x {
  font-size: 2em;
}

.la-inverse {
  color: #fff;
}

.la-500px:before {
  content: "";
}

.la-accessible-icon:before {
  content: "";
}

.la-accusoft:before {
  content: "";
}

.la-acquisitions-incorporated:before {
  content: "";
}

.la-ad:before {
  content: "";
}

.la-address-book:before {
  content: "";
}

.la-address-card:before {
  content: "";
}

.la-adjust:before {
  content: "";
}

.la-adn:before {
  content: "";
}

.la-adobe:before {
  content: "";
}

.la-adversal:before {
  content: "";
}

.la-affiliatetheme:before {
  content: "";
}

.la-air-freshener:before {
  content: "";
}

.la-airbnb:before {
  content: "";
}

.la-algolia:before {
  content: "";
}

.la-align-center:before {
  content: "";
}

.la-align-justify:before {
  content: "";
}

.la-align-left:before {
  content: "";
}

.la-align-right:before {
  content: "";
}

.la-alipay:before {
  content: "";
}

.la-allergies:before {
  content: "";
}

.la-amazon:before {
  content: "";
}

.la-amazon-pay:before {
  content: "";
}

.la-ambulance:before {
  content: "";
}

.la-american-sign-language-interpreting:before {
  content: "";
}

.la-amilia:before {
  content: "";
}

.la-anchor:before {
  content: "";
}

.la-android:before {
  content: "";
}

.la-angellist:before {
  content: "";
}

.la-angle-double-down:before {
  content: "";
}

.la-angle-double-left:before {
  content: "";
}

.la-angle-double-right:before {
  content: "";
}

.la-angle-double-up:before {
  content: "";
}

.la-angle-down:before {
  content: "";
}

.la-angle-left:before {
  content: "";
}

.la-angle-right:before {
  content: "";
}

.la-angle-up:before {
  content: "";
}

.la-angry:before {
  content: "";
}

.la-angrycreative:before {
  content: "";
}

.la-angular:before {
  content: "";
}

.la-ankh:before {
  content: "";
}

.la-app-store:before {
  content: "";
}

.la-app-store-ios:before {
  content: "";
}

.la-apper:before {
  content: "";
}

.la-apple:before {
  content: "";
}

.la-apple-alt:before {
  content: "";
}

.la-apple-pay:before {
  content: "";
}

.la-archive:before {
  content: "";
}

.la-archway:before {
  content: "";
}

.la-arrow-alt-circle-down:before {
  content: "";
}

.la-arrow-alt-circle-left:before {
  content: "";
}

.la-arrow-alt-circle-right:before {
  content: "";
}

.la-arrow-alt-circle-up:before {
  content: "";
}

.la-arrow-circle-down:before {
  content: "";
}

.la-arrow-circle-left:before {
  content: "";
}

.la-arrow-circle-right:before {
  content: "";
}

.la-arrow-circle-up:before {
  content: "";
}

.la-arrow-down:before {
  content: "";
}

.la-arrow-left:before {
  content: "";
}

.la-arrow-right:before {
  content: "";
}

.la-arrow-up:before {
  content: "";
}

.la-arrows-alt:before {
  content: "";
}

.la-arrows-alt-h:before {
  content: "";
}

.la-arrows-alt-v:before {
  content: "";
}

.la-artstation:before {
  content: "";
}

.la-assistive-listening-systems:before {
  content: "";
}

.la-asterisk:before {
  content: "";
}

.la-asymmetrik:before {
  content: "";
}

.la-at:before {
  content: "";
}

.la-atlas:before {
  content: "";
}

.la-atlassian:before {
  content: "";
}

.la-atom:before {
  content: "";
}

.la-audible:before {
  content: "";
}

.la-audio-description:before {
  content: "";
}

.la-autoprefixer:before {
  content: "";
}

.la-avianex:before {
  content: "";
}

.la-aviato:before {
  content: "";
}

.la-award:before {
  content: "";
}

.la-aws:before {
  content: "";
}

.la-baby:before {
  content: "";
}

.la-baby-carriage:before {
  content: "";
}

.la-backspace:before {
  content: "";
}

.la-backward:before {
  content: "";
}

.la-bacon:before {
  content: "";
}

.la-balance-scale:before {
  content: "";
}

.la-balance-scale-left:before {
  content: "";
}

.la-balance-scale-right:before {
  content: "";
}

.la-ban:before {
  content: "";
}

.la-band-aid:before {
  content: "";
}

.la-bandcamp:before {
  content: "";
}

.la-barcode:before {
  content: "";
}

.la-bars:before {
  content: "";
}

.la-baseball-ball:before {
  content: "";
}

.la-basketball-ball:before {
  content: "";
}

.la-bath:before {
  content: "";
}

.la-battery-empty:before {
  content: "";
}

.la-battery-full:before {
  content: "";
}

.la-battery-half:before {
  content: "";
}

.la-battery-quarter:before {
  content: "";
}

.la-battery-three-quarters:before {
  content: "";
}

.la-battle-net:before {
  content: "";
}

.la-bed:before {
  content: "";
}

.la-beer:before {
  content: "";
}

.la-behance:before {
  content: "";
}

.la-behance-square:before {
  content: "";
}

.la-bell:before {
  content: "";
}

.la-bell-slash:before {
  content: "";
}

.la-bezier-curve:before {
  content: "";
}

.la-bible:before {
  content: "";
}

.la-bicycle:before {
  content: "";
}

.la-biking:before {
  content: "";
}

.la-bimobject:before {
  content: "";
}

.la-binoculars:before {
  content: "";
}

.la-biohazard:before {
  content: "";
}

.la-birthday-cake:before {
  content: "";
}

.la-bitbucket:before {
  content: "";
}

.la-bitcoin:before {
  content: "";
}

.la-bity:before {
  content: "";
}

.la-black-tie:before {
  content: "";
}

.la-blackberry:before {
  content: "";
}

.la-blender:before {
  content: "";
}

.la-blender-phone:before {
  content: "";
}

.la-blind:before {
  content: "";
}

.la-blog:before {
  content: "";
}

.la-blogger:before {
  content: "";
}

.la-blogger-b:before {
  content: "";
}

.la-bluetooth:before {
  content: "";
}

.la-bluetooth-b:before {
  content: "";
}

.la-bold:before {
  content: "";
}

.la-bolt:before {
  content: "";
}

.la-bomb:before {
  content: "";
}

.la-bone:before {
  content: "";
}

.la-bong:before {
  content: "";
}

.la-book:before {
  content: "";
}

.la-book-dead:before {
  content: "";
}

.la-book-medical:before {
  content: "";
}

.la-book-open:before {
  content: "";
}

.la-book-reader:before {
  content: "";
}

.la-bookmark:before {
  content: "";
}

.la-bootstrap:before {
  content: "";
}

.la-border-all:before {
  content: "";
}

.la-border-none:before {
  content: "";
}

.la-border-style:before {
  content: "";
}

.la-bowling-ball:before {
  content: "";
}

.la-box:before {
  content: "";
}

.la-box-open:before {
  content: "";
}

.la-boxes:before {
  content: "";
}

.la-braille:before {
  content: "";
}

.la-brain:before {
  content: "";
}

.la-bread-slice:before {
  content: "";
}

.la-briefcase:before {
  content: "";
}

.la-briefcase-medical:before {
  content: "";
}

.la-broadcast-tower:before {
  content: "";
}

.la-broom:before {
  content: "";
}

.la-brush:before {
  content: "";
}

.la-btc:before {
  content: "";
}

.la-buffer:before {
  content: "";
}

.la-bug:before {
  content: "";
}

.la-building:before {
  content: "";
}

.la-bullhorn:before {
  content: "";
}

.la-bullseye:before {
  content: "";
}

.la-burn:before {
  content: "";
}

.la-buromobelexperte:before {
  content: "";
}

.la-bus:before {
  content: "";
}

.la-bus-alt:before {
  content: "";
}

.la-business-time:before {
  content: "";
}

.la-buysellads:before {
  content: "";
}

.la-calculator:before {
  content: "";
}

.la-calendar:before {
  content: "";
}

.la-calendar-alt:before {
  content: "";
}

.la-calendar-check:before {
  content: "";
}

.la-calendar-day:before {
  content: "";
}

.la-calendar-minus:before {
  content: "";
}

.la-calendar-plus:before {
  content: "";
}

.la-calendar-times:before {
  content: "";
}

.la-calendar-week:before {
  content: "";
}

.la-camera:before {
  content: "";
}

.la-camera-retro:before {
  content: "";
}

.la-campground:before {
  content: "";
}

.la-canadian-maple-leaf:before {
  content: "";
}

.la-candy-cane:before {
  content: "";
}

.la-cannabis:before {
  content: "";
}

.la-capsules:before {
  content: "";
}

.la-car:before {
  content: "";
}

.la-car-alt:before {
  content: "";
}

.la-car-battery:before {
  content: "";
}

.la-car-crash:before {
  content: "";
}

.la-car-side:before {
  content: "";
}

.la-caret-down:before {
  content: "";
}

.la-caret-left:before {
  content: "";
}

.la-caret-right:before {
  content: "";
}

.la-caret-square-down:before {
  content: "";
}

.la-caret-square-left:before {
  content: "";
}

.la-caret-square-right:before {
  content: "";
}

.la-caret-square-up:before {
  content: "";
}

.la-caret-up:before {
  content: "";
}

.la-carrot:before {
  content: "";
}

.la-cart-arrow-down:before {
  content: "";
}

.la-cart-plus:before {
  content: "";
}

.la-cash-register:before {
  content: "";
}

.la-cat:before {
  content: "";
}

.la-cc-amazon-pay:before {
  content: "";
}

.la-cc-amex:before {
  content: "";
}

.la-cc-apple-pay:before {
  content: "";
}

.la-cc-diners-club:before {
  content: "";
}

.la-cc-discover:before {
  content: "";
}

.la-cc-jcb:before {
  content: "";
}

.la-cc-mastercard:before {
  content: "";
}

.la-cc-paypal:before {
  content: "";
}

.la-cc-stripe:before {
  content: "";
}

.la-cc-visa:before {
  content: "";
}

.la-centercode:before {
  content: "";
}

.la-centos:before {
  content: "";
}

.la-certificate:before {
  content: "";
}

.la-chair:before {
  content: "";
}

.la-chalkboard:before {
  content: "";
}

.la-chalkboard-teacher:before {
  content: "";
}

.la-charging-station:before {
  content: "";
}

.la-chart-area:before {
  content: "";
}

.la-chart-bar:before {
  content: "";
}

.la-chart-line:before {
  content: "";
}

.la-chart-pie:before {
  content: "";
}

.la-check:before {
  content: "";
}

.la-check-circle:before {
  content: "";
}

.la-check-double:before {
  content: "";
}

.la-check-square:before {
  content: "";
}

.la-cheese:before {
  content: "";
}

.la-chess:before {
  content: "";
}

.la-chess-bishop:before {
  content: "";
}

.la-chess-board:before {
  content: "";
}

.la-chess-king:before {
  content: "";
}

.la-chess-knight:before {
  content: "";
}

.la-chess-pawn:before {
  content: "";
}

.la-chess-queen:before {
  content: "";
}

.la-chess-rook:before {
  content: "";
}

.la-chevron-circle-down:before {
  content: "";
}

.la-chevron-circle-left:before {
  content: "";
}

.la-chevron-circle-right:before {
  content: "";
}

.la-chevron-circle-up:before {
  content: "";
}

.la-chevron-down:before {
  content: "";
}

.la-chevron-left:before {
  content: "";
}

.la-chevron-right:before {
  content: "";
}

.la-chevron-up:before {
  content: "";
}

.la-child:before {
  content: "";
}

.la-chrome:before {
  content: "";
}

.la-chromecast:before {
  content: "";
}

.la-church:before {
  content: "";
}

.la-circle:before {
  content: "";
}

.la-circle-notch:before {
  content: "";
}

.la-city:before {
  content: "";
}

.la-clinic-medical:before {
  content: "";
}

.la-clipboard:before {
  content: "";
}

.la-clipboard-check:before {
  content: "";
}

.la-clipboard-list:before {
  content: "";
}

.la-clock:before {
  content: "";
}

.la-clone:before {
  content: "";
}

.la-closed-captioning:before {
  content: "";
}

.la-cloud:before {
  content: "";
}

.la-cloud-download-alt:before {
  content: "";
}

.la-cloud-meatball:before {
  content: "";
}

.la-cloud-moon:before {
  content: "";
}

.la-cloud-moon-rain:before {
  content: "";
}

.la-cloud-rain:before {
  content: "";
}

.la-cloud-showers-heavy:before {
  content: "";
}

.la-cloud-sun:before {
  content: "";
}

.la-cloud-sun-rain:before {
  content: "";
}

.la-cloud-upload-alt:before {
  content: "";
}

.la-cloudscale:before {
  content: "";
}

.la-cloudsmith:before {
  content: "";
}

.la-cloudversify:before {
  content: "";
}

.la-cocktail:before {
  content: "";
}

.la-code:before {
  content: "";
}

.la-code-branch:before {
  content: "";
}

.la-codepen:before {
  content: "";
}

.la-codiepie:before {
  content: "";
}

.la-coffee:before {
  content: "";
}

.la-cog:before {
  content: "";
}

.la-cogs:before {
  content: "";
}

.la-coins:before {
  content: "";
}

.la-columns:before {
  content: "";
}

.la-comment:before {
  content: "";
}

.la-comment-alt:before {
  content: "";
}

.la-comment-dollar:before {
  content: "";
}

.la-comment-dots:before {
  content: "";
}

.la-comment-medical:before {
  content: "";
}

.la-comment-slash:before {
  content: "";
}

.la-comments:before {
  content: "";
}

.la-comments-dollar:before {
  content: "";
}

.la-compact-disc:before {
  content: "";
}

.la-compass:before {
  content: "";
}

.la-compress:before {
  content: "";
}

.la-compress-arrows-alt:before {
  content: "";
}

.la-concierge-bell:before {
  content: "";
}

.la-confluence:before {
  content: "";
}

.la-connectdevelop:before {
  content: "";
}

.la-contao:before {
  content: "";
}

.la-cookie:before {
  content: "";
}

.la-cookie-bite:before {
  content: "";
}

.la-copy:before {
  content: "";
}

.la-copyright:before {
  content: "";
}

.la-cotton-bureau:before {
  content: "";
}

.la-couch:before {
  content: "";
}

.la-cpanel:before {
  content: "";
}

.la-creative-commons:before {
  content: "";
}

.la-creative-commons-by:before {
  content: "";
}

.la-creative-commons-nc:before {
  content: "";
}

.la-creative-commons-nc-eu:before {
  content: "";
}

.la-creative-commons-nc-jp:before {
  content: "";
}

.la-creative-commons-nd:before {
  content: "";
}

.la-creative-commons-pd:before {
  content: "";
}

.la-creative-commons-pd-alt:before {
  content: "";
}

.la-creative-commons-remix:before {
  content: "";
}

.la-creative-commons-sa:before {
  content: "";
}

.la-creative-commons-sampling:before {
  content: "";
}

.la-creative-commons-sampling-plus:before {
  content: "";
}

.la-creative-commons-share:before {
  content: "";
}

.la-creative-commons-zero:before {
  content: "";
}

.la-credit-card:before {
  content: "";
}

.la-critical-role:before {
  content: "";
}

.la-crop:before {
  content: "";
}

.la-crop-alt:before {
  content: "";
}

.la-cross:before {
  content: "";
}

.la-crosshairs:before {
  content: "";
}

.la-crow:before {
  content: "";
}

.la-crown:before {
  content: "";
}

.la-crutch:before {
  content: "";
}

.la-css3:before {
  content: "";
}

.la-css3-alt:before {
  content: "";
}

.la-cube:before {
  content: "";
}

.la-cubes:before {
  content: "";
}

.la-cut:before {
  content: "";
}

.la-cuttlefish:before {
  content: "";
}

.la-d-and-d:before {
  content: "";
}

.la-d-and-d-beyond:before {
  content: "";
}

.la-dashcube:before {
  content: "";
}

.la-database:before {
  content: "";
}

.la-deaf:before {
  content: "";
}

.la-delicious:before {
  content: "";
}

.la-democrat:before {
  content: "";
}

.la-deploydog:before {
  content: "";
}

.la-deskpro:before {
  content: "";
}

.la-desktop:before {
  content: "";
}

.la-dev:before {
  content: "";
}

.la-deviantart:before {
  content: "";
}

.la-dharmachakra:before {
  content: "";
}

.la-dhl:before {
  content: "";
}

.la-diagnoses:before {
  content: "";
}

.la-diaspora:before {
  content: "";
}

.la-dice:before {
  content: "";
}

.la-dice-d20:before {
  content: "";
}

.la-dice-d6:before {
  content: "";
}

.la-dice-five:before {
  content: "";
}

.la-dice-four:before {
  content: "";
}

.la-dice-one:before {
  content: "";
}

.la-dice-six:before {
  content: "";
}

.la-dice-three:before {
  content: "";
}

.la-dice-two:before {
  content: "";
}

.la-digg:before {
  content: "";
}

.la-digital-ocean:before {
  content: "";
}

.la-digital-tachograph:before {
  content: "";
}

.la-directions:before {
  content: "";
}

.la-discord:before {
  content: "";
}

.la-discourse:before {
  content: "";
}

.la-divide:before {
  content: "";
}

.la-dizzy:before {
  content: "";
}

.la-dna:before {
  content: "";
}

.la-dochub:before {
  content: "";
}

.la-docker:before {
  content: "";
}

.la-dog:before {
  content: "";
}

.la-dollar-sign:before {
  content: "";
}

.la-dolly:before {
  content: "";
}

.la-dolly-flatbed:before {
  content: "";
}

.la-donate:before {
  content: "";
}

.la-door-closed:before {
  content: "";
}

.la-door-open:before {
  content: "";
}

.la-dot-circle:before {
  content: "";
}

.la-dove:before {
  content: "";
}

.la-download:before {
  content: "";
}

.la-draft2digital:before {
  content: "";
}

.la-drafting-compass:before {
  content: "";
}

.la-dragon:before {
  content: "";
}

.la-draw-polygon:before {
  content: "";
}

.la-dribbble:before {
  content: "";
}

.la-dribbble-square:before {
  content: "";
}

.la-dropbox:before {
  content: "";
}

.la-drum:before {
  content: "";
}

.la-drum-steelpan:before {
  content: "";
}

.la-drumstick-bite:before {
  content: "";
}

.la-drupal:before {
  content: "";
}

.la-dumbbell:before {
  content: "";
}

.la-dumpster:before {
  content: "";
}

.la-dumpster-fire:before {
  content: "";
}

.la-dungeon:before {
  content: "";
}

.la-dyalog:before {
  content: "";
}

.la-earlybirds:before {
  content: "";
}

.la-ebay:before {
  content: "";
}

.la-edge:before {
  content: "";
}

.la-edit:before {
  content: "";
}

.la-egg:before {
  content: "";
}

.la-eject:before {
  content: "";
}

.la-elementor:before {
  content: "";
}

.la-ellipsis-h:before {
  content: "";
}

.la-ellipsis-v:before {
  content: "";
}

.la-ello:before {
  content: "";
}

.la-ember:before {
  content: "";
}

.la-empire:before {
  content: "";
}

.la-envelope:before {
  content: "";
}

.la-envelope-open:before {
  content: "";
}

.la-envelope-open-text:before {
  content: "";
}

.la-envelope-square:before {
  content: "";
}

.la-envira:before {
  content: "";
}

.la-equals:before {
  content: "";
}

.la-eraser:before {
  content: "";
}

.la-erlang:before {
  content: "";
}

.la-ethereum:before {
  content: "";
}

.la-ethernet:before {
  content: "";
}

.la-etsy:before {
  content: "";
}

.la-euro-sign:before {
  content: "";
}

.la-evernote:before {
  content: "";
}

.la-exchange-alt:before {
  content: "";
}

.la-exclamation:before {
  content: "";
}

.la-exclamation-circle:before {
  content: "";
}

.la-exclamation-triangle:before {
  content: "";
}

.la-expand:before {
  content: "";
}

.la-expand-arrows-alt:before {
  content: "";
}

.la-expeditedssl:before {
  content: "";
}

.la-external-link-alt:before {
  content: "";
}

.la-external-link-square-alt:before {
  content: "";
}

.la-eye:before {
  content: "";
}

.la-eye-dropper:before {
  content: "";
}

.la-eye-slash:before {
  content: "";
}

.la-facebook:before {
  content: "";
}

.la-facebook-f:before {
  content: "";
}

.la-facebook-messenger:before {
  content: "";
}

.la-facebook-square:before {
  content: "";
}

.la-fan:before {
  content: "";
}

.la-fantasy-flight-games:before {
  content: "";
}

.la-fast-backward:before {
  content: "";
}

.la-fast-forward:before {
  content: "";
}

.la-fax:before {
  content: "";
}

.la-feather:before {
  content: "";
}

.la-feather-alt:before {
  content: "";
}

.la-fedex:before {
  content: "";
}

.la-fedora:before {
  content: "";
}

.la-female:before {
  content: "";
}

.la-fighter-jet:before {
  content: "";
}

.la-figma:before {
  content: "";
}

.la-file:before {
  content: "";
}

.la-file-alt:before {
  content: "";
}

.la-file-archive:before {
  content: "";
}

.la-file-audio:before {
  content: "";
}

.la-file-code:before {
  content: "";
}

.la-file-contract:before {
  content: "";
}

.la-file-csv:before {
  content: "";
}

.la-file-download:before {
  content: "";
}

.la-file-excel:before {
  content: "";
}

.la-file-export:before {
  content: "";
}

.la-file-image:before {
  content: "";
}

.la-file-import:before {
  content: "";
}

.la-file-invoice:before {
  content: "";
}

.la-file-invoice-dollar:before {
  content: "";
}

.la-file-medical:before {
  content: "";
}

.la-file-medical-alt:before {
  content: "";
}

.la-file-pdf:before {
  content: "";
}

.la-file-powerpoint:before {
  content: "";
}

.la-file-prescription:before {
  content: "";
}

.la-file-signature:before {
  content: "";
}

.la-file-upload:before {
  content: "";
}

.la-file-video:before {
  content: "";
}

.la-file-word:before {
  content: "";
}

.la-fill:before {
  content: "";
}

.la-fill-drip:before {
  content: "";
}

.la-film:before {
  content: "";
}

.la-filter:before {
  content: "";
}

.la-fingerprint:before {
  content: "";
}

.la-fire:before {
  content: "";
}

.la-fire-alt:before {
  content: "";
}

.la-fire-extinguisher:before {
  content: "";
}

.la-firefox:before {
  content: "";
}

.la-first-aid:before {
  content: "";
}

.la-first-order:before {
  content: "";
}

.la-first-order-alt:before {
  content: "";
}

.la-firstdraft:before {
  content: "";
}

.la-fish:before {
  content: "";
}

.la-fist-raised:before {
  content: "";
}

.la-flag:before {
  content: "";
}

.la-flag-checkered:before {
  content: "";
}

.la-flag-usa:before {
  content: "";
}

.la-flask:before {
  content: "";
}

.la-flickr:before {
  content: "";
}

.la-flipboard:before {
  content: "";
}

.la-flushed:before {
  content: "";
}

.la-fly:before {
  content: "";
}

.la-folder:before {
  content: "";
}

.la-folder-minus:before {
  content: "";
}

.la-folder-open:before {
  content: "";
}

.la-folder-plus:before {
  content: "";
}

.la-font:before {
  content: "";
}

.la-font-awesome:before {
  content: "";
}

.la-font-awesome-alt:before {
  content: "";
}

.la-font-awesome-flag:before {
  content: "";
}

.la-fonticons:before {
  content: "";
}

.la-fonticons-fi:before {
  content: "";
}

.la-football-ball:before {
  content: "";
}

.la-fort-awesome:before {
  content: "";
}

.la-fort-awesome-alt:before {
  content: "";
}

.la-forumbee:before {
  content: "";
}

.la-forward:before {
  content: "";
}

.la-foursquare:before {
  content: "";
}

.la-free-code-camp:before {
  content: "";
}

.la-freebsd:before {
  content: "";
}

.la-frog:before {
  content: "";
}

.la-frown:before {
  content: "";
}

.la-frown-open:before {
  content: "";
}

.la-fulcrum:before {
  content: "";
}

.la-funnel-dollar:before {
  content: "";
}

.la-futbol:before {
  content: "";
}

.la-galactic-republic:before {
  content: "";
}

.la-galactic-senate:before {
  content: "";
}

.la-gamepad:before {
  content: "";
}

.la-gas-pump:before {
  content: "";
}

.la-gavel:before {
  content: "";
}

.la-gem:before {
  content: "";
}

.la-genderless:before {
  content: "";
}

.la-get-pocket:before {
  content: "";
}

.la-gg:before {
  content: "";
}

.la-gg-circle:before {
  content: "";
}

.la-ghost:before {
  content: "";
}

.la-gift:before {
  content: "";
}

.la-gifts:before {
  content: "";
}

.la-git:before {
  content: "";
}

.la-git-alt:before {
  content: "";
}

.la-git-square:before {
  content: "";
}

.la-github:before {
  content: "";
}

.la-github-alt:before {
  content: "";
}

.la-github-square:before {
  content: "";
}

.la-gitkraken:before {
  content: "";
}

.la-gitlab:before {
  content: "";
}

.la-gitter:before {
  content: "";
}

.la-glass-cheers:before {
  content: "";
}

.la-glass-martini:before {
  content: "";
}

.la-glass-martini-alt:before {
  content: "";
}

.la-glass-whiskey:before {
  content: "";
}

.la-glasses:before {
  content: "";
}

.la-glide:before {
  content: "";
}

.la-glide-g:before {
  content: "";
}

.la-globe:before {
  content: "";
}

.la-globe-africa:before {
  content: "";
}

.la-globe-americas:before {
  content: "";
}

.la-globe-asia:before {
  content: "";
}

.la-globe-europe:before {
  content: "";
}

.la-gofore:before {
  content: "";
}

.la-golf-ball:before {
  content: "";
}

.la-goodreads:before {
  content: "";
}

.la-goodreads-g:before {
  content: "";
}

.la-google:before {
  content: "";
}

.la-google-drive:before {
  content: "";
}

.la-google-play:before {
  content: "";
}

.la-google-plus:before {
  content: "";
}

.la-google-plus-g:before {
  content: "";
}

.la-google-plus-square:before {
  content: "";
}

.la-google-wallet:before {
  content: "";
}

.la-gopuram:before {
  content: "";
}

.la-graduation-cap:before {
  content: "";
}

.la-gratipay:before {
  content: "";
}

.la-grav:before {
  content: "";
}

.la-greater-than:before {
  content: "";
}

.la-greater-than-equal:before {
  content: "";
}

.la-grimace:before {
  content: "";
}

.la-grin:before {
  content: "";
}

.la-grin-alt:before {
  content: "";
}

.la-grin-beam:before {
  content: "";
}

.la-grin-beam-sweat:before {
  content: "";
}

.la-grin-hearts:before {
  content: "";
}

.la-grin-squint:before {
  content: "";
}

.la-grin-squint-tears:before {
  content: "";
}

.la-grin-stars:before {
  content: "";
}

.la-grin-tears:before {
  content: "";
}

.la-grin-tongue:before {
  content: "";
}

.la-grin-tongue-squint:before {
  content: "";
}

.la-grin-tongue-wink:before {
  content: "";
}

.la-grin-wink:before {
  content: "";
}

.la-grip-horizontal:before {
  content: "";
}

.la-grip-lines:before {
  content: "";
}

.la-grip-lines-vertical:before {
  content: "";
}

.la-grip-vertical:before {
  content: "";
}

.la-gripfire:before {
  content: "";
}

.la-grunt:before {
  content: "";
}

.la-guitar:before {
  content: "";
}

.la-gulp:before {
  content: "";
}

.la-h-square:before {
  content: "";
}

.la-hacker-news:before {
  content: "";
}

.la-hacker-news-square:before {
  content: "";
}

.la-hackerrank:before {
  content: "";
}

.la-hamburger:before {
  content: "";
}

.la-hammer:before {
  content: "";
}

.la-hamsa:before {
  content: "";
}

.la-hand-holding:before {
  content: "";
}

.la-hand-holding-heart:before {
  content: "";
}

.la-hand-holding-usd:before {
  content: "";
}

.la-hand-lizard:before {
  content: "";
}

.la-hand-middle-finger:before {
  content: "";
}

.la-hand-paper:before {
  content: "";
}

.la-hand-peace:before {
  content: "";
}

.la-hand-point-down:before {
  content: "";
}

.la-hand-point-left:before {
  content: "";
}

.la-hand-point-right:before {
  content: "";
}

.la-hand-point-up:before {
  content: "";
}

.la-hand-pointer:before {
  content: "";
}

.la-hand-rock:before {
  content: "";
}

.la-hand-scissors:before {
  content: "";
}

.la-hand-spock:before {
  content: "";
}

.la-hands:before {
  content: "";
}

.la-hands-helping:before {
  content: "";
}

.la-handshake:before {
  content: "";
}

.la-hanukiah:before {
  content: "";
}

.la-hard-hat:before {
  content: "";
}

.la-hashtag:before {
  content: "";
}

.la-hat-wizard:before {
  content: "";
}

.la-haykal:before {
  content: "";
}

.la-hdd:before {
  content: "";
}

.la-heading:before {
  content: "";
}

.la-headphones:before {
  content: "";
}

.la-headphones-alt:before {
  content: "";
}

.la-headset:before {
  content: "";
}

.la-heart:before {
  content: "";
}

.la-heart-broken:before {
  content: "";
}

.la-heartbeat:before {
  content: "";
}

.la-helicopter:before {
  content: "";
}

.la-highlighter:before {
  content: "";
}

.la-hiking:before {
  content: "";
}

.la-hippo:before {
  content: "";
}

.la-hips:before {
  content: "";
}

.la-hire-a-helper:before {
  content: "";
}

.la-history:before {
  content: "";
}

.la-hockey-puck:before {
  content: "";
}

.la-holly-berry:before {
  content: "";
}

.la-home:before {
  content: "";
}

.la-hooli:before {
  content: "";
}

.la-hornbill:before {
  content: "";
}

.la-horse:before {
  content: "";
}

.la-horse-head:before {
  content: "";
}

.la-hospital:before {
  content: "";
}

.la-hospital-alt:before {
  content: "";
}

.la-hospital-symbol:before {
  content: "";
}

.la-hot-tub:before {
  content: "";
}

.la-hotdog:before {
  content: "";
}

.la-hotel:before {
  content: "";
}

.la-hotjar:before {
  content: "";
}

.la-hourglass:before {
  content: "";
}

.la-hourglass-end:before {
  content: "";
}

.la-hourglass-half:before {
  content: "";
}

.la-hourglass-start:before {
  content: "";
}

.la-house-damage:before {
  content: "";
}

.la-houzz:before {
  content: "";
}

.la-hryvnia:before {
  content: "";
}

.la-html5:before {
  content: "";
}

.la-hubspot:before {
  content: "";
}

.la-i-cursor:before {
  content: "";
}

.la-ice-cream:before {
  content: "";
}

.la-icicles:before {
  content: "";
}

.la-icons:before {
  content: "";
}

.la-id-badge:before {
  content: "";
}

.la-id-card:before {
  content: "";
}

.la-id-card-alt:before {
  content: "";
}

.la-igloo:before {
  content: "";
}

.la-image:before {
  content: "";
}

.la-images:before {
  content: "";
}

.la-imdb:before {
  content: "";
}

.la-inbox:before {
  content: "";
}

.la-indent:before {
  content: "";
}

.la-industry:before {
  content: "";
}

.la-infinity:before {
  content: "";
}

.la-info:before {
  content: "";
}

.la-info-circle:before {
  content: "";
}

.la-instagram:before {
  content: "";
}

.la-intercom:before {
  content: "";
}

.la-internet-explorer:before {
  content: "";
}

.la-invision:before {
  content: "";
}

.la-ioxhost:before {
  content: "";
}

.la-italic:before {
  content: "";
}

.la-itch-io:before {
  content: "";
}

.la-itunes:before {
  content: "";
}

.la-itunes-note:before {
  content: "";
}

.la-java:before {
  content: "";
}

.la-jedi:before {
  content: "";
}

.la-jedi-order:before {
  content: "";
}

.la-jenkins:before {
  content: "";
}

.la-jira:before {
  content: "";
}

.la-joget:before {
  content: "";
}

.la-joint:before {
  content: "";
}

.la-joomla:before {
  content: "";
}

.la-journal-whills:before {
  content: "";
}

.la-js:before {
  content: "";
}

.la-js-square:before {
  content: "";
}

.la-jsfiddle:before {
  content: "";
}

.la-kaaba:before {
  content: "";
}

.la-kaggle:before {
  content: "";
}

.la-key:before {
  content: "";
}

.la-keybase:before {
  content: "";
}

.la-keyboard:before {
  content: "";
}

.la-keycdn:before {
  content: "";
}

.la-khanda:before {
  content: "";
}

.la-kickstarter:before {
  content: "";
}

.la-kickstarter-k:before {
  content: "";
}

.la-kiss:before {
  content: "";
}

.la-kiss-beam:before {
  content: "";
}

.la-kiss-wink-heart:before {
  content: "";
}

.la-kiwi-bird:before {
  content: "";
}

.la-korvue:before {
  content: "";
}

.la-landmark:before {
  content: "";
}

.la-language:before {
  content: "";
}

.la-laptop:before {
  content: "";
}

.la-laptop-code:before {
  content: "";
}

.la-laptop-medical:before {
  content: "";
}

.la-laravel:before {
  content: "";
}

.la-lastfm:before {
  content: "";
}

.la-lastfm-square:before {
  content: "";
}

.la-laugh:before {
  content: "";
}

.la-laugh-beam:before {
  content: "";
}

.la-laugh-squint:before {
  content: "";
}

.la-laugh-wink:before {
  content: "";
}

.la-layer-group:before {
  content: "";
}

.la-leaf:before {
  content: "";
}

.la-leanpub:before {
  content: "";
}

.la-lemon:before {
  content: "";
}

.la-less:before {
  content: "";
}

.la-less-than:before {
  content: "";
}

.la-less-than-equal:before {
  content: "";
}

.la-level-down-alt:before {
  content: "";
}

.la-level-up-alt:before {
  content: "";
}

.la-life-ring:before {
  content: "";
}

.la-lightbulb:before {
  content: "";
}

.la-line:before {
  content: "";
}

.la-link:before {
  content: "";
}

.la-linkedin:before {
  content: "";
}

.la-linkedin-in:before {
  content: "";
}

.la-linode:before {
  content: "";
}

.la-linux:before {
  content: "";
}

.la-lira-sign:before {
  content: "";
}

.la-list:before {
  content: "";
}

.la-list-alt:before {
  content: "";
}

.la-list-ol:before {
  content: "";
}

.la-list-ul:before {
  content: "";
}

.la-location-arrow:before {
  content: "";
}

.la-lock:before {
  content: "";
}

.la-lock-open:before {
  content: "";
}

.la-long-arrow-alt-down:before {
  content: "";
}

.la-long-arrow-alt-left:before {
  content: "";
}

.la-long-arrow-alt-right:before {
  content: "";
}

.la-long-arrow-alt-up:before {
  content: "";
}

.la-low-vision:before {
  content: "";
}

.la-luggage-cart:before {
  content: "";
}

.la-lyft:before {
  content: "";
}

.la-magento:before {
  content: "";
}

.la-magic:before {
  content: "";
}

.la-magnet:before {
  content: "";
}

.la-mail-bulk:before {
  content: "";
}

.la-mailchimp:before {
  content: "";
}

.la-male:before {
  content: "";
}

.la-mandalorian:before {
  content: "";
}

.la-map:before {
  content: "";
}

.la-map-marked:before {
  content: "";
}

.la-map-marked-alt:before {
  content: "";
}

.la-map-marker:before {
  content: "";
}

.la-map-marker-alt:before {
  content: "";
}

.la-map-pin:before {
  content: "";
}

.la-map-signs:before {
  content: "";
}

.la-markdown:before {
  content: "";
}

.la-marker:before {
  content: "";
}

.la-mars:before {
  content: "";
}

.la-mars-double:before {
  content: "";
}

.la-mars-stroke:before {
  content: "";
}

.la-mars-stroke-h:before {
  content: "";
}

.la-mars-stroke-v:before {
  content: "";
}

.la-mask:before {
  content: "";
}

.la-mastodon:before {
  content: "";
}

.la-maxcdn:before {
  content: "";
}

.la-medal:before {
  content: "";
}

.la-medapps:before {
  content: "";
}

.la-medium:before {
  content: "";
}

.la-medium-m:before {
  content: "";
}

.la-medkit:before {
  content: "";
}

.la-medrt:before {
  content: "";
}

.la-meetup:before {
  content: "";
}

.la-megaport:before {
  content: "";
}

.la-meh:before {
  content: "";
}

.la-meh-blank:before {
  content: "";
}

.la-meh-rolling-eyes:before {
  content: "";
}

.la-memory:before {
  content: "";
}

.la-mendeley:before {
  content: "";
}

.la-menorah:before {
  content: "";
}

.la-mercury:before {
  content: "";
}

.la-meteor:before {
  content: "";
}

.la-microchip:before {
  content: "";
}

.la-microphone:before {
  content: "";
}

.la-microphone-alt:before {
  content: "";
}

.la-microphone-alt-slash:before {
  content: "";
}

.la-microphone-slash:before {
  content: "";
}

.la-microscope:before {
  content: "";
}

.la-microsoft:before {
  content: "";
}

.la-minus:before {
  content: "";
}

.la-minus-circle:before {
  content: "";
}

.la-minus-square:before {
  content: "";
}

.la-mitten:before {
  content: "";
}

.la-mix:before {
  content: "";
}

.la-mixcloud:before {
  content: "";
}

.la-mizuni:before {
  content: "";
}

.la-mobile:before {
  content: "";
}

.la-mobile-alt:before {
  content: "";
}

.la-modx:before {
  content: "";
}

.la-monero:before {
  content: "";
}

.la-money-bill:before {
  content: "";
}

.la-money-bill-alt:before {
  content: "";
}

.la-money-bill-wave:before {
  content: "";
}

.la-money-bill-wave-alt:before {
  content: "";
}

.la-money-check:before {
  content: "";
}

.la-money-check-alt:before {
  content: "";
}

.la-monument:before {
  content: "";
}

.la-moon:before {
  content: "";
}

.la-mortar-pestle:before {
  content: "";
}

.la-mosque:before {
  content: "";
}

.la-motorcycle:before {
  content: "";
}

.la-mountain:before {
  content: "";
}

.la-mouse-pointer:before {
  content: "";
}

.la-mug-hot:before {
  content: "";
}

.la-music:before {
  content: "";
}

.la-napster:before {
  content: "";
}

.la-neos:before {
  content: "";
}

.la-network-wired:before {
  content: "";
}

.la-neuter:before {
  content: "";
}

.la-newspaper:before {
  content: "";
}

.la-nimblr:before {
  content: "";
}

.la-node:before {
  content: "";
}

.la-node-js:before {
  content: "";
}

.la-not-equal:before {
  content: "";
}

.la-notes-medical:before {
  content: "";
}

.la-npm:before {
  content: "";
}

.la-ns8:before {
  content: "";
}

.la-nutritionix:before {
  content: "";
}

.la-object-group:before {
  content: "";
}

.la-object-ungroup:before {
  content: "";
}

.la-odnoklassniki:before {
  content: "";
}

.la-odnoklassniki-square:before {
  content: "";
}

.la-oil-can:before {
  content: "";
}

.la-old-republic:before {
  content: "";
}

.la-om:before {
  content: "";
}

.la-opencart:before {
  content: "";
}

.la-openid:before {
  content: "";
}

.la-opera:before {
  content: "";
}

.la-optin-monster:before {
  content: "";
}

.la-osi:before {
  content: "";
}

.la-otter:before {
  content: "";
}

.la-outdent:before {
  content: "";
}

.la-page4:before {
  content: "";
}

.la-pagelines:before {
  content: "";
}

.la-pager:before {
  content: "";
}

.la-paint-brush:before {
  content: "";
}

.la-paint-roller:before {
  content: "";
}

.la-palette:before {
  content: "";
}

.la-palfed:before {
  content: "";
}

.la-pallet:before {
  content: "";
}

.la-paper-plane:before {
  content: "";
}

.la-paperclip:before {
  content: "";
}

.la-parachute-box:before {
  content: "";
}

.la-paragraph:before {
  content: "";
}

.la-parking:before {
  content: "";
}

.la-passport:before {
  content: "";
}

.la-pastafarianism:before {
  content: "";
}

.la-paste:before {
  content: "";
}

.la-patreon:before {
  content: "";
}

.la-pause:before {
  content: "";
}

.la-pause-circle:before {
  content: "";
}

.la-paw:before {
  content: "";
}

.la-paypal:before {
  content: "";
}

.la-peace:before {
  content: "";
}

.la-pen:before {
  content: "";
}

.la-pen-alt:before {
  content: "";
}

.la-pen-fancy:before {
  content: "";
}

.la-pen-nib:before {
  content: "";
}

.la-pen-square:before {
  content: "";
}

.la-pencil-alt:before {
  content: "";
}

.la-pencil-ruler:before {
  content: "";
}

.la-penny-arcade:before {
  content: "";
}

.la-people-carry:before {
  content: "";
}

.la-pepper-hot:before {
  content: "";
}

.la-percent:before {
  content: "";
}

.la-percentage:before {
  content: "";
}

.la-periscope:before {
  content: "";
}

.la-person-booth:before {
  content: "";
}

.la-phabricator:before {
  content: "";
}

.la-phoenix-framework:before {
  content: "";
}

.la-phoenix-squadron:before {
  content: "";
}

.la-phone:before {
  content: "";
}

.la-phone-alt:before {
  content: "";
}

.la-phone-slash:before {
  content: "";
}

.la-phone-square:before {
  content: "";
}

.la-phone-square-alt:before {
  content: "";
}

.la-phone-volume:before {
  content: "";
}

.la-photo-video:before {
  content: "";
}

.la-php:before {
  content: "";
}

.la-pied-piper:before {
  content: "";
}

.la-pied-piper-alt:before {
  content: "";
}

.la-pied-piper-hat:before {
  content: "";
}

.la-pied-piper-pp:before {
  content: "";
}

.la-piggy-bank:before {
  content: "";
}

.la-pills:before {
  content: "";
}

.la-pinterest:before {
  content: "";
}

.la-pinterest-p:before {
  content: "";
}

.la-pinterest-square:before {
  content: "";
}

.la-pizza-slice:before {
  content: "";
}

.la-place-of-worship:before {
  content: "";
}

.la-plane:before {
  content: "";
}

.la-plane-arrival:before {
  content: "";
}

.la-plane-departure:before {
  content: "";
}

.la-play:before {
  content: "";
}

.la-play-circle:before {
  content: "";
}

.la-playstation:before {
  content: "";
}

.la-plug:before {
  content: "";
}

.la-plus:before {
  content: "";
}

.la-plus-circle:before {
  content: "";
}

.la-plus-square:before {
  content: "";
}

.la-podcast:before {
  content: "";
}

.la-poll:before {
  content: "";
}

.la-poll-h:before {
  content: "";
}

.la-poo:before {
  content: "";
}

.la-poo-storm:before {
  content: "";
}

.la-poop:before {
  content: "";
}

.la-portrait:before {
  content: "";
}

.la-pound-sign:before {
  content: "";
}

.la-power-off:before {
  content: "";
}

.la-pray:before {
  content: "";
}

.la-praying-hands:before {
  content: "";
}

.la-prescription:before {
  content: "";
}

.la-prescription-bottle:before {
  content: "";
}

.la-prescription-bottle-alt:before {
  content: "";
}

.la-print:before {
  content: "";
}

.la-procedures:before {
  content: "";
}

.la-product-hunt:before {
  content: "";
}

.la-project-diagram:before {
  content: "";
}

.la-pushed:before {
  content: "";
}

.la-puzzle-piece:before {
  content: "";
}

.la-python:before {
  content: "";
}

.la-qq:before {
  content: "";
}

.la-qrcode:before {
  content: "";
}

.la-question:before {
  content: "";
}

.la-question-circle:before {
  content: "";
}

.la-quidditch:before {
  content: "";
}

.la-quinscape:before {
  content: "";
}

.la-quora:before {
  content: "";
}

.la-quote-left:before {
  content: "";
}

.la-quote-right:before {
  content: "";
}

.la-quran:before {
  content: "";
}

.la-r-project:before {
  content: "";
}

.la-radiation:before {
  content: "";
}

.la-radiation-alt:before {
  content: "";
}

.la-rainbow:before {
  content: "";
}

.la-random:before {
  content: "";
}

.la-raspberry-pi:before {
  content: "";
}

.la-ravelry:before {
  content: "";
}

.la-react:before {
  content: "";
}

.la-reacteurope:before {
  content: "";
}

.la-readme:before {
  content: "";
}

.la-rebel:before {
  content: "";
}

.la-receipt:before {
  content: "";
}

.la-recycle:before {
  content: "";
}

.la-red-river:before {
  content: "";
}

.la-reddit:before {
  content: "";
}

.la-reddit-alien:before {
  content: "";
}

.la-reddit-square:before {
  content: "";
}

.la-redhat:before {
  content: "";
}

.la-redo:before {
  content: "";
}

.la-redo-alt:before {
  content: "";
}

.la-registered:before {
  content: "";
}

.la-remove-format:before {
  content: "";
}

.la-renren:before {
  content: "";
}

.la-reply:before {
  content: "";
}

.la-reply-all:before {
  content: "";
}

.la-replyd:before {
  content: "";
}

.la-republican:before {
  content: "";
}

.la-researchgate:before {
  content: "";
}

.la-resolving:before {
  content: "";
}

.la-restroom:before {
  content: "";
}

.la-retweet:before {
  content: "";
}

.la-rev:before {
  content: "";
}

.la-ribbon:before {
  content: "";
}

.la-ring:before {
  content: "";
}

.la-road:before {
  content: "";
}

.la-robot:before {
  content: "";
}

.la-rocket:before {
  content: "";
}

.la-rocketchat:before {
  content: "";
}

.la-rockrms:before {
  content: "";
}

.la-route:before {
  content: "";
}

.la-rss:before {
  content: "";
}

.la-rss-square:before {
  content: "";
}

.la-ruble-sign:before {
  content: "";
}

.la-ruler:before {
  content: "";
}

.la-ruler-combined:before {
  content: "";
}

.la-ruler-horizontal:before {
  content: "";
}

.la-ruler-vertical:before {
  content: "";
}

.la-running:before {
  content: "";
}

.la-rupee-sign:before {
  content: "";
}

.la-sad-cry:before {
  content: "";
}

.la-sad-tear:before {
  content: "";
}

.la-safari:before {
  content: "";
}

.la-salesforce:before {
  content: "";
}

.la-sass:before {
  content: "";
}

.la-satellite:before {
  content: "";
}

.la-satellite-dish:before {
  content: "";
}

.la-save:before {
  content: "";
}

.la-schlix:before {
  content: "";
}

.la-school:before {
  content: "";
}

.la-screwdriver:before {
  content: "";
}

.la-scribd:before {
  content: "";
}

.la-scroll:before {
  content: "";
}

.la-sd-card:before {
  content: "";
}

.la-search:before {
  content: "";
}

.la-search-dollar:before {
  content: "";
}

.la-search-location:before {
  content: "";
}

.la-search-minus:before {
  content: "";
}

.la-search-plus:before {
  content: "";
}

.la-searchengin:before {
  content: "";
}

.la-seedling:before {
  content: "";
}

.la-sellcast:before {
  content: "";
}

.la-sellsy:before {
  content: "";
}

.la-server:before {
  content: "";
}

.la-servicestack:before {
  content: "";
}

.la-shapes:before {
  content: "";
}

.la-share:before {
  content: "";
}

.la-share-alt:before {
  content: "";
}

.la-share-alt-square:before {
  content: "";
}

.la-share-square:before {
  content: "";
}

.la-shekel-sign:before {
  content: "";
}

.la-shield-alt:before {
  content: "";
}

.la-ship:before {
  content: "";
}

.la-shipping-fast:before {
  content: "";
}

.la-shirtsinbulk:before {
  content: "";
}

.la-shoe-prints:before {
  content: "";
}

.la-shopping-bag:before {
  content: "";
}

.la-shopping-basket:before {
  content: "";
}

.la-shopping-cart:before {
  content: "";
}

.la-shopware:before {
  content: "";
}

.la-shower:before {
  content: "";
}

.la-shuttle-van:before {
  content: "";
}

.la-sign:before {
  content: "";
}

.la-sign-in-alt:before {
  content: "";
}

.la-sign-language:before {
  content: "";
}

.la-sign-out-alt:before {
  content: "";
}

.la-signal:before {
  content: "";
}

.la-signature:before {
  content: "";
}

.la-sim-card:before {
  content: "";
}

.la-simplybuilt:before {
  content: "";
}

.la-sistrix:before {
  content: "";
}

.la-sitemap:before {
  content: "";
}

.la-sith:before {
  content: "";
}

.la-skating:before {
  content: "";
}

.la-sketch:before {
  content: "";
}

.la-skiing:before {
  content: "";
}

.la-skiing-nordic:before {
  content: "";
}

.la-skull:before {
  content: "";
}

.la-skull-crossbones:before {
  content: "";
}

.la-skyatlas:before {
  content: "";
}

.la-skype:before {
  content: "";
}

.la-slack:before {
  content: "";
}

.la-slack-hash:before {
  content: "";
}

.la-slash:before {
  content: "";
}

.la-sleigh:before {
  content: "";
}

.la-sliders-h:before {
  content: "";
}

.la-slideshare:before {
  content: "";
}

.la-smile:before {
  content: "";
}

.la-smile-beam:before {
  content: "";
}

.la-smile-wink:before {
  content: "";
}

.la-smog:before {
  content: "";
}

.la-smoking:before {
  content: "";
}

.la-smoking-ban:before {
  content: "";
}

.la-sms:before {
  content: "";
}

.la-snapchat:before {
  content: "";
}

.la-snapchat-ghost:before {
  content: "";
}

.la-snapchat-square:before {
  content: "";
}

.la-snowboarding:before {
  content: "";
}

.la-snowflake:before {
  content: "";
}

.la-snowman:before {
  content: "";
}

.la-snowplow:before {
  content: "";
}

.la-socks:before {
  content: "";
}

.la-solar-panel:before {
  content: "";
}

.la-sort:before {
  content: "";
}

.la-sort-alpha-down:before {
  content: "";
}

.la-sort-alpha-down-alt:before {
  content: "";
}

.la-sort-alpha-up:before {
  content: "";
}

.la-sort-alpha-up-alt:before {
  content: "";
}

.la-sort-amount-down:before {
  content: "";
}

.la-sort-amount-down-alt:before {
  content: "";
}

.la-sort-amount-up:before {
  content: "";
}

.la-sort-amount-up-alt:before {
  content: "";
}

.la-sort-down:before {
  content: "";
}

.la-sort-numeric-down:before {
  content: "";
}

.la-sort-numeric-down-alt:before {
  content: "";
}

.la-sort-numeric-up:before {
  content: "";
}

.la-sort-numeric-up-alt:before {
  content: "";
}

.la-sort-up:before {
  content: "";
}

.la-soundcloud:before {
  content: "";
}

.la-sourcetree:before {
  content: "";
}

.la-spa:before {
  content: "";
}

.la-space-shuttle:before {
  content: "";
}

.la-speakap:before {
  content: "";
}

.la-speaker-deck:before {
  content: "";
}

.la-spell-check:before {
  content: "";
}

.la-spider:before {
  content: "";
}

.la-spinner:before {
  content: "";
}

.la-splotch:before {
  content: "";
}

.la-spotify:before {
  content: "";
}

.la-spray-can:before {
  content: "";
}

.la-square:before {
  content: "";
}

.la-square-full:before {
  content: "";
}

.la-square-root-alt:before {
  content: "";
}

.la-squarespace:before {
  content: "";
}

.la-stack-exchange:before {
  content: "";
}

.la-stack-overflow:before {
  content: "";
}

.la-stackpath:before {
  content: "";
}

.la-stamp:before {
  content: "";
}

.la-star:before {
  content: "";
}

.la-star-and-crescent:before {
  content: "";
}

.la-star-half:before {
  content: "";
}

.la-star-half-alt:before {
  content: "";
}

.la-star-of-david:before {
  content: "";
}

.la-star-of-life:before {
  content: "";
}

.la-staylinked:before {
  content: "";
}

.la-steam:before {
  content: "";
}

.la-steam-square:before {
  content: "";
}

.la-steam-symbol:before {
  content: "";
}

.la-step-backward:before {
  content: "";
}

.la-step-forward:before {
  content: "";
}

.la-stethoscope:before {
  content: "";
}

.la-sticker-mule:before {
  content: "";
}

.la-sticky-note:before {
  content: "";
}

.la-stop:before {
  content: "";
}

.la-stop-circle:before {
  content: "";
}

.la-stopwatch:before {
  content: "";
}

.la-store:before {
  content: "";
}

.la-store-alt:before {
  content: "";
}

.la-strava:before {
  content: "";
}

.la-stream:before {
  content: "";
}

.la-street-view:before {
  content: "";
}

.la-strikethrough:before {
  content: "";
}

.la-stripe:before {
  content: "";
}

.la-stripe-s:before {
  content: "";
}

.la-stroopwafel:before {
  content: "";
}

.la-studiovinari:before {
  content: "";
}

.la-stumbleupon:before {
  content: "";
}

.la-stumbleupon-circle:before {
  content: "";
}

.la-subscript:before {
  content: "";
}

.la-subway:before {
  content: "";
}

.la-suitcase:before {
  content: "";
}

.la-suitcase-rolling:before {
  content: "";
}

.la-sun:before {
  content: "";
}

.la-superpowers:before {
  content: "";
}

.la-superscript:before {
  content: "";
}

.la-supple:before {
  content: "";
}

.la-surprise:before {
  content: "";
}

.la-suse:before {
  content: "";
}

.la-swatchbook:before {
  content: "";
}

.la-swimmer:before {
  content: "";
}

.la-swimming-pool:before {
  content: "";
}

.la-symfony:before {
  content: "";
}

.la-synagogue:before {
  content: "";
}

.la-sync:before {
  content: "";
}

.la-sync-alt:before {
  content: "";
}

.la-syringe:before {
  content: "";
}

.la-table:before {
  content: "";
}

.la-table-tennis:before {
  content: "";
}

.la-tablet:before {
  content: "";
}

.la-tablet-alt:before {
  content: "";
}

.la-tablets:before {
  content: "";
}

.la-tachometer-alt:before {
  content: "";
}

.la-tag:before {
  content: "";
}

.la-tags:before {
  content: "";
}

.la-tape:before {
  content: "";
}

.la-tasks:before {
  content: "";
}

.la-taxi:before {
  content: "";
}

.la-teamspeak:before {
  content: "";
}

.la-teeth:before {
  content: "";
}

.la-teeth-open:before {
  content: "";
}

.la-telegram:before {
  content: "";
}

.la-telegram-plane:before {
  content: "";
}

.la-temperature-high:before {
  content: "";
}

.la-temperature-low:before {
  content: "";
}

.la-tencent-weibo:before {
  content: "";
}

.la-tenge:before {
  content: "";
}

.la-terminal:before {
  content: "";
}

.la-text-height:before {
  content: "";
}

.la-text-width:before {
  content: "";
}

.la-th:before {
  content: "";
}

.la-th-large:before {
  content: "";
}

.la-th-list:before {
  content: "";
}

.la-the-red-yeti:before {
  content: "";
}

.la-theater-masks:before {
  content: "";
}

.la-themeco:before {
  content: "";
}

.la-themeisle:before {
  content: "";
}

.la-thermometer:before {
  content: "";
}

.la-thermometer-empty:before {
  content: "";
}

.la-thermometer-full:before {
  content: "";
}

.la-thermometer-half:before {
  content: "";
}

.la-thermometer-quarter:before {
  content: "";
}

.la-thermometer-three-quarters:before {
  content: "";
}

.la-think-peaks:before {
  content: "";
}

.la-thumbs-down:before {
  content: "";
}

.la-thumbs-up:before {
  content: "";
}

.la-thumbtack:before {
  content: "";
}

.la-ticket-alt:before {
  content: "";
}

.la-times:before {
  content: "";
}

.la-times-circle:before {
  content: "";
}

.la-tint:before {
  content: "";
}

.la-tint-slash:before {
  content: "";
}

.la-tired:before {
  content: "";
}

.la-toggle-off:before {
  content: "";
}

.la-toggle-on:before {
  content: "";
}

.la-toilet:before {
  content: "";
}

.la-toilet-paper:before {
  content: "";
}

.la-toolbox:before {
  content: "";
}

.la-tools:before {
  content: "";
}

.la-tooth:before {
  content: "";
}

.la-torah:before {
  content: "";
}

.la-torii-gate:before {
  content: "";
}

.la-tractor:before {
  content: "";
}

.la-trade-federation:before {
  content: "";
}

.la-trademark:before {
  content: "";
}

.la-traffic-light:before {
  content: "";
}

.la-train:before {
  content: "";
}

.la-tram:before {
  content: "";
}

.la-transgender:before {
  content: "";
}

.la-transgender-alt:before {
  content: "";
}

.la-trash:before {
  content: "";
}

.la-trash-alt:before {
  content: "";
}

.la-trash-restore:before {
  content: "";
}

.la-trash-restore-alt:before {
  content: "";
}

.la-tree:before {
  content: "";
}

.la-trello:before {
  content: "";
}

.la-tripadvisor:before {
  content: "";
}

.la-trophy:before {
  content: "";
}

.la-truck:before {
  content: "";
}

.la-truck-loading:before {
  content: "";
}

.la-truck-monster:before {
  content: "";
}

.la-truck-moving:before {
  content: "";
}

.la-truck-pickup:before {
  content: "";
}

.la-tshirt:before {
  content: "";
}

.la-tty:before {
  content: "";
}

.la-tumblr:before {
  content: "";
}

.la-tumblr-square:before {
  content: "";
}

.la-tv:before {
  content: "";
}

.la-twitch:before {
  content: "";
}

.la-twitter:before {
  content: "";
}

.la-twitter-square:before {
  content: "";
}

.la-typo3:before {
  content: "";
}

.la-uber:before {
  content: "";
}

.la-ubuntu:before {
  content: "";
}

.la-uikit:before {
  content: "";
}

.la-umbrella:before {
  content: "";
}

.la-umbrella-beach:before {
  content: "";
}

.la-underline:before {
  content: "";
}

.la-undo:before {
  content: "";
}

.la-undo-alt:before {
  content: "";
}

.la-uniregistry:before {
  content: "";
}

.la-universal-access:before {
  content: "";
}

.la-university:before {
  content: "";
}

.la-unlink:before {
  content: "";
}

.la-unlock:before {
  content: "";
}

.la-unlock-alt:before {
  content: "";
}

.la-untappd:before {
  content: "";
}

.la-upload:before {
  content: "";
}

.la-ups:before {
  content: "";
}

.la-usb:before {
  content: "";
}

.la-user:before {
  content: "";
}

.la-user-alt:before {
  content: "";
}

.la-user-alt-slash:before {
  content: "";
}

.la-user-astronaut:before {
  content: "";
}

.la-user-check:before {
  content: "";
}

.la-user-circle:before {
  content: "";
}

.la-user-clock:before {
  content: "";
}

.la-user-cog:before {
  content: "";
}

.la-user-edit:before {
  content: "";
}

.la-user-friends:before {
  content: "";
}

.la-user-graduate:before {
  content: "";
}

.la-user-injured:before {
  content: "";
}

.la-user-lock:before {
  content: "";
}

.la-user-md:before {
  content: "";
}

.la-user-minus:before {
  content: "";
}

.la-user-ninja:before {
  content: "";
}

.la-user-nurse:before {
  content: "";
}

.la-user-plus:before {
  content: "";
}

.la-user-secret:before {
  content: "";
}

.la-user-shield:before {
  content: "";
}

.la-user-slash:before {
  content: "";
}

.la-user-tag:before {
  content: "";
}

.la-user-tie:before {
  content: "";
}

.la-user-times:before {
  content: "";
}

.la-users:before {
  content: "";
}

.la-users-cog:before {
  content: "";
}

.la-usps:before {
  content: "";
}

.la-ussunnah:before {
  content: "";
}

.la-utensil-spoon:before {
  content: "";
}

.la-utensils:before {
  content: "";
}

.la-vaadin:before {
  content: "";
}

.la-vector-square:before {
  content: "";
}

.la-venus:before {
  content: "";
}

.la-venus-double:before {
  content: "";
}

.la-venus-mars:before {
  content: "";
}

.la-viacoin:before {
  content: "";
}

.la-viadeo:before {
  content: "";
}

.la-viadeo-square:before {
  content: "";
}

.la-vial:before {
  content: "";
}

.la-vials:before {
  content: "";
}

.la-viber:before {
  content: "";
}

.la-video:before {
  content: "";
}

.la-video-slash:before {
  content: "";
}

.la-vihara:before {
  content: "";
}

.la-vimeo:before {
  content: "";
}

.la-vimeo-square:before {
  content: "";
}

.la-vimeo-v:before {
  content: "";
}

.la-vine:before {
  content: "";
}

.la-vk:before {
  content: "";
}

.la-vnv:before {
  content: "";
}

.la-voicemail:before {
  content: "";
}

.la-volleyball-ball:before {
  content: "";
}

.la-volume-down:before {
  content: "";
}

.la-volume-mute:before {
  content: "";
}

.la-volume-off:before {
  content: "";
}

.la-volume-up:before {
  content: "";
}

.la-vote-yea:before {
  content: "";
}

.la-vr-cardboard:before {
  content: "";
}

.la-vuejs:before {
  content: "";
}

.la-walking:before {
  content: "";
}

.la-wallet:before {
  content: "";
}

.la-warehouse:before {
  content: "";
}

.la-water:before {
  content: "";
}

.la-wave-square:before {
  content: "";
}

.la-waze:before {
  content: "";
}

.la-weebly:before {
  content: "";
}

.la-weibo:before {
  content: "";
}

.la-weight:before {
  content: "";
}

.la-weight-hanging:before {
  content: "";
}

.la-weixin:before {
  content: "";
}

.la-whatsapp:before {
  content: "";
}

.la-whatsapp-square:before {
  content: "";
}

.la-wheelchair:before {
  content: "";
}

.la-whmcs:before {
  content: "";
}

.la-wifi:before {
  content: "";
}

.la-wikipedia-w:before {
  content: "";
}

.la-wind:before {
  content: "";
}

.la-window-close:before {
  content: "";
}

.la-window-maximize:before {
  content: "";
}

.la-window-minimize:before {
  content: "";
}

.la-window-restore:before {
  content: "";
}

.la-windows:before {
  content: "";
}

.la-wine-bottle:before {
  content: "";
}

.la-wine-glass:before {
  content: "";
}

.la-wine-glass-alt:before {
  content: "";
}

.la-wix:before {
  content: "";
}

.la-wizards-of-the-coast:before {
  content: "";
}

.la-wolf-pack-battalion:before {
  content: "";
}

.la-won-sign:before {
  content: "";
}

.la-wordpress:before {
  content: "";
}

.la-wordpress-simple:before {
  content: "";
}

.la-wpbeginner:before {
  content: "";
}

.la-wpexplorer:before {
  content: "";
}

.la-wpforms:before {
  content: "";
}

.la-wpressr:before {
  content: "";
}

.la-wrench:before {
  content: "";
}

.la-x-ray:before {
  content: "";
}

.la-xbox:before {
  content: "";
}

.la-xing:before {
  content: "";
}

.la-xing-square:before {
  content: "";
}

.la-y-combinator:before {
  content: "";
}

.la-yahoo:before {
  content: "";
}

.la-yammer:before {
  content: "";
}

.la-yandex:before {
  content: "";
}

.la-yandex-international:before {
  content: "";
}

.la-yarn:before {
  content: "";
}

.la-yelp:before {
  content: "";
}

.la-yen-sign:before {
  content: "";
}

.la-yin-yang:before {
  content: "";
}

.la-yoast:before {
  content: "";
}

.la-youtube:before {
  content: "";
}

.la-youtube-square:before {
  content: "";
}

.la-zhihu:before {
  content: "";
}

.la-hat-cowboy:before {
  content: "";
}

.la-hat-cowboy-side:before {
  content: "";
}

.la-mdb:before {
  content: "";
}

.la-mouse:before {
  content: "";
}

.la-orcid:before {
  content: "";
}

.la-record-vinyl:before {
  content: "";
}

.la-swift:before {
  content: "";
}

.la-umbraco:before {
  content: "";
}

.la-buy-n-large:before {
  content: "";
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.ngx-datatable {
  display: block;
  overflow: hidden;
  justify-content: center;
  position: relative;
  transform: translate3d(0, 0, 0);
  /**
   * Vertical Scrolling Adjustments
   */
  /**
   * Horizontal Scrolling Adjustments
   */
  /**
   * Fixed Header Height Adjustments
   */
  /**
   * Fixed row height adjustments
   */
  /**
   * Shared Styles
   */
  /**
   * Header Styles
   */
  /**
   * Body Styles
   */
  /**
   * Footer Styles
   */
}
.ngx-datatable [hidden] {
  display: none !important;
}
.ngx-datatable *,
.ngx-datatable *:before,
.ngx-datatable *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.ngx-datatable.scroll-vertical .datatable-body {
  overflow-y: auto;
}
.ngx-datatable.scroll-vertical.virtualized .datatable-body .datatable-row-wrapper {
  position: absolute;
}
.ngx-datatable.scroll-horz .datatable-body {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.ngx-datatable.fixed-header .datatable-header .datatable-header-inner {
  white-space: nowrap;
}
.ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ngx-datatable.fixed-row .datatable-scroll {
  white-space: nowrap;
}
.ngx-datatable.fixed-row .datatable-scroll .datatable-body-row {
  white-space: nowrap;
}
.ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-body-cell {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-body-group-cell {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ngx-datatable .datatable-body-row,
.ngx-datatable .datatable-row-center,
.ngx-datatable .datatable-header-inner {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  -webkit-flex-flow: row;
  -moz-flex-flow: row;
  -ms-flex-flow: row;
  -o-flex-flow: row;
  flex-flow: row;
}
.ngx-datatable .datatable-body-cell,
.ngx-datatable .datatable-header-cell {
  overflow-x: hidden;
  vertical-align: top;
  display: inline-block;
  line-height: 1.625;
}
.ngx-datatable .datatable-body-cell:focus,
.ngx-datatable .datatable-header-cell:focus {
  outline: none;
}
.ngx-datatable .datatable-row-left,
.ngx-datatable .datatable-row-right {
  z-index: 9;
}
.ngx-datatable .datatable-row-left,
.ngx-datatable .datatable-row-center,
.ngx-datatable .datatable-row-group,
.ngx-datatable .datatable-row-right {
  position: relative;
}
.ngx-datatable .datatable-header {
  display: block;
  overflow: hidden;
}
.ngx-datatable .datatable-header .datatable-header-inner {
  align-items: stretch;
  -webkit-align-items: stretch;
}
.ngx-datatable .datatable-header .datatable-header-cell {
  position: relative;
  display: inline-block;
}
.ngx-datatable .datatable-header .datatable-header-cell.sortable .datatable-header-cell-wrapper {
  cursor: pointer;
}
.ngx-datatable .datatable-header .datatable-header-cell.longpress .datatable-header-cell-wrapper {
  cursor: move;
}
.ngx-datatable .datatable-header .datatable-header-cell .sort-btn {
  line-height: 100%;
  vertical-align: middle;
  display: inline-block;
  cursor: pointer;
}
.ngx-datatable .datatable-header .datatable-header-cell .resize-handle,
.ngx-datatable .datatable-header .datatable-header-cell .resize-handle--not-resizable {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 5px;
  padding: 0 4px;
  visibility: hidden;
}
.ngx-datatable .datatable-header .datatable-header-cell .resize-handle {
  cursor: ew-resize;
}
.ngx-datatable .datatable-header .datatable-header-cell.resizeable:hover .resize-handle {
  visibility: visible;
}
.ngx-datatable .datatable-header .datatable-header-cell:hover .resize-handle--not-resizable {
  visibility: visible;
}
.ngx-datatable .datatable-header .datatable-header-cell .targetMarker {
  position: absolute;
  top: 0;
  bottom: 0;
}
.ngx-datatable .datatable-header .datatable-header-cell .targetMarker.dragFromLeft {
  right: 0;
}
.ngx-datatable .datatable-header .datatable-header-cell .targetMarker.dragFromRight {
  left: 0;
}
.ngx-datatable .datatable-header .datatable-header-cell .datatable-header-cell-template-wrap {
  height: inherit;
}
.ngx-datatable .datatable-body {
  position: relative;
  z-index: 10;
  display: block;
}
.ngx-datatable .datatable-body .datatable-scroll {
  display: inline-block;
}
.ngx-datatable .datatable-body .datatable-row-detail {
  overflow-y: hidden;
}
.ngx-datatable .datatable-body .datatable-row-wrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.ngx-datatable .datatable-body .datatable-body-row {
  outline: none;
}
.ngx-datatable .datatable-body .datatable-body-row > div {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.ngx-datatable .datatable-footer {
  display: block;
  width: 100%;
  overflow: auto;
}
.ngx-datatable .datatable-footer .datatable-footer-inner {
  display: flex;
  align-items: center;
  width: 100%;
}
.ngx-datatable .datatable-footer .selected-count .page-count {
  flex: 1 1 40%;
}
.ngx-datatable .datatable-footer .selected-count .datatable-pager {
  flex: 1 1 60%;
}
.ngx-datatable .datatable-footer .page-count {
  flex: 1 1 20%;
}
.ngx-datatable .datatable-footer .datatable-pager {
  flex: 1 1 80%;
  text-align: right;
}
.ngx-datatable .datatable-footer .datatable-pager .pager,
.ngx-datatable .datatable-footer .datatable-pager .pager li {
  padding: 0;
  margin: 0;
  display: inline-block;
  list-style: none;
}
.ngx-datatable .datatable-footer .datatable-pager .pager li,
.ngx-datatable .datatable-footer .datatable-pager .pager li a {
  outline: none;
}
.ngx-datatable .datatable-footer .datatable-pager .pager li a {
  cursor: pointer;
  display: inline-block;
}
.ngx-datatable .datatable-footer .datatable-pager .pager li.disabled a {
  cursor: not-allowed;
}

/*
  This stylesheet uses scss valiables for most of the colors / background-colors of the table
  to enable the customization of the displayed table without cloning the stylesheet into the
  own application.

  To modify table colors, add the following lines to the scss file of your application
  (this example modifies the color of the selected row - selectionType = single, multi or multiClick):

  $ngx-datatable-selected-active-background: yellow;
  $ngx-datatable-selected-active-background-hover: rgba(yellow, 0.2);

  @import '~@swimlane/ngx-datatable/index.css';
  @import '~@swimlane/ngx-datatable/themes/material.scss';
  @import '~@swimlane/ngx-datatable/assets/icons.css';

That's all.
*/
.ngx-datatable.material {
  background: #fff;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  /**
  * Shared Styles
  */
  /**
  * Global Row Styles
  */
  /**
  * Header Styles
  */
  /**
  * Body Styles
  */
  /**
  * Footer Styles
  */
}
.ngx-datatable.material.striped .datatable-row-odd {
  background: #eee;
}
.ngx-datatable.material.single-selection .datatable-body-row.active, .ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group, .ngx-datatable.material.multi-selection .datatable-body-row.active, .ngx-datatable.material.multi-selection .datatable-body-row.active .datatable-row-group, .ngx-datatable.material.multi-click-selection .datatable-body-row.active, .ngx-datatable.material.multi-click-selection .datatable-body-row.active .datatable-row-group {
  background-color: #304ffe;
  color: #fff;
}
.ngx-datatable.material.single-selection .datatable-body-row.active:hover, .ngx-datatable.material.single-selection .datatable-body-row.active:hover .datatable-row-group, .ngx-datatable.material.multi-selection .datatable-body-row.active:hover, .ngx-datatable.material.multi-selection .datatable-body-row.active:hover .datatable-row-group, .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover, .ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover .datatable-row-group {
  background-color: #193ae4;
  color: #fff;
}
.ngx-datatable.material.single-selection .datatable-body-row.active:focus, .ngx-datatable.material.single-selection .datatable-body-row.active:focus .datatable-row-group, .ngx-datatable.material.multi-selection .datatable-body-row.active:focus, .ngx-datatable.material.multi-selection .datatable-body-row.active:focus .datatable-row-group, .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus, .ngx-datatable.material.multi-click-selection .datatable-body-row.active:focus .datatable-row-group {
  background-color: #2041ef;
  color: #fff;
}
.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover, .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
  background-color: #eee;
  transition-property: background;
  transition-duration: 0.3s;
  transition-timing-function: linear;
}
.ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus, .ngx-datatable.material:not(.cell-selection) .datatable-body-row:focus .datatable-row-group {
  background-color: #ddd;
}
.ngx-datatable.material.cell-selection .datatable-body-cell:hover, .ngx-datatable.material.cell-selection .datatable-body-cell:hover .datatable-row-group {
  background-color: #eee;
  transition-property: background;
  transition-duration: 0.3s;
  transition-timing-function: linear;
}
.ngx-datatable.material.cell-selection .datatable-body-cell:focus, .ngx-datatable.material.cell-selection .datatable-body-cell:focus .datatable-row-group {
  background-color: #ddd;
}
.ngx-datatable.material.cell-selection .datatable-body-cell.active, .ngx-datatable.material.cell-selection .datatable-body-cell.active .datatable-row-group {
  background-color: #304ffe;
  color: #fff;
}
.ngx-datatable.material.cell-selection .datatable-body-cell.active:hover, .ngx-datatable.material.cell-selection .datatable-body-cell.active:hover .datatable-row-group {
  background-color: #193ae4;
  color: #fff;
}
.ngx-datatable.material.cell-selection .datatable-body-cell.active:focus, .ngx-datatable.material.cell-selection .datatable-body-cell.active:focus .datatable-row-group {
  background-color: #2041ef;
  color: #fff;
}
.ngx-datatable.material .empty-row {
  height: 50px;
  text-align: left;
  padding: 0.5rem 1.2rem;
  vertical-align: top;
  border-top: 0;
}
.ngx-datatable.material .loading-row {
  text-align: left;
  padding: 0.5rem 1.2rem;
  vertical-align: top;
  border-top: 0;
}
.ngx-datatable.material .datatable-header .datatable-row-left,
.ngx-datatable.material .datatable-body .datatable-row-left {
  background-color: #fff;
  background-position: 100% 0;
  background-repeat: repeat-y;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQIHWPSkNeSBmJhTQVtbiDNCgASagIIuJX8OgAAAABJRU5ErkJggg==);
}
.ngx-datatable.material .datatable-header .datatable-row-right,
.ngx-datatable.material .datatable-body .datatable-row-right {
  background-position: 0 0;
  background-color: #fff;
  background-repeat: repeat-y;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAABCAYAAAD5PA/NAAAAFklEQVQI12PQkNdi1VTQ5gbSwkAsDQARLAIGtOSFUAAAAABJRU5ErkJggg==);
}
.ngx-datatable.material .datatable-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.ngx-datatable.material .datatable-header .datatable-header-cell {
  text-align: left;
  padding: 0.9rem 1.2rem;
  font-weight: 400;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.54);
  vertical-align: bottom;
  font-size: 12px;
  font-weight: 500;
}
.ngx-datatable.material .datatable-header .datatable-header-cell .datatable-header-cell-wrapper {
  position: relative;
}
.ngx-datatable.material .datatable-header .datatable-header-cell.longpress .draggable::after {
  transition: transform 400ms ease, opacity 400ms ease;
  opacity: 0.5;
  transform: scale(1);
}
.ngx-datatable.material .datatable-header .datatable-header-cell .draggable::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -30px 0 0 -30px;
  height: 60px;
  width: 60px;
  background: #eee;
  border-radius: 100%;
  opacity: 1;
  filter: none;
  transform: scale(0);
  z-index: 9999;
  pointer-events: none;
}
.ngx-datatable.material .datatable-header .datatable-header-cell.dragging .resize-handle {
  border-right: none;
}
.ngx-datatable.material .datatable-header .resize-handle {
  border-right: solid 1px #eee;
}
.ngx-datatable.material .datatable-body {
  position: relative;
}
.ngx-datatable.material .datatable-body .datatable-row-detail {
  background: #f5f5f5;
  padding: 10px;
}
.ngx-datatable.material .datatable-body .datatable-group-header {
  background: #f5f5f5;
  border-bottom: solid 1px #d9d8d9;
  border-top: solid 1px #d9d8d9;
}
.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
  text-align: left;
  padding: 0.9rem 1.2rem;
  vertical-align: top;
  border-top: 0;
  color: rgba(0, 0, 0, 0.87);
  transition: width 0.3s ease;
  font-size: 14px;
  font-weight: 400;
}
.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-group-cell {
  text-align: left;
  padding: 0.9rem 1.2rem;
  vertical-align: top;
  border-top: 0;
  color: rgba(0, 0, 0, 0.87);
  transition: width 0.3s ease;
  font-size: 14px;
  font-weight: 400;
}
.ngx-datatable.material .datatable-body .progress-linear {
  display: block;
  position: sticky;
  width: 100%;
  height: 5px;
  padding: 0;
  margin: 0;
  top: 0;
}
.ngx-datatable.material .datatable-body .progress-linear .container {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 5px;
  -webkit-transform: translate(0, 0) scale(1, 1);
  transform: translate(0, 0) scale(1, 1);
  background-color: #aad1f9;
}
.ngx-datatable.material .datatable-body .progress-linear .container .bar {
  transition: all 0.2s linear;
  -webkit-animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
  animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
  transition: -webkit-transform 0.2s linear;
  transition: transform 0.2s linear;
  background-color: #106cc8;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 5px;
}
.ngx-datatable.material .datatable-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.54);
}
.ngx-datatable.material .datatable-footer .page-count {
  line-height: 50px;
  height: 50px;
  padding: 0 1.2rem;
}
.ngx-datatable.material .datatable-footer .datatable-pager {
  margin: 0 10px;
}
.ngx-datatable.material .datatable-footer .datatable-pager li {
  vertical-align: middle;
}
.ngx-datatable.material .datatable-footer .datatable-pager li.disabled a {
  color: rgba(0, 0, 0, 0.26) !important;
  background-color: transparent !important;
}
.ngx-datatable.material .datatable-footer .datatable-pager li.active a {
  background-color: rgba(158, 158, 158, 0.2);
  font-weight: bold;
}
.ngx-datatable.material .datatable-footer .datatable-pager a {
  height: 22px;
  min-width: 24px;
  line-height: 22px;
  padding: 0 6px;
  border-radius: 3px;
  margin: 6px 3px;
  text-align: center;
  vertical-align: top;
  color: rgba(0, 0, 0, 0.54);
  text-decoration: none;
  vertical-align: bottom;
}
.ngx-datatable.material .datatable-footer .datatable-pager a:hover {
  color: rgba(0, 0, 0, 0.75);
  background-color: rgba(158, 158, 158, 0.2);
}
.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-left,
.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-skip,
.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-right,
.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-prev {
  font-size: 20px;
  line-height: 20px;
  padding: 0 3px;
}
.ngx-datatable.material .datatable-summary-row .datatable-body-row {
  background-color: #ddd;
}
.ngx-datatable.material .datatable-summary-row .datatable-body-row:hover {
  background-color: #ddd;
}
.ngx-datatable.material .datatable-summary-row .datatable-body-row .datatable-body-cell {
  font-weight: bold;
}

/**
 * Checkboxes
**/
.datatable-checkbox {
  position: relative;
  margin: 0;
  cursor: pointer;
  vertical-align: middle;
  display: inline-block;
  box-sizing: border-box;
  padding: 0;
}
.datatable-checkbox input[type=checkbox] {
  position: relative;
  margin: 0 1rem 0 0;
  cursor: pointer;
  outline: none;
}
.datatable-checkbox input[type=checkbox]:before {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  content: "";
  position: absolute;
  left: 0;
  z-index: 1;
  width: 1rem;
  height: 1rem;
  border: 2px solid #f2f2f2;
}
.datatable-checkbox input[type=checkbox]:checked:before {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  height: 0.5rem;
  border-color: #009688;
  border-top-style: none;
  border-right-style: none;
}
.datatable-checkbox input[type=checkbox]:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1rem;
  height: 1rem;
  background: #fff;
  cursor: pointer;
}

/**
 * Progress bar animations
 */
@keyframes query {
  0% {
    opacity: 1;
    transform: translateX(35%) scale(0.3, 1);
  }
  100% {
    opacity: 0;
    transform: translateX(-50%) scale(0, 1);
  }
}
.ngx-datatable.dark {
  box-shadow: none;
  background: #1b1e27;
  border: 1px solid #2f3646;
  color: #fff;
  font-size: 13px;
}
.ngx-datatable.dark .datatable-header {
  background: #181b24;
  color: #72809b;
}
.ngx-datatable.dark .datatable-header .datatable-header-cell {
  text-align: left;
  padding: 0.5rem 1.2rem;
  font-weight: bold;
}
.ngx-datatable.dark .datatable-header .datatable-header-cell .datatable-header-cell-label {
  line-height: 24px;
}
.ngx-datatable.dark .datatable-body {
  background: #1a1e28;
}
.ngx-datatable.dark .datatable-body .datatable-body-row {
  border-top: 1px solid #2f3646;
}
.ngx-datatable.dark .datatable-body .datatable-body-row .datatable-body-cell {
  text-align: left;
  padding: 0.5rem 1.2rem;
  vertical-align: top;
}
.ngx-datatable.dark .datatable-body .datatable-body-row:hover {
  background: #171b24;
  transition-property: background;
  transition-duration: 0.3s;
  transition-timing-function: linear;
}
.ngx-datatable.dark .datatable-body .datatable-body-row:focus {
  background-color: #232837;
}
.ngx-datatable.dark .datatable-body .datatable-body-row.active {
  background-color: #1483ff;
  color: #fff;
}
.ngx-datatable.dark .datatable-footer {
  background: #232837;
  color: #72809b;
  margin-top: -1px;
}
.ngx-datatable.dark .datatable-footer .page-count {
  line-height: 50px;
  height: 50px;
  padding: 0 1.2rem;
}
.ngx-datatable.dark .datatable-footer .datatable-pager {
  margin: 0 10px;
  vertical-align: top;
}
.ngx-datatable.dark .datatable-footer .datatable-pager ul li {
  margin: 10px 0px;
}
.ngx-datatable.dark .datatable-footer .datatable-pager ul li:not(.disabled).active a, .ngx-datatable.dark .datatable-footer .datatable-pager ul li:not(.disabled):hover a {
  background-color: #455066;
  font-weight: bold;
}
.ngx-datatable.dark .datatable-footer .datatable-pager a {
  height: 22px;
  min-width: 24px;
  line-height: 22px;
  padding: 0;
  border-radius: 3px;
  margin: 0 3px;
  text-align: center;
  vertical-align: top;
  text-decoration: none;
  vertical-align: bottom;
  color: #72809b;
}
.ngx-datatable.dark .datatable-footer .datatable-pager .datatable-icon-left,
.ngx-datatable.dark .datatable-footer .datatable-pager .datatable-icon-skip,
.ngx-datatable.dark .datatable-footer .datatable-pager .datatable-icon-right,
.ngx-datatable.dark .datatable-footer .datatable-pager .datatable-icon-prev {
  font-size: 18px;
  line-height: 27px;
  padding: 0 3px;
}
.ngx-datatable.dark .datatable-summary-row .datatable-body-row {
  background-color: #14171f;
}
.ngx-datatable.dark .datatable-summary-row .datatable-body-row:hover {
  background-color: #14171f;
}
.ngx-datatable.dark .datatable-summary-row .datatable-body-row .datatable-body-cell {
  font-weight: bold;
}

/*
bootstrap table theme
*/
.ngx-datatable.bootstrap {
  box-shadow: none;
  font-size: 13px;
}
.ngx-datatable.bootstrap .datatable-header {
  height: unset !important;
}
.ngx-datatable.bootstrap .datatable-header .datatable-header-cell {
  vertical-align: bottom;
  padding: 0.75rem;
  border-bottom: 1px solid #d1d4d7;
}
.ngx-datatable.bootstrap .datatable-header .datatable-header-cell .datatable-header-cell-label {
  line-height: 24px;
}
.ngx-datatable.bootstrap .datatable-body .datatable-body-row {
  vertical-align: top;
  border-top: 1px solid #d1d4d7;
}
.ngx-datatable.bootstrap .datatable-body .datatable-body-row.datatable-row-even {
  background-color: rgba(0, 0, 0, 0.05);
}
.ngx-datatable.bootstrap .datatable-body .datatable-body-row.active {
  background-color: #1483ff;
  color: #fff;
}
.ngx-datatable.bootstrap .datatable-body .datatable-body-row .datatable-body-cell {
  padding: 0.75rem;
  text-align: left;
  vertical-align: top;
}
.ngx-datatable.bootstrap .datatable-body .empty-row {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
}
.ngx-datatable.bootstrap .datatable-footer {
  background: #424242;
  color: #ededed;
  margin-top: -1px;
}
.ngx-datatable.bootstrap .datatable-footer .page-count {
  line-height: 50px;
  height: 50px;
  padding: 0 1.2rem;
}
.ngx-datatable.bootstrap .datatable-footer .datatable-pager {
  margin: 0 10px;
  vertical-align: top;
}
.ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li {
  margin: 10px 0px;
}
.ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li:not(.disabled).active a, .ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li:not(.disabled):hover a {
  background-color: #545454;
  font-weight: bold;
}
.ngx-datatable.bootstrap .datatable-footer .datatable-pager a {
  height: 22px;
  min-width: 24px;
  line-height: 22px;
  padding: 0;
  border-radius: 3px;
  margin: 0 3px;
  text-align: center;
  vertical-align: top;
  text-decoration: none;
  vertical-align: bottom;
  color: #ededed;
}
.ngx-datatable.bootstrap .datatable-footer .datatable-pager .datatable-icon-left,
.ngx-datatable.bootstrap .datatable-footer .datatable-pager .datatable-icon-skip,
.ngx-datatable.bootstrap .datatable-footer .datatable-pager .datatable-icon-right,
.ngx-datatable.bootstrap .datatable-footer .datatable-pager .datatable-icon-prev {
  font-size: 18px;
  line-height: 27px;
  padding: 0 3px;
}
.ngx-datatable.bootstrap .datatable-summary-row .datatable-body-row .datatable-body-cell {
  font-weight: bold;
}

@font-face {
  font-family: "data-table";
  src: url("fonts/data-table.eot");
  src: url("fonts/data-table.eot?#iefix") format("embedded-opentype"), url("fonts/data-table.woff") format("woff"), url("fonts/data-table.ttf") format("truetype"), url("fonts/data-table.svg#data-table") format("svg");
  font-weight: normal;
  font-style: normal;
}
[data-icon]::before {
  font-family: "data-table" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^=datatable-icon-]::before,
[class*=" datatable-icon-"]::before {
  font-family: "data-table" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.datatable-icon-filter::before {
  content: "b";
}

.datatable-icon-collapse::before {
  content: "a";
}

.datatable-icon-expand::before {
  content: "c";
}

.datatable-icon-close::before {
  content: "d";
}

.datatable-icon-up::before {
  content: "e";
}

.datatable-icon-down::before {
  content: "f";
}

.datatable-icon-sort-unset::before {
  content: "c";
  opacity: 0.5;
}

.datatable-icon-sort::before {
  content: "g";
}

.datatable-icon-done::before {
  content: "h";
}

.datatable-icon-done-all::before {
  content: "i";
}

.datatable-icon-search::before {
  content: "j";
}

.datatable-icon-pin::before {
  content: "k";
}

.datatable-icon-add::before {
  content: "m";
}

.datatable-icon-left::before {
  content: "o";
}

.datatable-icon-right::before {
  content: "p";
}

.datatable-icon-skip::before {
  content: "q";
}

.datatable-icon-prev::before {
  content: "r";
}

/* .bs-datepicker */
.bs-datepicker {
  display: flex;
  align-items: stretch;
  flex-flow: row wrap;
  background: #fff;
  box-shadow: 0 0 10px 0 #aaa;
  position: relative;
  z-index: 1;
  /* button */
  /* .bs-datepicker-head */
  /* .bs-datepicker-body */
  /* .current-timedate */
  /* .bs-datepicker-multiple */
  /* .bs-datepicker-btns */
  /*.bs-datepicker-custom-range */
  /* .bs-datepicker-predefined-btns */
  /* .bs-datepicker-buttons */
}
.bs-datepicker:after {
  clear: both;
  content: "";
  display: block;
}
.bs-datepicker bs-day-picker {
  float: left;
}
.bs-datepicker button:hover,
.bs-datepicker button:focus,
.bs-datepicker button:active,
.bs-datepicker input:hover,
.bs-datepicker input:focus,
.bs-datepicker input:active, .bs-datepicker-btns button:hover, .bs-datepicker-btns button:focus, .bs-datepicker-btns button:active, .bs-datepicker-predefined-btns button:active, .bs-datepicker-predefined-btns button:focus {
  outline: none;
}
.bs-datepicker-head {
  min-width: 270px;
  height: 50px;
  padding: 10px;
  border-radius: 3px 3px 0 0;
  text-align: justify;
  /* .bs-datepicker-head button */
}
.bs-datepicker-head:after {
  content: "";
  display: inline-block;
  vertical-align: top;
  width: 100%;
}
.bs-datepicker-head button {
  display: inline-block;
  vertical-align: top;
  padding: 0;
  height: 30px;
  line-height: 30px;
  border: 0;
  background: transparent;
  text-align: center;
  cursor: pointer;
  color: #fff;
  transition: 0.3s;
}
.bs-datepicker-head button[disabled], .bs-datepicker-head button[disabled]:hover, .bs-datepicker-head button[disabled]:active {
  background: rgba(221, 221, 221, 0.3);
  color: #f5f5f5;
  cursor: not-allowed;
}
.bs-datepicker-head button.previous span {
  transform: translate(-1px, -1px);
}
.bs-datepicker-head button.next span {
  transform: translate(1px, -1px);
}
.bs-datepicker-head button.next, .bs-datepicker-head button.previous {
  border-radius: 50%;
  width: 30px;
  height: 30px;
}
.bs-datepicker-head button.next span, .bs-datepicker-head button.previous span {
  font-size: 28px;
  line-height: 1;
  display: inline-block;
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
.bs-datepicker-head button.current {
  border-radius: 15px;
  max-width: 155px;
  padding: 0 13px;
}
.bs-datepicker-head button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.bs-datepicker-head button:active {
  background-color: rgba(0, 0, 0, 0.2);
}
.bs-datepicker-body {
  padding: 10px;
  border-radius: 0 0 3px 3px;
  min-height: 232px;
  min-width: 278px;
  border: 1px solid #dee2e6;
  /* .bs-datepicker-body table */
}
.bs-datepicker-body .days.weeks {
  position: relative;
  z-index: 1;
}
.bs-datepicker-body table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  /* .bs-datepicker-body table.days */
  /* .bs-datepicker-body table.weeks */
}
.bs-datepicker-body table th {
  font-size: 13px;
  color: #9aaec1;
  font-weight: 400;
  text-align: center;
}
.bs-datepicker-body table td {
  color: #54708b;
  text-align: center;
  position: relative;
  padding: 0;
  /* todo: check first :not(.disabled) usage */
}
.bs-datepicker-body table td span {
  display: block;
  margin: 0 auto;
  font-size: 13px;
  border-radius: 50%;
  /*z-index: 1;*/
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.bs-datepicker-body table td:not(.disabled):not(.week) span:not(.disabled):not(.is-other-month) {
  cursor: pointer;
}
.bs-datepicker-body table td.is-highlighted:not(.disabled):not(.selected) span,
.bs-datepicker-body table td span.is-highlighted:not(.disabled):not(.selected) {
  background-color: #e9edf0;
  transition: 0s;
}
.bs-datepicker-body table td.is-active-other-month:not(.disabled):not(.selected) span,
.bs-datepicker-body table td span.is-active-other-month:not(.disabled):not(.selected) {
  background-color: #e9edf0;
  transition: 0s;
  cursor: pointer;
}
.bs-datepicker-body table td span.disabled, .bs-datepicker-body table td.disabled span {
  color: #9aaec1;
}
.bs-datepicker-body table td span.selected, .bs-datepicker-body table td.selected span {
  color: #fff;
}
.bs-datepicker-body table td span.is-other-month, .bs-datepicker-body table td.is-other-month span {
  color: rgba(0, 0, 0, 0.25);
}
.bs-datepicker-body table td.active {
  position: relative;
}
.bs-datepicker-body table td.active.select-start:before {
  left: 35%;
}
.bs-datepicker-body table td.active.select-end:before {
  left: -85%;
}
.bs-datepicker-body table td span.active.select-start:after,
.bs-datepicker-body table td span.active.select-end:after, .bs-datepicker-body table td.active.select-start span:after, .bs-datepicker-body table td.active.select-end span:after {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  transition: 0.3s;
  top: 0;
  border-radius: 50%;
}
.bs-datepicker-body table td:before,
.bs-datepicker-body table td span:before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  top: 6px;
  bottom: 6px;
  left: -3px;
  right: -2px;
  box-sizing: content-box;
  background: transparent;
}
.bs-datepicker-body table td.active.select-start + td.active:before {
  left: -20%;
}
.bs-datepicker-body table td:last-child.active:before {
  border-radius: 0 3px 3px 0;
  width: 125%;
  left: -25%;
}
.bs-datepicker-body table td span[class*=select-], .bs-datepicker-body table td[class*=select-] span {
  border-radius: 50%;
  color: #fff;
}
.bs-datepicker-body table.days td.active:not(.select-start):before, .bs-datepicker-body table.days td.in-range:not(.select-start):before,
.bs-datepicker-body table.days span.active:not(.select-start):before,
.bs-datepicker-body table.days span.in-range:not(.select-start):before {
  background: #e9edf0;
}
.bs-datepicker-body table.days span {
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.bs-datepicker-body table.days span.select-start {
  z-index: 2;
}
.bs-datepicker-body table.days span.is-highlighted.in-range:before {
  right: 3px;
  left: 0;
}
.bs-datepicker-body table.days span.in-range.select-end:before {
  right: 4px;
  left: 0;
}
.bs-datepicker-body table.days td.select-start + td.select-end:before, .bs-datepicker-body table.days td.select-start + td.is-highlighted:before, .bs-datepicker-body table.days td.active + td.is-highlighted:before, .bs-datepicker-body table.days td.active + td.select-end:before, .bs-datepicker-body table.days td.in-range + td.is-highlighted:before, .bs-datepicker-body table.days td.in-range + td.select-end:before {
  background: #e9edf0;
  width: 100%;
}
.bs-datepicker-body table.weeks tr td:nth-child(2).active:before {
  border-radius: 3px 0 0 3px;
  left: 0;
  width: 100%;
}
.bs-datepicker-body table:not(.weeks) tr td:first-child:before {
  border-radius: 3px 0 0 3px;
}
.bs-datepicker-body table.years td span {
  width: 46px;
  height: 46px;
  line-height: 45px;
  margin: 0 auto;
}
.bs-datepicker-body table.years tr:not(:last-child) td span {
  margin-bottom: 8px;
}
.bs-datepicker-body table.months td {
  height: 52px;
}
.bs-datepicker-body table.months td span {
  padding: 6px;
  border-radius: 15px;
}
.bs-datepicker .current-timedate {
  color: #54708b;
  font-size: 15px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  border-radius: 20px;
  border: 1px solid #dee2e6;
  margin-bottom: 10px;
  cursor: pointer;
  text-transform: uppercase;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.bs-datepicker .current-timedate span:not(:empty):before {
  content: "";
  width: 15px;
  height: 16px;
  display: inline-block;
  margin-right: 4px;
  vertical-align: text-bottom;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAQCAYAAADJViUEAAABMklEQVQoU9VTwW3CQBCcOUgBtEBKSAukAnBKME+wFCAlYIhk8sQlxFABtJAScAsuAPBEewYcxCP8ouxrPDsza61uiVN1o6RNHD4htSCmq49RfO71BvMJqBBkITRf1kmUW49nQRC9h1I5AZlBClaL8aP1fKgOOxCx8aSLs+Q19eZuNO8QmPqJRtDFguy7OAcDbJPs+/BKVPDIPrvD2ZJgWAmVe7O0rI0Vqs1seyWUXpuJoppYCa5L+U++NpNPkr5OE2oMdARsb3gykJT5ydZcL8Z9Ww60nxg2LhjON9li9OwXZzo+xLbp3nC2s9CL2RrueGyVrgwNm8HpsCzZ9EEW6kqXlo1GQe03FzP/7W8Hl0dBtu7Bf7zt6mIwvX1RvzDCm7+q3mAW0Dl/GPdUCeXrZLT9BrDrGkm4qlPvAAAAAElFTkSuQmCC);
}
.bs-datepicker-multiple {
  border-radius: 4px 0 0 4px;
}
.bs-datepicker-multiple + .bs-datepicker-multiple {
  margin-left: 10px;
}
.bs-datepicker-multiple .bs-datepicker {
  box-shadow: none;
  position: relative;
}
.bs-datepicker-multiple .bs-datepicker:not(:last-child) {
  padding-right: 10px;
}
.bs-datepicker-multiple .bs-datepicker + .bs-datepicker:after {
  content: "";
  display: block;
  width: 14px;
  height: 10px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAKCAYAAABrGwT5AAAA1ElEQVQoU42RsQrCUAxF77VuDu7O4oMWW//BURBBpZvgKk4uIrjoqKOTf+DopIO4uYggtFTfw3+pkQqCW1/G5J7kJiFy4m5MxUlxAzgIPHX+lzMPzupRYlYgxiR7vqsOP8YKzsTx0yxFMCUZ+q7aZzlr+OvgoWcAFyAHgat2jLWu48252DdqAihDJGSSJNUUxYmQjs3+hPQBlAh2rG2LCOPnaw3IiGDX99TRCs7ASJsNhUOA7d/LcuHvRG22FIZvsNXw1MX6VZExCilOQKEfeLXr/10+aC9Ho7arh7oAAAAASUVORK5CYII=);
  position: absolute;
  top: 25px;
  left: -8px;
}
.bs-datepicker-multiple .bs-datepicker .left {
  float: left;
}
.bs-datepicker-multiple .bs-datepicker .right {
  float: right;
}
.bs-datepicker-container {
  padding: 15px;
}
.bs-datepicker .bs-media-container {
  display: flex;
}
@media (max-width: 768px) {
  .bs-datepicker .bs-media-container {
    flex-direction: column;
  }
}
.bs-datepicker .bs-timepicker-in-datepicker-container {
  display: flex;
  justify-content: space-around;
}
.bs-datepicker-custom-range {
  padding: 15px;
  background: #eee;
}
.bs-datepicker-predefined-btns button {
  width: 100%;
  display: block;
  height: 30px;
  background-color: #9aaec1;
  border-radius: 4px;
  color: #fff;
  border: 0;
  margin-bottom: 10px;
  padding: 0 18px;
  text-align: left;
  transition: 0.3s;
}
.bs-datepicker-predefined-btns button:hover {
  background-color: #54708b;
}
.bs-datepicker-buttons {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  padding-top: 10px;
  border-top: 1px solid #dee2e6;
}
.bs-datepicker-buttons .btn-default {
  margin-left: 10px;
}
.bs-datepicker-buttons .btn-today-wrapper {
  display: flex;
  flex-flow: row wrap;
}
.bs-datepicker-buttons .clear-right,
.bs-datepicker-buttons .today-right {
  flex-grow: 0;
}
.bs-datepicker-buttons .clear-left,
.bs-datepicker-buttons .today-left {
  flex-grow: 1;
}
.bs-datepicker-buttons .clear-center,
.bs-datepicker-buttons .today-center {
  flex-grow: 0.5;
}

/* .bs-timepicker */
.bs-timepicker-container {
  padding: 10px 0;
}
.bs-timepicker-label {
  color: #54708b;
  margin-bottom: 10px;
}
.bs-timepicker-controls {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}
.bs-timepicker-controls button {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 0;
  background-color: #e9edf0;
  color: #54708b;
  font-size: 16px;
  font-weight: 700;
  vertical-align: middle;
  line-height: 0;
  padding: 0;
  transition: 0.3s;
}
.bs-timepicker-controls button:hover {
  background-color: #d5dadd;
}
.bs-timepicker-controls input {
  width: 35px;
  height: 25px;
  border-radius: 13px;
  text-align: center;
  border: 1px solid #dee2e6;
}
.bs-timepicker .switch-time-format {
  text-transform: uppercase;
  min-width: 54px;
  height: 25px;
  border-radius: 20px;
  border: 1px solid #dee2e6;
  background: #fff;
  color: #54708b;
  font-size: 13px;
}
.bs-timepicker .switch-time-format img {
  vertical-align: initial;
  margin-left: 4px;
}

bs-datepicker-container,
bs-daterangepicker-container {
  z-index: 1080;
}

/* screen size < 1024px */
@media (max-width: 768px) {
  .bs-datepicker {
    width: min-content;
    justify-content: center;
  }
  .bs-datepicker-multiple {
    display: flex;
  }
  .bs-datepicker-multiple + .bs-datepicker-multiple {
    margin-top: 10px;
    margin-left: 0;
  }
}
/* theming */
.theme-default .bs-datepicker-head {
  background-color: #777;
}
.theme-default .btn-today-wrapper .btn-success, .theme-default .btn-clear-wrapper .btn-success {
  background-color: #777;
  border-color: #777;
}
.theme-default .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active:focus, .theme-default .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}
.theme-default .btn-today-wrapper .btn-success:focus, .theme-default .btn-clear-wrapper .btn-success:focus {
  box-shadow: none;
}
.theme-default .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active, .theme-default .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active {
  background-color: #616161;
  border-color: #616161;
}
.theme-default .btn-today-wrapper .btn-success:hover, .theme-default .btn-clear-wrapper .btn-success:hover {
  background-color: #6F6E6E;
  border-color: #6F6E6E;
}
.theme-default .bs-datepicker-predefined-btns button.selected {
  background-color: #777;
}
.theme-default .bs-datepicker-body table td span.selected, .theme-default .bs-datepicker-body table td.selected span,
.theme-default .bs-datepicker-body table td span[class*=select-]:after, .theme-default .bs-datepicker-body table td[class*=select-] span:after {
  background-color: #777;
}
.theme-default .bs-datepicker-body table td.week span {
  color: #777;
}
.theme-default .bs-datepicker-body table td.active-week span:hover {
  cursor: pointer;
  background-color: #777;
  color: #fff;
  opacity: 0.5;
  transition: 0s;
}

.theme-green .bs-datepicker-head {
  background-color: #5cb85c;
}
.theme-green .btn-today-wrapper .btn-success, .theme-green .btn-clear-wrapper .btn-success {
  background-color: #5cb85c;
  border-color: #5cb85c;
}
.theme-green .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active:focus, .theme-green .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}
.theme-green .btn-today-wrapper .btn-success:focus, .theme-green .btn-clear-wrapper .btn-success:focus {
  box-shadow: none;
}
.theme-green .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active, .theme-green .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active {
  background-color: #1e7e34;
  border-color: #1e7e34;
}
.theme-green .btn-today-wrapper .btn-success:hover, .theme-green .btn-clear-wrapper .btn-success:hover {
  background-color: #218838;
  border-color: #218838;
}
.theme-green .bs-datepicker-predefined-btns button.selected {
  background-color: #5cb85c;
}
.theme-green .bs-datepicker-body table td span.selected, .theme-green .bs-datepicker-body table td.selected span,
.theme-green .bs-datepicker-body table td span[class*=select-]:after, .theme-green .bs-datepicker-body table td[class*=select-] span:after {
  background-color: #5cb85c;
}
.theme-green .bs-datepicker-body table td.week span {
  color: #5cb85c;
}
.theme-green .bs-datepicker-body table td.active-week span:hover {
  cursor: pointer;
  background-color: #5cb85c;
  color: #fff;
  opacity: 0.5;
  transition: 0s;
}

.theme-blue .bs-datepicker-head {
  background-color: #5bc0de;
}
.theme-blue .btn-today-wrapper .btn-success, .theme-blue .btn-clear-wrapper .btn-success {
  background-color: #5bc0de;
  border-color: #5bc0de;
}
.theme-blue .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active:focus, .theme-blue .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}
.theme-blue .btn-today-wrapper .btn-success:focus, .theme-blue .btn-clear-wrapper .btn-success:focus {
  box-shadow: none;
}
.theme-blue .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active, .theme-blue .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active {
  background-color: #2AA8CD;
  border-color: #2AA8CD;
}
.theme-blue .btn-today-wrapper .btn-success:hover, .theme-blue .btn-clear-wrapper .btn-success:hover {
  background-color: #3AB3D7;
  border-color: #3AB3D7;
}
.theme-blue .bs-datepicker-predefined-btns button.selected {
  background-color: #5bc0de;
}
.theme-blue .bs-datepicker-body table td span.selected, .theme-blue .bs-datepicker-body table td.selected span,
.theme-blue .bs-datepicker-body table td span[class*=select-]:after, .theme-blue .bs-datepicker-body table td[class*=select-] span:after {
  background-color: #5bc0de;
}
.theme-blue .bs-datepicker-body table td.week span {
  color: #5bc0de;
}
.theme-blue .bs-datepicker-body table td.active-week span:hover {
  cursor: pointer;
  background-color: #5bc0de;
  color: #fff;
  opacity: 0.5;
  transition: 0s;
}

.theme-dark-blue .bs-datepicker-head {
  background-color: #337ab7;
}
.theme-dark-blue .btn-today-wrapper .btn-success, .theme-dark-blue .btn-clear-wrapper .btn-success {
  background-color: #337ab7;
  border-color: #337ab7;
}
.theme-dark-blue .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active:focus, .theme-dark-blue .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}
.theme-dark-blue .btn-today-wrapper .btn-success:focus, .theme-dark-blue .btn-clear-wrapper .btn-success:focus {
  box-shadow: none;
}
.theme-dark-blue .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active, .theme-dark-blue .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active {
  background-color: #266498;
  border-color: #266498;
}
.theme-dark-blue .btn-today-wrapper .btn-success:hover, .theme-dark-blue .btn-clear-wrapper .btn-success:hover {
  background-color: #2C6FA9;
  border-color: #2C6FA9;
}
.theme-dark-blue .bs-datepicker-predefined-btns button.selected {
  background-color: #337ab7;
}
.theme-dark-blue .bs-datepicker-body table td span.selected, .theme-dark-blue .bs-datepicker-body table td.selected span,
.theme-dark-blue .bs-datepicker-body table td span[class*=select-]:after, .theme-dark-blue .bs-datepicker-body table td[class*=select-] span:after {
  background-color: #337ab7;
}
.theme-dark-blue .bs-datepicker-body table td.week span {
  color: #337ab7;
}
.theme-dark-blue .bs-datepicker-body table td.active-week span:hover {
  cursor: pointer;
  background-color: #337ab7;
  color: #fff;
  opacity: 0.5;
  transition: 0s;
}

.theme-red .bs-datepicker-head {
  background-color: #d9534f;
}
.theme-red .btn-today-wrapper .btn-success, .theme-red .btn-clear-wrapper .btn-success {
  background-color: #d9534f;
  border-color: #d9534f;
}
.theme-red .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active:focus, .theme-red .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}
.theme-red .btn-today-wrapper .btn-success:focus, .theme-red .btn-clear-wrapper .btn-success:focus {
  box-shadow: none;
}
.theme-red .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active, .theme-red .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active {
  background-color: #D23531;
  border-color: #D23531;
}
.theme-red .btn-today-wrapper .btn-success:hover, .theme-red .btn-clear-wrapper .btn-success:hover {
  background-color: #E33732;
  border-color: #E33732;
}
.theme-red .bs-datepicker-predefined-btns button.selected {
  background-color: #d9534f;
}
.theme-red .bs-datepicker-body table td span.selected, .theme-red .bs-datepicker-body table td.selected span,
.theme-red .bs-datepicker-body table td span[class*=select-]:after, .theme-red .bs-datepicker-body table td[class*=select-] span:after {
  background-color: #d9534f;
}
.theme-red .bs-datepicker-body table td.week span {
  color: #d9534f;
}
.theme-red .bs-datepicker-body table td.active-week span:hover {
  cursor: pointer;
  background-color: #d9534f;
  color: #fff;
  opacity: 0.5;
  transition: 0s;
}

.theme-orange .bs-datepicker-head {
  background-color: #f0ad4e;
}
.theme-orange .btn-today-wrapper .btn-success, .theme-orange .btn-clear-wrapper .btn-success {
  background-color: #f0ad4e;
  border-color: #f0ad4e;
}
.theme-orange .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active:focus, .theme-orange .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}
.theme-orange .btn-today-wrapper .btn-success:focus, .theme-orange .btn-clear-wrapper .btn-success:focus {
  box-shadow: none;
}
.theme-orange .btn-today-wrapper .btn-success:not(:disabled):not(.disabled):active, .theme-orange .btn-clear-wrapper .btn-success:not(:disabled):not(.disabled):active {
  background-color: #ED9C29;
  border-color: #ED9C29;
}
.theme-orange .btn-today-wrapper .btn-success:hover, .theme-orange .btn-clear-wrapper .btn-success:hover {
  background-color: #FFAC35;
  border-color: #FFAC35;
}
.theme-orange .bs-datepicker-predefined-btns button.selected {
  background-color: #f0ad4e;
}
.theme-orange .bs-datepicker-body table td span.selected, .theme-orange .bs-datepicker-body table td.selected span,
.theme-orange .bs-datepicker-body table td span[class*=select-]:after, .theme-orange .bs-datepicker-body table td[class*=select-] span:after {
  background-color: #f0ad4e;
}
.theme-orange .bs-datepicker-body table td.week span {
  color: #f0ad4e;
}
.theme-orange .bs-datepicker-body table td.active-week span:hover {
  cursor: pointer;
  background-color: #f0ad4e;
  color: #fff;
  opacity: 0.5;
  transition: 0s;
}

.spacer10 {
  height: 10px;
}

.spacer20 {
  height: 20px;
}

.spacer30 {
  height: 30px;
}

.spacer40 {
  height: 40px;
}

.spacer50 {
  height: 50px;
}

.spacer60 {
  height: 60px;
}

.spacer70 {
  height: 70px;
}

.spacer80 {
  height: 80px;
}

.spacer90 {
  height: 90px;
}

.spacer100 {
  height: 100px;
}

#bg-login {
  /*background: url('../../assets/images/admin/background-login.png');*/
  min-height: 100vh;
  width: 100%;
}
#bg-login .column-1 {
  background-color: #0F4561;
  height: 100vh;
  width: 50%;
  position: fixed;
  top: 0;
  left: 0;
}
#bg-login .column-2 {
  background: url("../../assets/images/admin/background-login.jpg");
  background-repeat: no-repeat;
  height: 100vh;
  width: 50%;
  position: fixed;
  top: 0;
  right: 0;
}
#bg-login .login-box {
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}
#bg-login .site-data {
  color: #739FCB;
}
#bg-login .site-data .dashboard {
  max-width: 385px;
  width: 100%;
}
#bg-login .site-data a {
  color: #739FCB !important;
}
#bg-login .br-welcome {
  border-bottom: 4px solid #fff;
  width: 25%;
}
#bg-login .inn-form {
  background-color: #fff;
  border-radius: 7px;
  box-shadow: 0px 0px 20px #0000000D;
  max-width: 385px;
  /*padding: 2rem 3rem;*/
}
#bg-login .inn-form .form-group .form-control {
  /*border-color: $gray3;*/
  background-color: #F6F6F6;
  color: #989898;
}
#bg-login .inn-form .btn-primary {
  padding: 0;
}
#bg-login .inn-form .btn-primary.bordered {
  background-color: #F6F6F6;
  border-color: #F6F6F6;
}
#bg-login .br-bt-gray {
  border-bottom: 1px solid #DBDBDB;
}
#bg-login .container-check {
  margin: 0 auto;
  /*width: max-content;*/
}
#bg-login .container-check .checkmark {
  border: 1px solid #989898;
}
#bg-login .logo-login {
  /*position: absolute;
  right: 5vw;
  top: 50%;*/
  transform: translateY(-30%);
  width: 12vw;
}

.main-content {
  background-color: #F6F6F6;
  min-height: 100vh;
  padding: 30px 2rem;
  padding-left: calc(255px + 2rem);
  transition: all 1s;
  overflow-x: hidden;
}
.main-content .info {
  background-color: #fff;
  border-bottom: 2px solid rgba(219, 219, 219, 0.4);
  left: 255px;
  padding: 1rem 2rem;
  padding-top: 6rem;
  position: fixed;
  top: 0;
  transition: all 1s;
  width: calc(100% - 255px);
  z-index: 2;
}
.main-content .info + div {
  margin-top: 10rem;
}
.main-content .info--form .inn-form {
  border-top: 2px solid rgba(219, 219, 219, 0.4);
  margin: 0 -2rem;
  padding: 1rem 2rem 0;
}
.main-content .info--form .inn-form .form-group .form-control {
  background-color: initial;
  border: 0;
  border-bottom: 1px solid #DBDBDB;
  border-radius: 0;
}
.main-content .info--form + div {
  margin-top: 14.5rem;
}
.main-content.aside-xs {
  padding-left: calc(50px + 2rem);
}
.main-content.aside-xs .info {
  width: calc(100% - 50px);
  left: 50px;
}

@media (max-width: 991.98px) {
  .main-content {
    padding: 30px 0;
  }
  .main-content .info + div {
    margin-top: 12rem;
  }
  .main-content.aside-xs .info {
    width: calc(100% - 50px);
    left: 50px;
  }
}
@media (max-width: 576px) {
  .main-content.aside-xs {
    padding: 30px 0;
  }

  .main-content {
    overflow: hidden;
    background-color: #F6F6F6;
  }
  .main-content .info + .dashboard-box {
    margin-top: 5rem;
    margin-left: 25px;
    margin-right: 25px;
    overflow-x: auto;
    padding-bottom: 20px;
  }
  .main-content .dashboard-box {
    background: transparent;
  }
  .main-content .dashboard-box .dashboard-row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    grid-column-gap: 10px;
  }
  .main-content .dashboard-box .dashboard-row .dashboard-col {
    border-right: 1px solid #F6F6F6;
    background: #fff;
    border-radius: 10px;
    padding: 0px;
  }
  .main-content .dashboard-box .dashboard-row .dashboard-col > div {
    padding-left: 1rem !important;
  }
  .main-content .dashboard-box .dashboard-row .dashboard-col i {
    color: #69B6F2 !important;
    font-size: 2rem;
  }
  .main-content.aside-xs .info {
    width: 100%;
    left: 0;
  }

  #chart-users .frappe-chart {
    height: 350px;
  }
}
.create-user-top {
  padding: 96px 25px 0px 25px;
  left: 0;
  right: 0;
  top: 0;
}

.arrow-back .la-long-arrow-alt-left {
  background: #F6F6F6;
  border-radius: 5px;
  padding: 3.5px;
  font-size: 1.5rem;
  color: #2D2D2D;
}

.txt-top {
  font-size: 15px;
  color: #2D2D2D;
}

.tabs {
  padding-top: 40px;
  padding-bottom: 19px;
}
.tabs .txt-tab {
  font-family: "Lato-Regular";
  font-size: 14px;
  color: #989898;
}

.container-form {
  border-radius: 10px;
}
.container-form .max-width-form {
  max-width: 800px;
  width: 100%;
}
.container-form .first-section {
  padding: 20px;
}
.container-form .form-data {
  padding: 25px 20px 20px 20px;
}
.container-form .form-data-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}
.container-form .form-data .form-input .form-control {
  background-color: #F6F6F6;
  border-color: #F6F6F6;
  border-radius: 5px;
  font-size: 15px;
  height: 2.5rem;
  font-family: "Poppins-regular";
  padding-left: 20px;
}
.container-form .form-data .form-input .form-control::placeholder {
  color: #989898;
}
.container-form .form-data .form-input {
  margin-bottom: 20px;
  position: relative;
}
.container-form .form-data .form-input .label-input {
  color: #739FCB;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .container-form .form-data-grid {
    grid-template-columns: 1fr 1fr;
  }
}
.detail-user-profile-top {
  padding: 96px 25px 20px 25px;
  left: 0;
  right: 0;
  top: 0;
}
.detail-user-profile-top .arrow-back .la-long-arrow-alt-left {
  background: #F6F6F6;
  border-radius: 5px;
  padding: 3px;
  font-size: 1.5rem;
  color: #000;
}
.detail-user-profile-top .container-icons-top .la-trash-alt, .detail-user-profile-top .container-icons-top .la-edit {
  font-size: 1.5rem;
  color: #989898;
}
.detail-user-profile-top .container-icons-top .icons-top:first-child {
  top: 2px;
}
.detail-user-profile-top .container-icons-top .onoffswitch-inner:before {
  background-color: #69B6F2;
  opacity: 0.3;
}
.detail-user-profile-top .container-icons-top .onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  background-color: #69B6F2 !important;
}

.main-container-data {
  margin: 9.2rem 25px 25px;
}

.first-container-data {
  border-radius: 10px;
  position: relative;
}
.first-container-data .first-container-items-data {
  padding: 15px 0px;
}
.first-container-data .first-container-items-data .number-publications {
  right: 0;
  padding-right: 1rem;
}
.first-container-data .first-container-items-data .container-user-image {
  background-color: #4C4C9F;
  border-radius: 10px;
}
.first-container-data .first-container-items-data .container-user-image .la-user {
  font-size: 4.2rem;
}
.first-container-data .first-container-items-data .container-user-progress {
  padding: 20px 16px;
  position: relative;
  border-radius: 50%;
  border: 2px solid #f2d1494f;
}
.first-container-data .first-container-items-data .container-user-progress:before {
  content: "";
  position: absolute;
  border: 3px solid #F2D149;
  border-radius: 50%;
  left: -3px;
  right: -3px;
  top: -3px;
  bottom: -3px;
}
.first-container-data .first-container-items-data .txt-progress {
  color: #F2D149;
  max-width: 70px;
}
.first-container-data .first-container-items-data .txt-progress:first-child {
  font-size: 19px;
}
.first-container-data .first-container-items-data .txt-publications {
  color: #90E383;
  max-width: 100px;
}
.first-container-data .first-container-items-data .la-font-awesome-flag {
  color: #90E383;
  font-size: 3.3rem;
}
.first-container-data .first-container-items-data .txt-last {
  color: #60E5D4;
  max-width: 140px;
  border-left: 2px solid #60E5D4;
  height: 40px;
}
.first-container-data .first-container-items-data .la-list-ul {
  color: #60E5D4;
  font-size: 2rem;
}
.first-container-data .second-container-items-data {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.6rem;
}
.first-container-data .second-container-items-data .gallery {
  background: #DBDBDB;
  aspect-ratio: 1/1;
  width: 100%;
  border-radius: 5px;
}
.first-container-data .second-container-items-data .gallery .la-image {
  font-size: 2rem;
}
.first-container-data .items-data {
  background-color: #F6F6F6;
  padding: 10px 20px;
  border-radius: 5px;
  margin-bottom: 5px;
}
.first-container-data .items-data .txt-data {
  color: #989898;
}
.first-container-data .items-data .txt-data-second {
  color: #000;
}
.first-container-data .items-data .txt-data-second .txt-dot {
  line-height: 0;
  position: relative;
  top: 7px;
}

.txt-subtitle, .txt-last, .txt-publications, .txt-progress, .txt-time {
  font-size: 14px;
}

.container-time {
  max-width: 150px;
}
.container-time .txt-time:first-child {
  font-size: 30px;
}

@media (min-width: 768px) {
  .first-container-data .second-container-items-data {
    gap: 1rem;
  }
}
.bg-error-found {
  background-color: #739FCB;
  height: 100vh;
  overflow: hidden;
  background-image: url(../../assets/images/error-404.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0em 50%;
}
.bg-error-found .content {
  background-color: rgba(76, 76, 159, 0.15);
  border-radius: 50%;
  height: 100vh;
  align-items: center;
  text-align: center;
}
.bg-error-found .content .content-info {
  position: relative;
  top: 45%;
}
.bg-error-found .content .content-info h1 {
  font-size: 4rem;
  letter-spacing: 2px;
}
.bg-error-found .content .content-info h6 {
  font-size: 0.7rem;
  width: 70%;
  margin: auto;
}

.bg-error-service {
  background-color: #0F4561;
  height: 100vh;
  overflow: hidden;
}
.bg-error-service .content {
  position: relative;
  top: 20%;
}
.bg-error-service .content img {
  width: 100%;
  position: relative;
  bottom: 3rem;
}
.bg-error-service .content .error {
  font-size: 4rem;
  position: absolute;
  bottom: 0;
  color: #ACACEA;
  opacity: 0.15;
}
.bg-error-service .content .color {
  width: 100%;
  height: 100%;
  background-color: #ACACEA;
  opacity: 0.15;
}
.bg-error-service .content h6 {
  font-size: 0.8rem;
  width: 50%;
}

.x-small {
  font-size: 11px;
  line-height: calc(11px + 4px);
}

.fs-small {
  font-size: 1rem;
}

.fs-normal {
  font-size: 1.2rem;
  line-height: calc(1.2rem + 0.2rem);
}

.fs-big {
  font-size: 2.5rem;
  line-height: calc(2.5rem + 0.3rem);
}

.fs-bigger {
  font-size: 4rem;
}

p {
  margin: 0;
}

hr {
  margin: 0.5rem 0;
}

.pointer {
  cursor: pointer;
}

.nowrap {
  white-space: nowrap;
}

.color-white {
  color: #fff !important;
}

.color-gray1 {
  color: #F6F6F6 !important;
}

.color-gray2 {
  color: #DBDBDB !important;
}

.color-gray3 {
  color: #989898 !important;
}

.color-black-light {
  color: #062536 !important;
}

.color-gray4 {
  color: #2D2D2D !important;
}

.color-primary {
  color: #739FCB !important;
}

.color-secondary {
  color: #4C4C9F !important;
}

.txt-uppercase {
  text-transform: uppercase;
}

.p-medium {
  padding: 0 3rem;
}

.b-right {
  border-right: 1px solid #DBDBDB;
}

.b-top {
  border-top: 1px solid #DBDBDB;
}

.b-left {
  border-left: 1px solid #DBDBDB;
}

.b-bottom {
  border-bottom: 1px solid #DBDBDB;
}

.underline {
  border-bottom: 1px solid;
}

a:hover, a.selected {
  border-bottom: 1px solid;
  color: #739FCB;
  font-weight: 600;
  text-decoration: none;
}

.searchbar {
  align-items: center;
  background-color: #F6F6F6;
  border-radius: 5px;
  display: none;
  width: 30%;
}
.searchbar.show {
  display: flex;
}
.searchbar span {
  font-size: 1rem;
  margin: 0 12px;
}
.searchbar input {
  background-color: initial;
  border: 0;
  border-left: 1px solid #989898;
  padding: 7px 15px;
  width: -webkit-fill-available;
}

.navbar {
  background-color: #0F4561;
  color: #739FCB;
  width: 100%;
  z-index: 1000;
}

.nav-link {
  color: #739FCB;
}

.medidor {
  background-color: #90E383;
  width: 40%;
  height: 500px;
  float: right;
  border-radius: 0px 0px 0px 200px;
  overflow: hidden;
}
.medidor .text_cloud p {
  font-size: 30px;
}
.medidor .text_aqui_tittle {
  top: 7%;
  left: 57%;
}
.medidor .text_aqui_tittle p {
  font-size: 30px;
}
.medidor .text_quality_tittle p {
  font-size: 15px;
}
.medidor .text_quality_face p {
  font-size: 2rem;
}
.medidor .text_quality_p {
  top: 23em;
  left: 5em;
  overflow: hidden;
  text-overflow: ellipsis;
}
.medidor .text_quality_p p {
  font-size: 15px;
}

.face {
  background-color: #90E383;
  width: 140px;
  height: 140px;
  float: right;
  z-index: 0;
  border-radius: 50%;
  position: absolute;
  top: 44%;
  left: 60%;
  background-image: url(../../assets/images/icons/face/face_bueno.svg);
}

.map {
  width: calc(100% - 116px);
  height: calc(100vh - 10%);
  float: right;
  z-index: 0;
  border-radius: 0px 0px 0px 480px;
  position: absolute;
  left: 116px;
}

.medidor_landing {
  width: 40%;
  max-height: calc(81vh);
  height: auto;
  float: right;
  border-radius: 0px 0px 0px 200px;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.medidor_landing .text_cloud p {
  font-size: 30px;
}
.medidor_landing .text_aqui_tittle p {
  font-size: 30px;
}
.medidor_landing .text_quality_tittle p {
  font-size: 15px;
}
.medidor_landing .text_quality_face p {
  font-size: 2rem;
  line-height: 1;
}
.medidor_landing .text_quality_p {
  top: 23em;
  left: 5em;
  overflow: hidden;
  text-overflow: ellipsis;
}
.medidor_landing .text_quality_p p {
  font-size: 15px;
}

.face_map {
  width: 8em;
  height: 7em;
  float: left;
  z-index: 1;
  border-radius: 50%;
}
.face_map .p {
  font-size: 4rem;
}

.face_map_landing {
  width: 10em;
  float: right;
  z-index: 1;
  border-radius: 50%;
  position: relative;
  left: 5em;
  top: 13em;
}
.face_map_landing .p {
  font-size: 4rem;
}

.cloud_map {
  width: 94px;
  height: 63px;
  z-index: 0;
  background-image: url(../../assets/images/icons/nube_aqi.svg);
  background-repeat: no-repeat;
}
.cloud_map .p {
  font-size: 4rem;
  position: absolute;
  left: 15%;
  top: 100px;
}

.ul_map_landing {
  position: absolute;
  left: 10em;
}

.more_detail {
  position: absolute;
  top: 28em;
  left: 2em;
}

.chart_icon {
  background-color: #fff;
  border-radius: 5px;
}

.badge-primary {
  color: #fff;
  background-color: #739FCB;
}

.badge {
  font-size: 100%;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  width: 21px;
  height: 21px;
}

.f-subtitle {
  font-family: "Poppins-regular";
  align-self: center;
  margin-left: 5px;
}

.card_title-bitacora {
  font-family: Calibri;
}

.card_text-bitacora {
  font-family: Calibri;
  color: #989898;
  font-size: 1rem;
}

.bitacora-icons {
  color: #DBDBDB;
}
.bitacora-icons i:hover {
  color: #2D2D2D;
}

.badge_bitacora {
  top: -27%;
  left: 8.5em;
}

.btn-send {
  background-color: #739FCB;
  color: #fff;
}

.btn-send:hover {
  background-color: #69B6F2;
  color: #fff;
}

.sidebar .form-control::placeholder {
  color: #989898;
  font-size: 1rem;
  font-family: "Poppins-regular";
}

.bg-form_input {
  background-color: #F6F6F6;
}

.btn .badge_comment {
  top: -0.7em;
}

.btn .badge {
  position: relative;
  /* top: -1px; */
}

.back {
  margin-top: 78px;
}
.back .container_map {
  background: #0F4561;
  min-height: 860px !important;
}
.back .container-know-us {
  margin-top: 200px;
}

.w-10 {
  width: 10%;
}

.material_particulate {
  /* z-index: -2; */
  /* height: 100%; */
  /* width: 100%; */
  position: absolute;
  /* display: flex; */
  display: block;
  /* left: 100%; */
  bottom: -144em;
}

.data {
  background: #0F4561;
  z-index: -2;
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
}
.data .smock {
  width: 47%;
  height: auto;
}
.data .circle {
  width: 200px;
  height: 200px;
  bottom: 0%;
  position: absolute;
  left: 16%;
}
.data .particle {
  width: 300px;
  height: 300px;
  bottom: 10%;
  position: absolute;
  right: 0%;
}
.data .text {
  width: 100%;
}
.data .description {
  max-width: 300px;
}

.title_emoticon {
  color: #062536;
  font-size: 1rem;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.city {
  top: 127%;
  position: relative;
  overflow: hidden;
}
.city .section-form {
  background-color: #739FCB;
  /* height: 100%; */
  /* width: 100%; */
  /* top: 143%; */
  position: relative;
  overflow: hidden;
  padding-bottom: 8rem;
  padding-top: 7rem;
}
.city .section-form .contact_text .about-title {
  font-size: 4.5rem;
}
.city .section-form .contact_text p {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 1.5rem;
  max-width: 300px;
}
.city .section-form .btn-send-form {
  background-color: #0F4561;
  border-color: #0F4561;
  font-size: 1rem;
  width: 256px;
  height: 50px;
}
.city .section-form form label {
  color: #062536;
  font-size: 1rem;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
.city .section-form form .form-control {
  background-color: #87acd2;
  background: #87acd2;
  border-color: #87acd2;
  color: #DBDBDB;
}
.city .section-form form .form-control::placeholder {
  color: #DBDBDB;
}

.prom-dashboard {
  width: 8%;
  margin: 2%;
}

.content-gray {
  height: 100vh;
}
.content-gray .p_description {
  position: absolute;
  bottom: 70%;
}

.opinion {
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  overflow-x: clip;
}
.opinion .smock {
  width: 600px;
  height: 600px;
}
.opinion .circle {
  width: 200px;
  height: 200px;
  top: 0%;
  position: absolute;
  left: 16%;
}
.opinion .particle {
  width: 300px;
  height: 300px;
  bottom: -12%;
  position: absolute;
  right: 9%;
}
.opinion .cloud {
  width: 100%;
  height: 20%;
  top: 119%;
  position: absolute;
}
.opinion .material_text {
  width: 30%;
  height: 20%;
  top: 200%;
  left: 39%;
  position: absolute;
}
.opinion .material_text p {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 1.9rem;
}
.opinion .material_title {
  width: 30%;
  height: 20%;
  top: 159%;
  left: 29%;
  position: absolute;
}
.opinion .material_title p {
  font-size: 4.9rem;
}
.opinion .circle_2 {
  width: 100%;
  height: 35%;
  top: 209%;
  position: absolute;
  right: 24%;
}
.opinion .text {
  top: 322px;
  color: #0F4561;
}

.footer {
  position: relative;
  width: 100%;
  background-color: #0F4561;
  color: #739FCB;
  text-align: center;
  min-height: 30%;
  bottom: -123%;
  left: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: stretch;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}
.footer img {
  top: 0;
}
.footer .responsability {
  max-width: 300px;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
  border: none !important;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  border: none !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #739FCB;
  border-radius: 10px;
  border: none !important;
}

.theme-green .bs-datepicker-body table td.week span {
  display: none !important;
}

@font-face {
  font-family: "Lato-Bold";
  src: url("../../assets/fonts/Lato/Lato-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Lato-Regular";
  src: url("../../assets/fonts/Lato/Lato-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins-regular";
  src: url("../../assets/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins-semibold";
  src: url("../../assets/fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Ubuntu-regular";
  src: url("../../assets/fonts/Ubuntu/Ubuntu-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Ubuntu-semibold";
  src: url("../../assets/fonts/Ubuntu/Ubuntu-Bold.ttf") format("truetype");
}
body {
  color: #2D2D2D;
  font-family: "Ubuntu-Regular";
  font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Ubuntu-regular";
  margin-bottom: 0;
}
h1.normal,
h2.normal,
h3.normal,
h4.normal,
h5.normal,
h6.normal {
  font-family: "Ubuntu-Regular";
}

.nav-menu li {
  font-family: "Ubuntu-regular";
}
.nav-menu li.selected {
  font-family: "Ubuntu-semibold";
}

button,
a {
  font-family: "Ubuntu-regular";
}

.semibold {
  font-family: "Ubuntu-semibold";
}

.p-semibold {
  font-family: "Poppins-semibold";
}

.title-regular {
  font-family: "Ubuntu-regular";
}

header {
  align-items: center;
  background-color: #fff;
  border-radius: 7px;
  box-shadow: 0px 0px 20px #0000000D;
  display: flex;
  height: 55px;
  justify-content: space-between;
  left: calc(255px + 2rem);
  padding: 0 2rem;
  position: fixed;
  top: 1.5rem;
  transition: all 1s;
  width: calc(100% - 255px - 4rem);
  z-index: 3;
}
header .user-menu {
  align-items: center;
  background-color: #F6F6F6;
  border-radius: 7px;
  cursor: pointer;
  display: flex;
  padding-right: 1rem;
  position: relative;
}
header .user-menu img {
  border-radius: 50%;
  height: 30px;
  object-fit: cover;
}
header .user-menu p {
  color: #739FCB;
}
header .user-menu .user-icon {
  align-items: center;
  background-color: #739FCB;
  border-radius: 7px;
  display: flex;
  height: 2.2rem;
  justify-content: center;
  margin-right: 0.5rem;
  width: 2.2rem;
}
header .user-menu .user-icon i {
  color: #fff;
  font-size: 1.5rem;
}
header .user-menu .dropdown-profile {
  background-color: #fff;
  box-shadow: 0px 0px 20px #0000000D;
  border-radius: 5px;
  display: none;
  max-width: 100vw;
  padding: 2rem 1.2rem;
  position: absolute;
  right: -25px;
  top: calc(100% + 25px);
  transition: all 1s;
  width: 250px;
}
header .user-menu .dropdown-profile.open {
  display: block;
}
header .user-menu .dropdown-profile .user-icon {
  align-items: center;
  display: flex;
  height: 100px;
  justify-content: center;
  margin: 0 auto;
  width: 100px;
}
header .user-menu .dropdown-profile .user-icon i {
  font-size: 67px;
}
header .user-menu .dropdown-profile img {
  height: auto;
  width: 40%;
}
header .user-menu .dropdown-profile:before {
  border-color: transparent transparent #fff transparent;
  border-style: solid;
  border-width: 0 15px 12px 15px;
  content: "";
  position: absolute;
  right: 25px;
  top: -12px;
}
header .user-menu .dropdown-profile .btn-profile {
  align-items: center;
  border-radius: 2px;
  border: 1px solid;
  box-shadow: none;
  color: #F6F6F6;
  display: flex;
  font-weight: 100;
  justify-content: center;
}
header.aside-xs {
  left: calc(50px + 2rem);
  width: calc(100% - 50px - 4rem);
}

@media (max-width: 575.98px) {
  header.aside-xs p:first-child {
    visibility: hidden;
  }
}
aside {
  background-color: #fff;
  box-shadow: 0px 0px 20px #0000000D;
  min-height: 100vh;
  padding: 0.7rem;
  position: fixed;
  transition: all 0.7s;
  width: 255px;
  z-index: 4;
}
aside .lnr-menu {
  color: #2D2D2D;
  cursor: pointer;
  float: right;
  font-size: 20px;
}
aside nav a {
  border-bottom: 0 !important;
}
aside nav a img {
  width: 55%;
}
aside nav .nav-menu {
  color: #989898;
  list-style: none;
  margin: 10px 5px;
  padding: 0;
  transition: all 0.7s;
}
aside nav .nav-menu li {
  cursor: pointer;
  margin: 6px 0;
  outline: 0;
  position: relative;
  color: #989898;
}
aside nav .nav-menu li p {
  align-items: center;
  border-radius: 5px;
  display: flex;
  padding: 5px 10px;
  padding-right: 0;
}
aside nav .nav-menu li p i {
  font-size: 1.5rem;
  margin-right: 5px;
}
aside nav .nav-menu li p span {
  transition: all 0.1s;
  opacity: 1;
  visibility: visible;
}
aside nav .nav-menu li ul {
  list-style: none;
  max-height: 0;
  overflow: hidden;
  padding-left: 1.8rem;
  transition: all 0.3s;
}
aside nav .nav-menu li ul li {
  transition: all 0.7s;
  margin: 6px 0;
  margin-bottom: 9px;
  padding-left: 0.8rem;
  position: relative;
}
aside nav .nav-menu li ul li:before {
  background-color: #989898;
  border-radius: 50%;
  content: "";
  left: 0;
  height: 3px;
  position: absolute;
  top: 8px;
  width: 3px;
}
aside nav .nav-menu li.selected p {
  background-color: #F6F6F6;
  color: #739FCB;
}
aside nav .nav-menu li.selected p i {
  color: #0F4561;
}
aside nav .nav-menu li.selected p span {
  background-color: #F6F6F6;
  color: #739FCB;
  font-weight: bold;
}
aside nav .nav-menu li .open-submenu {
  transition: all 0.7s;
  float: right;
}
aside nav .nav-menu li.has-children p .la-angle-up {
  display: none;
}
aside nav .nav-menu li.has-children.selected {
  background-color: initial;
}
aside nav .nav-menu li.has-children.selected p {
  background-color: #F6F6F6;
}
aside nav .nav-menu li.has-children.selected p .la-angle-up {
  display: block;
}
aside nav .nav-menu li.has-children.selected p .la-angle-down {
  display: none;
}
aside nav .nav-menu li.has-children.selected ul {
  max-height: initial;
}
aside .social-media {
  background-color: #fff;
  bottom: 0;
  left: 0;
  padding: 15px 10px;
  position: fixed;
  text-align: center;
  width: 255px;
}
aside .social-media a {
  color: #739FCB;
  font-size: 24px;
  margin: 0 5px;
  text-decoration: none;
}
aside.aside-xs {
  width: 50px;
  transition: all 0.7s;
}
aside.aside-xs p {
  display: none;
}
aside.aside-xs .la-bars {
  margin-left: 8px;
}
aside.aside-xs nav img {
  width: 100%;
}
aside.aside-xs nav .nav-menu {
  margin-left: 0;
  margin-right: 0;
}
aside.aside-xs nav .nav-menu li {
  padding: 0;
}
aside.aside-xs nav .nav-menu li ul {
  padding-left: 0;
  transition: all 0.3s;
}
aside.aside-xs nav .nav-menu li ul li {
  display: flex;
  justify-content: center;
}
aside.aside-xs nav .nav-menu li ul li i {
  display: block !important;
  font-size: 1.2rem;
}
aside.aside-xs nav .nav-menu li ul li:before {
  content: unset;
}
aside.aside-xs nav .nav-menu li .la-angle-down, aside.aside-xs nav .nav-menu li .la-angle-up {
  display: none !important;
}
aside.aside-xs nav .nav-menu li p {
  justify-content: center;
  padding: 5px 3px;
}
aside.aside-xs nav .nav-menu li i {
  font-size: 1.5rem;
  margin-left: 0;
  margin-right: 0;
}
aside.aside-xs nav .nav-menu li span {
  visibility: hidden;
  opacity: 0;
  width: 0;
  height: 0;
}
aside.aside-xs .social-media {
  display: none;
}

.input-error {
  background-color: #F6F6F6;
  border-radius: 5px;
  color: #F26363;
  max-height: 0;
  overflow: hidden;
  position: absolute;
  transition: all 0.3s;
  width: 100%;
  z-index: 1;
}
.input-error span {
  background-color: #F26363;
  color: #fff;
  font-size: 1.5rem;
  height: 100%;
  left: 0;
  padding-top: 0.2rem;
  position: absolute;
  text-align: center;
  top: 0;
  width: 2rem;
}
.input-error.opened {
  max-height: initial;
  padding: 0.5rem 1rem 0.5rem 3rem;
}

.box {
  background-color: #fff;
  border-radius: 10px;
}
.box--permissions__permission {
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
}
.box--permissions__permission .la-angle-down {
  transform: rotate(-90deg);
}
.box--permissions__list {
  display: none;
  list-style: none;
  margin: 5px 1.5rem;
  padding: 5px 0;
}
.box--permissions__list li {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.box--permissions__list li:before {
  background-color: #DBDBDB;
  bottom: 5px;
  content: "";
  height: 1px;
  left: 5%;
  position: absolute;
  width: 92%;
}
.box--permissions__list li .permission-name,
.box--permissions__list li .onoffswitch {
  background-color: #fff;
  z-index: 1;
}
.box--permissions__list li .permission-name {
  padding-right: 5px;
}
.box--permissions .active .box--permissions__permission {
  background-color: #F6F6F6;
  color: #2D2D2D;
}
.box--permissions .active .box--permissions__permission .la-angle-down {
  transform: rotate(0deg);
}
.box--permissions .active .box--permissions__list {
  display: block;
}
.box--pagination {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.box--pagination span {
  width: 7rem;
  white-space: nowrap;
}
.box .box--permissions__permission:hover {
  background-color: #F6F6F6;
  border-radius: 10px;
  padding: 10px 20px;
  color: #2D2D2D;
}

.btn {
  border-radius: 5px;
  font-size: 14px;
  height: 2rem;
  padding: 0 1.5rem;
  min-width: content-box;
}
.btn.btn-primary {
  background-color: #69B6F2;
  border-color: #69B6F2;
  color: #fff;
}
.btn.btn-primary.bordered {
  background-color: #fff;
  color: #739FCB;
}
.btn.btn-primary:hover {
  background-color: #739FCB;
}
.btn.btn-default {
  background-color: #739FCB;
  border-color: #739FCB;
  color: #fff;
}
.btn.btn-default.bordered {
  background-color: #fff;
  color: #fff;
}
.btn.btn-default:hover {
  background-color: #87acd2;
  border-color: #87acd2;
}
.btn.btn-default span {
  color: #fff;
  background-color: #739FCB;
}
.btn.btn-info {
  background-color: #69B6F2;
  border-color: #69B6F2;
  color: #fff;
}
.btn.btn-info.bordered {
  color: #69B6F2;
}
.btn.btn-icon {
  align-items: center;
  display: flex;
  height: 2rem;
  justify-content: center;
  padding: 0;
  width: 2rem;
}
.btn.btn-icon i {
  color: #4C4C9F;
}
.btn.btn-error {
  background-color: #F9F9F9;
  border-color: #F9F9F9;
  color: #ACACEA;
}
.btn.btn-error.bordered {
  background-color: #F9F9F9;
  color: #F9F9F9;
}

.inn-form .form-group {
  margin-bottom: 0.5rem;
}
.inn-form .form-group label {
  color: #739FCB;
  font-size: 11px;
  margin-bottom: 0;
}
.inn-form .form-group .form-control {
  background-color: #F6F6F6;
  border-color: #F6F6F6;
  border-radius: 7px;
  font-size: 14px;
  height: 2.3rem;
}
.inn-form--filters .form-group .form-control {
  background-color: #F6F6F6;
}

.ck-editor__editable_inline {
  min-height: 500px;
}

/* Hide the browser's default checkbox */
.container-check {
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-bottom: 12px;
  padding-left: 28px;
  padding-top: 1px;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* Create a custom checkbox */
}
.container-check:hover input ~ .checkmark {
  background-color: #fff;
  box-shadow: 0px 4px 10px #0000001F;
}
.container-check .checkmark {
  background-color: #F6F6F6;
  border-radius: 4px;
  height: 20px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 20px;
}
.container-check .checkmark:after {
  border: solid white;
  border-width: 0 2px 2px 0;
  content: "";
  display: none;
  height: 12px;
  left: 7px;
  position: absolute;
  top: 2px;
  width: 5px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.container-check input {
  cursor: pointer;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;
}
.container-check input:checked ~ .checkmark {
  background-color: #739FCB;
}
.container-check input:checked ~ .checkmark:after {
  display: block;
}

.onoffswitch {
  position: relative;
  width: 35px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.onoffswitch-checkbox {
  opacity: 0;
  position: absolute;
  pointer-events: none;
}

.onoffswitch-label {
  border-radius: 18px;
  cursor: pointer;
  display: block;
  overflow: hidden;
}

.onoffswitch-inner {
  display: block;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
  width: 200%;
}

.onoffswitch-inner:before, .onoffswitch-inner:after {
  box-sizing: border-box;
  color: white;
  display: block;
  font-family: Trebuchet, Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  float: left;
  height: 18px;
  line-height: 18px;
  padding: 0;
  width: 50%;
}

.onoffswitch-inner:before {
  content: "";
  padding-left: 29px;
  background-color: rgba(115, 159, 203, 0.15);
}

.onoffswitch-inner:after {
  background-color: rgba(152, 152, 152, 0.3);
  content: "";
  padding-right: 29px;
  text-align: right;
}

.onoffswitch-switch {
  background: #989898 !important;
  border-radius: 18px;
  bottom: 0;
  display: block;
  height: 17px;
  position: absolute;
  right: 18px;
  top: 0;
  transition: all 0.3s ease-in 0s;
  width: 17px;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  background-color: #739FCB !important;
  right: 0px;
}

.pagination {
  padding: 0 25px;
  position: relative;
}
.pagination .nav-links {
  align-items: center;
  display: inline-flex;
  flex-wrap: wrap;
}
.pagination .nav-links .page-numbers {
  align-items: center;
  background-color: #F6F6F6;
  border-radius: 7px;
  border: 1px solid #F6F6F6;
  color: #2D2D2D;
  display: flex;
  font-size: 11px;
  height: 30px;
  justify-content: center;
  margin: 0 0.15rem;
  text-decoration: none !important;
  width: 30px;
}
.pagination .nav-links .page-numbers.active {
  border-color: #69B6F2;
  background-color: #69B6F2;
  color: #fff;
}
.pagination .nav-links .page-numbers:first-child, .pagination .nav-links .page-numbers:last-child {
  background-color: #F6F6F6;
  border: 0;
  color: #2D2D2D;
  font-size: 1.2rem;
}

.loading {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1051;
}

.spinner {
  margin: 100px auto;
  width: 100px;
  height: 100px;
  position: relative;
  text-align: center;
  -webkit-animation: sk-rotate 2s infinite linear;
  animation: sk-rotate 2s infinite linear;
}

.dot1, .dot2 {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  border-radius: 100%;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.dot2 {
  background-color: #4C4C9F;
  top: auto;
  bottom: 0;
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.dot1 {
  background-color: #739FCB;
}

@-webkit-keyframes sk-rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes sk-rotate {
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes sk-bounce {
  0%, 100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
.modal-dialog {
  align-items: center;
  display: flex;
  margin: 0 auto;
  min-height: 100vh;
}

.modal .modal-content {
  border: 0;
}
.modal .modal-content .modal-header {
  border: 0;
  justify-content: center;
  padding: 1rem 0rem 0;
}
.modal .modal-content .modal-header .icon-message {
  font-size: 2.5rem;
}
.modal .modal-content .modal-header .icon-message.error {
  color: #F26363;
}
.modal .modal-content .modal-header .icon-message.success {
  color: #90E383;
}
.modal .modal-content .modal-header .close {
  position: absolute;
  right: 1rem;
}
.modal .modal-content .modal-footer {
  justify-content: center;
}

.table thead th {
  border-top: none;
}
.table tbody td {
  margin: 0;
  padding: 0;
  padding: 0.75rem;
  border-bottom: 0px;
  border-left: 1px solid #F6F6F6;
  border-right: 1px solid #F6F6F6;
  color: #989898;
}
.table tbody td a {
  color: #989898;
  text-decoration: none !important;
  transform-origin: center;
}
.table tbody tr td a i {
  font-size: 1.5rem;
}
.table tbody tr .td-permissions {
  padding: 10px 20px;
}
.table tbody tr:hover:not(.active), .table tbody tr.selected:not(.active) {
  background-color: #F6F6F6;
  border-radius: 10px;
}
.table tbody tr:hover:not(.active) td, .table tbody tr.selected:not(.active) td {
  color: #2D2D2D;
}
.table tbody tr:hover:not(.active) td a i, .table tbody tr.selected:not(.active) td a i {
  color: #2D2D2D;
  transform: scale(1);
}
.table tbody tr:hover:not(.active) .td-hover {
  border-radius: 10px 0px 0px 10px;
}
.table tbody tr.selected {
  position: relative;
}
.table tbody tr.selected td:first-child {
  border-radius: 10px 0px 0px 10px;
}
.table tbody tr.selected:after {
  background-color: #739FCB;
  position: absolute;
  content: "";
  height: 1.8rem;
  margin-top: 7px;
  transform: translateX(-3px);
  width: 4px;
  right: 0;
}
.table tbody .non-hover:hover:not(.active) {
  background-color: unset;
  border-radius: 0px;
}
.table tbody .non-hover:hover:not(.active) td:first-child {
  border-radius: 0;
}
.table.no-border-top thead {
  border-top: 0;
  border-bottom: 1px solid #DBDBDB;
}
.table__image {
  border-radius: 50%;
  height: 2rem;
  margin: 0 auto;
  width: 2rem;
}

.table_properties thead th {
  border: 1px solid #DBDBDB;
  border-top: none;
}
.table_properties tbody td {
  margin: 0;
  padding: 0;
  padding: 8px 0rem;
  border-bottom: 0px;
  border-left: 1px solid #F6F6F6;
  border-right: 1px solid #F6F6F6;
}
.table_properties tbody td a {
  color: #DBDBDB;
  text-decoration: none !important;
  transform-origin: center;
}
.table_properties tbody tr:hover:not(.active), .table_properties tbody tr.selected:not(.active) {
  background-color: #F6F6F6;
}
.table_properties tbody tr:hover:not(.active) td, .table_properties tbody tr.selected:not(.active) td {
  color: #2D2D2D;
}
.table_properties tbody tr:hover:not(.active) td a i, .table_properties tbody tr.selected:not(.active) td a i {
  transform: scale(1.1);
}
.table_properties tbody tr.selected {
  position: relative;
}
.table_properties tbody tr.selected td:first-child {
  background-color: #DBDBDB;
}
.table_properties tbody tr.selected:after {
  background-color: #DBDBDB;
  position: absolute;
  content: "";
  height: 1.8rem;
  margin-top: 7px;
  transform: translateX(-3px);
  width: 4px;
}
.table_properties.no-border-top thead {
  border-top: 0;
}
.table_properties__image {
  border-radius: 50%;
  height: 2rem;
  margin: 0 auto;
  width: 2rem;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered th {
  border: 0px solid #F6F6F6;
  color: #739FCB;
  border-bottom: 1px solid #DBDBDB;
  border-top: 1px solid #DBDBDB;
}
.table-responsive > .table-bordered td {
  border-bottom: 0px solid #DBDBDB;
  border-top: 0px solid #DBDBDB;
  border-left: 0px solid #F6F6F6;
  border-right: 0px solid #F6F6F6;
}
.table-responsive > .table-bordered td a i {
  font-size: 1.5rem;
}

.container-drawer {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: all ease-in-out 0.4s;
}
.container-drawer--menu {
  transform: translateX(-100%);
}
.container-drawer--user {
  transform: translateX(100%);
}
.container-drawer--user .info-user__avatar {
  background: #739FCB;
  border-radius: 10px;
}
.container-drawer .drawer {
  z-index: 10;
  position: relative;
  padding: 20px 20px 20px 25px;
  width: 84.5%;
  box-shadow: 0px 0px 20px #0000000D;
}
.container-drawer .drawer-user {
  float: right;
  right: 0;
}
.container-drawer .drawer .la-long-arrow-alt-left, .container-drawer .drawer .la-long-arrow-alt-right {
  background: #F6F6F6;
  border-radius: 5px;
  padding: 3.5px;
  font-size: 1.5rem;
  color: #2D2D2D;
}
.container-drawer .drawer .item-menu-text {
  font-size: 16px;
  font-family: "Poppins-regular";
  color: #989898;
  font-weight: 400;
  margin-left: 15px;
}
.container-drawer .drawer .items-menu {
  list-style: none;
  padding: 0;
}
.container-drawer .drawer .items-menu i {
  font-size: 1.5rem;
  margin-left: 0px;
  color: #DBDBDB;
}
.container-drawer .drawer-overlay {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
}
.container-drawer.active {
  transform: translateX(0);
}

@media (max-width: 767.98px) {
  .header-top {
    background-color: #fff;
    box-shadow: 0px 0px 20px #0000000D;
    padding: 15px 25px;
    position: fixed;
    width: 100%;
    z-index: 3;
  }
  .header-top .la-bars {
    font-size: 1.5rem;
    color: #4C4C9F;
  }
  .header-top nav .nav-menu div {
    display: flex;
    align-items: center;
  }
  .header-top nav .nav-menu i {
    font-size: 1.5rem;
    margin-left: 0px;
    color: #4C4C9F;
  }
  .header-top nav .nav-menu span {
    color: #4C4C9F;
    font-family: "Poppins-regular";
    font-size: 16px;
  }
  .header-top .options .la-bell {
    background: #F6F6F6;
    border-radius: 5px;
    padding: 6px;
    font-size: 1.2rem;
    color: #2D2D2D;
  }
  .header-top .options .la-user {
    background: #4C4C9F;
    border-radius: 5px;
    padding: 6px;
    font-size: 1.2rem;
    color: #fff;
    margin-left: 10px;
  }
}
.roles-permissions-bottom {
  margin-top: 12rem;
  position: absolute;
  left: 0;
  right: 0;
  padding: 25px 25px 25px 25px;
}
.roles-permissions-bottom .roles-permissions-bottom-head {
  padding-bottom: 11px;
}
.roles-permissions-bottom .roles-permissions-bottom-head .arrow-back .la-long-arrow-alt-left {
  background: #F6F6F6;
  border-radius: 5px;
  padding: 3.5px;
  font-size: 1.5rem;
  color: #2D2D2D;
}
.roles-permissions-bottom .roles-permissions-bottom-head .txt-bottom {
  color: #4C4C9F;
  margin-left: 16px;
  margin-right: 10px;
}
.roles-permissions-bottom .divisor {
  position: absolute;
  left: 0;
  right: 0;
}
.roles-permissions-bottom .roles-permissions-bottom-body {
  padding: 20px 0;
}
.roles-permissions-bottom .roles-permissions-bottom-body .txt-bottom-body {
  font-size: 15px;
  color: #989898;
}
.roles-permissions-bottom .roles-permissions-bottom-body .arrow-collapse .la-angle-right {
  font-size: 1rem;
  color: #000000;
  transition: all ease-in-out 0.3s;
}
.roles-permissions-bottom .roles-permissions-bottom-body .box-permissions-list {
  list-style: none;
  height: 0;
  overflow-y: hidden;
  transition: all ease-in-out 0.3s;
  width: 100%;
  margin-bottom: 0;
  padding-left: 15px;
  padding-right: 25px;
}
.roles-permissions-bottom .roles-permissions-bottom-body .box-permissions-list.active {
  height: auto;
  margin: 21px 0px 32px 0px;
}
.roles-permissions-bottom .roles-permissions-bottom-body .box-permissions-list .permission-name {
  color: #989898;
  font-size: 14px;
  z-index: 1;
  padding-right: 5px;
}
.roles-permissions-bottom .roles-permissions-bottom-body .box-permissions-list li {
  position: relative;
  margin-bottom: 10px;
}
.roles-permissions-bottom .roles-permissions-bottom-body .box-permissions-list li:before {
  background-color: #DBDBDB;
  bottom: 3px;
  content: "";
  height: 1px;
  left: 5%;
  position: absolute;
  width: 82%;
}
.roles-permissions-bottom .roles-permissions-bottom-body .item-select {
  padding: 10px 25px 10px 15px;
  transition: all ease-in-out 0.3s;
  cursor: pointer;
}
.roles-permissions-bottom .roles-permissions-bottom-body .active-arrow {
  transform: rotate(90deg);
}
.roles-permissions-bottom .roles-permissions-bottom-body .selected {
  border-radius: 5px;
  background-color: #F9F9F9;
  padding: 10px 25px 10px 15px;
}
.roles-permissions-bottom .roles-permissions-bottom-body .selected .txt-bottom-body {
  color: #2D2D2D;
}

.container-drawer-notifications {
  transform: translateX(150%);
}
.container-drawer-notifications .drawer-notifications .drawer-header {
  padding: 20px 25px;
  background-color: #4C4C9F;
}
.container-drawer-notifications .drawer-notifications .drawer-header .txt-title {
  color: #fff;
}
.container-drawer-notifications .drawer-notifications .info-notifications {
  border-bottom: 1px solid #2D2D2D;
  padding: 20px 25px;
}
.container-drawer-notifications .drawer-notifications .info-notifications .title-info-notifications {
  color: #000;
}
.container-drawer-notifications .drawer-notifications .info-notifications .txt-info-notifications {
  color: #989898;
}
.container-drawer-notifications .drawer-notifications .la-long-arrow-alt-right, .container-drawer-notifications .drawer-notifications .la-map {
  background: #F6F6F6;
  border-radius: 5px;
  padding: 3px;
  font-size: 1.5rem;
  color: #000;
}

.container-drawer-notifications.active {
  transform: translateX(15.5%);
}

.container-drawer-notifications .drawer-overlay {
  transform: translateX(-15.5%);
}

.drop-container {
  background: #F0F0F7 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 130px !important;
  width: 100% !important;
  outline-offset: -8px !important;
  margin-bottom: 20px;
}
.drop-container .upload-button {
  display: inline-block !important;
  border: none !important;
  outline: 0 !important;
  cursor: pointer !important;
  padding: 0 2px !important;
}
.drop-container .upload-button input {
  display: none !important;
}
.drop-container .text-drop {
  padding: 20px !important;
  margin: 10px !important;
}

.sidebar {
  background-color: #fff;
  box-shadow: 0px 4px 10px #0000001F;
  height: 100vh;
  left: 0;
  max-width: 100%;
  min-width: 240px;
  overflow-y: auto;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: calc(255px + 4rem);
  transform: translateX(-100%);
  transition: all 0.3s;
  z-index: 6;
}
.sidebar.opened {
  left: 255px;
  opacity: 1;
  pointer-events: initial;
  transform: translate(0);
}
.sidebar--right {
  left: auto !important;
  right: 0;
  transform: translateX(100%);
}
.sidebar--right.opened {
  transform: translate(0);
}
.sidebar--top {
  left: 0 !important;
  top: 0;
  transform: translateY(-100%);
}
.sidebar--top.opened {
  transform: translate(0);
}
.sidebar--large {
  width: 320px;
}
.sidebar--full {
  min-height: 100vh;
  width: 100%;
}
.sidebar--auto {
  height: auto;
}
.sidebar--auto.sidebar--right {
  border-bottom-left-radius: 5px;
}
.sidebar--alert {
  border-radius: 5px;
  height: auto;
  min-width: 25rem;
  overflow: hidden;
  top: calc(1.8rem + 55px);
}
.sidebar--alert.success {
  border-left: 5px solid #28a745;
}
.sidebar--alert.error {
  border-left: 5px solid #dc3545;
}

.menu-xs .sidebar.opened {
  left: 50px;
}

@media (max-width: 1024px) {
  .medidor_landing .text_quality_face p {
    font-size: 2.5rem;
  }

  .p_description {
    margin-bottom: 2rem;
  }

  .medidor .text_quality_face p {
    font-size: 2rem;
  }
}
@media (max-width: 991px) {
  .back {
    margin-top: 72px;
  }
}
@media (max-width: 768px) {
  .data {
    background: #0F4561;
    z-index: 0;
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
  }

  .back {
    margin-top: 72px;
  }

  .map {
    height: 500px;
    float: unset;
    position: static;
    left: unset;
    width: 100%;
    padding: 0 20px;
    background-color: #0F4561;
    border-radius: 0px;
  }

  .medidor_landing {
    min-height: 500px;
    height: auto;
    max-height: unset;
    width: auto;
    float: unset;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    margin: 0 20px;
  }

  .ul_map {
    position: static;
    left: unset;
  }

  .face_map_landing {
    float: unset;
    left: 7em;
    top: -5em;
  }

  .cloud_map {
    float: unset;
    left: 0;
    width: 94px;
    height: 63px;
  }

  .city .section-form .contact_text .about-title {
    font-size: 3rem;
  }
  .city .section-form .contact_text p {
    font-size: 1rem;
  }

  .city .section-form .btn-send-form {
    width: 100%;
  }

  .data .smock {
    width: 400px;
    height: 400px;
  }

  .data {
    padding-top: 100px !important;
  }

  .data .container .monitor {
    padding-top: 200px !important;
  }

  .data .particle {
    bottom: unset;
    right: -152px;
    left: unset;
    top: 10%;
  }

  .opinion .circle {
    width: 200px;
    height: 200px;
    top: -4%;
    position: absolute;
    left: 11%;
  }

  .opinion .material_title p {
    font-size: 1.8rem;
  }

  .opinion .material_text p {
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
    font-size: 1.1rem;
  }

  .opinion .material_title {
    top: 185%;
    width: 100%;
    left: 0%;
  }
  .opinion .material_title p {
    text-align: center !important;
  }

  .opinion .material_text {
    width: 100%;
    top: 206%;
    left: 0;
    padding: 0 15px;
  }
  .opinion .material_text p {
    text-align: center !important;
  }

  .opinion .circle_2 {
    width: 100%;
    height: 35%;
    top: 231%;
    position: absolute;
    right: 24%;
  }

  .opinion .particle {
    width: 300px;
    height: 300px;
    bottom: -12%;
    position: absolute;
    right: 9%;
  }

  .testimonials img {
    width: 50% !important;
  }
}
@media (max-width: 540px) {
  .map {
    height: 500px;
    float: unset;
    position: static;
    left: unset;
    width: 100%;
    padding: 0 20px;
    background-color: #0F4561;
    border-radius: 0px;
  }

  .medidor_landing {
    min-height: 500px;
    height: auto;
    width: 100%;
    float: unset;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    margin: 0;
  }

  .container_map {
    background-color: #0F4561;
  }

  .medidor_landing .text_quality_face p {
    font-size: 2.3rem;
  }

  .p_description {
    margin-bottom: 2rem;
  }

  .ul_map {
    position: static;
    left: unset;
  }

  .face_map_landing {
    float: unset;
    left: 7em;
    top: -5em;
  }

  .cloud_map {
    float: unset;
    left: 0;
    width: 94px;
    height: 63px;
  }

  .city .section-form .contact_text .about-title {
    font-size: 3rem;
  }
  .city .section-form .contact_text p {
    font-size: 1.2rem;
  }

  .data .smock {
    width: 200px;
    height: 200px;
  }

  .data {
    padding-top: 100px !important;
  }

  .data .container .monitor {
    padding-top: 200px !important;
  }

  .data .particle {
    bottom: unset;
    right: -206px;
    left: unset;
    top: 10%;
  }

  .opinion .circle {
    width: 200px;
    height: 200px;
    top: 9%;
    position: absolute;
    left: 16%;
  }

  .back .container-know-us {
    margin-top: 0px;
  }
}
.container-drawer {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: all ease-in-out 0.4s;
}
.container-drawer--menu {
  transform: translateX(-100%);
}
.container-drawer .drawer {
  z-index: 10;
  position: relative;
  padding: 20px 20px 20px 25px;
  width: 300px;
  box-shadow: 0px 0px 20px #0000000D;
  background-color: #0F4561;
}
.container-drawer .drawer .la-long-arrow-alt-left, .container-drawer .drawer .la-long-arrow-alt-right {
  background: #F6F6F6;
  border-radius: 5px;
  padding: 3.5px;
  font-size: 1.5rem;
  color: #2D2D2D;
}
.container-drawer .drawer .item-menu-text {
  font-size: 16px;
  font-family: "Poppins-regular";
  color: #DBDBDB;
  font-weight: 400;
  margin-left: 15px;
}
.container-drawer .drawer .items-menu {
  list-style: none;
  padding: 0;
}
.container-drawer .drawer .items-menu i {
  font-size: 1.5rem;
  margin-left: 0px;
  color: #DBDBDB;
}
.container-drawer .drawer-overlay {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
}
.container-drawer.active {
  transform: translateX(0);
}

@media (max-width: 1199.98px) {
  .header-top-drawer {
    background-color: #0F4561;
    box-shadow: 0px 0px 20px #0000000D;
    padding: 15px 25px;
    position: fixed;
    width: 100%;
    z-index: 3;
    height: 72px;
    top: 0;
  }
  .header-top-drawer .la-bars {
    font-size: 1.5rem;
    color: #fff;
  }
  .header-top-drawer nav .nav-menu div {
    display: flex;
    align-items: center;
  }
  .header-top-drawer nav .nav-menu i {
    font-size: 1.5rem;
    margin-left: 0px;
    color: #4C4C9F;
  }
  .header-top-drawer nav .nav-menu span {
    color: #4C4C9F;
    font-family: "Poppins-regular";
    font-size: 16px;
  }
  .header-top-drawer .options .la-user {
    background: #fff;
    border-radius: 5px;
    padding: 6px;
    font-size: 1.2rem;
    color: #0F4561;
    margin-left: 10px;
  }
}
.partners .circle {
  width: 200px;
  height: 200px;
  top: 0;
  left: 16%;
  z-index: 1;
}
.partners .background-image {
  z-index: 0;
}
.partners .container-info {
  margin-top: 350px;
  z-index: 1;
}
.partners .container-info .testimonials .opinion_title {
  color: #0F4561;
  font-size: 1.5rem;
  margin: auto;
  width: 60%;
}
.partners .container-info .testimonials .lead {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 1.4rem;
  max-width: 355px;
}
.partners .container-info h3 {
  color: #0F4561;
}

@media (max-width: 768px) {
  .partners .container-info h3 {
    font-size: 3rem;
  }
  .partners .container-info .testimonials .opinion_title {
    font-size: 1.8rem;
  }
  .partners .container-info .testimonials .lead {
    font-size: 1.2rem;
  }
}
.particle {
  margin-top: 120px;
  height: 580px;
}
.particle .img-particle {
  width: 300px;
  height: 300px;
  right: 0;
  top: -230px;
  z-index: 1;
}
.particle .cloud {
  width: 75%;
  min-width: 1000px;
}
.particle .circle {
  width: 200px;
  height: 200px;
  bottom: -133px;
  left: 193px;
}
.particle .container-info {
  padding-top: 150px;
}
.particle .container-info .material_title {
  margin-left: 150px;
  margin-bottom: 100px;
}
.particle .container-info .material_title p:first-child {
  font-size: 3rem;
}
.particle .container-info .material_title p:last-child {
  font-size: 4rem;
}
.particle .container-info .material_text p {
  font-size: 1.3rem;
  max-width: 360px;
}

@media (max-width: 768px) {
  .particle .circle {
    bottom: -215px;
    left: 117px;
  }
  .particle .container-info .material_title {
    margin-left: 0;
  }
}
@media (max-width: 540px) {
  .particle .container-info .material_title p:first-child {
    font-size: 1.8rem;
  }
  .particle .container-info .material_title p:last-child {
    font-size: 2.5rem;
  }
  .particle .container-info .material_text p {
    font-size: 1.2rem;
  }
}
.container-air-scale {
  max-height: calc(81vh);
  border-radius: 0px 0px 0px 200px;
  right: 0;
  z-index: 1;
  top: 0;
  width: 40%;
  margin-top: 78px;
}
.container-air-scale .header-air-scale {
  background-color: #90E383;
}
.container-air-scale .header-air-scale .la-long-arrow-alt-left {
  background-color: #b2eba8;
  border-radius: 5px;
  padding: 3.5px;
  font-size: 1.5rem;
}
.container-air-scale .header-air-scale .place {
  color: #062536;
  font-size: 1.3rem;
}
.container-air-scale .header-air-scale .qualification {
  color: #fff;
  font-size: 1.3rem;
}
.container-air-scale .header-air-scale .face-emoticon {
  width: 40px;
  height: 40px;
}
.container-air-scale .body-air-scale .filters-air-scale .form-group label {
  font-size: 0.8rem;
  max-width: 170px;
}
.container-air-scale .body-air-scale .filters-air-scale .form-group .form-control {
  border-radius: 5px;
  background-color: #F6F6F6;
  font-size: 0.8rem;
}
.container-air-scale .body-air-scale .filters-air-scale .form-group .form-control::placeholder {
  color: #989898;
}
.container-air-scale .body-air-scale .chart-air-scale .faces-air-scale .face-scale {
  width: 20px;
  height: 20px;
}
.container-air-scale .body-air-scale .chart-air-scale .box {
  height: 60%;
}
.container-air-scale .body-air-scale .chart-air-scale p {
  position: relative;
  top: -7rem;
}
.container-air-scale .body-air-scale #chart-air-perception {
  position: relative;
  top: -3rem;
}

@media (max-width: 991px) {
  .container-air-scale {
    margin-top: 72px;
  }
}
@media (max-width: 768px) {
  .container-air-scale {
    min-height: 500px;
    height: auto;
    max-height: unset;
    width: auto !important;
    float: unset;
    border-radius: 10px;
    overflow: hidden;
    position: relative !important;
    margin: 0 20px 50px 20px;
    top: unset;
  }
}
@media (max-width: 767.98px) {
  #bg-login .column-1 {
    height: 80vh;
    width: 100%;
  }
  #bg-login .column-2 {
    height: 20vh;
    width: 100%;
    top: 80%;
  }

  .d-xs-none {
    display: none;
  }
}
@media (min-width: 576px) {
  .content-info span {
    font-size: 2.5rem !important;
  }
  .content-info h2 {
    font-size: 1.6rem;
  }

  .form-content {
    display: flex;
  }
}
@media (min-width: 768px) {
  #bg-login .site-data {
    min-height: 100vh;
  }
}
@media (max-width: 768px) {
  bs-daterangepicker-container {
    transform: translate3d(-17px, 418px, 0px) !important;
  }
}