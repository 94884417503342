.sidebar {
    background-color: $base-color;
    box-shadow: $shadow3;
    height: 100vh;
    left: 0;
    max-width: 100%;
    min-width: 240px;
    overflow-y: auto;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    width: $sidebar-width;
    transform: translateX(-100%);
    transition: all 0.3s;
    z-index: 6;
    &.opened {
        left: $menu-width;
        opacity: 1;
        pointer-events: initial;
        transform: translate(0);
    }
    &--right {
        left: auto !important;
        right: 0;
        transform: translateX(100%);
        &.opened {
            transform: translate(0);
        }
    }
    &--top {
        left: 0 !important;
        top: 0;
        transform: translateY(-100%);
        &.opened {
            transform: translate(0);
        }
    }
    &--large {
        width: $sidebar-width-lg;
    }
    &--full {
        min-height: 100vh;
        width: 100%;
    }
    &--auto {
        height: auto;
        &.sidebar--right {
            border-bottom-left-radius: $border1;
        }
    }
    &--alert {
        border-radius: $border1;
        height: auto;
        min-width: 25rem;
        overflow: hidden;
        top: calc(#{$layout-padding} + #{$header-height});
        &.success {
            border-left: 5px solid map-get($theme-colors, success);
        }
        &.error {
            border-left: 5px solid map-get($theme-colors, danger);
        }
    }
}

.menu-xs {
    .sidebar {
        &.opened {
            left: $menu-width-xs;
        }
    }
}