.x-small {
    font-size: map-get($font-sizes, x-small);
    line-height: calc(#{map-get($font-sizes, x-small)} + 4px);
}

.fs-small {
    font-size: map-get($font-sizes, small);
}

.fs-normal {
    font-size: map-get($font-sizes, normal);
    line-height: calc(#{map-get($font-sizes, normal)} + 0.2rem);
}

.fs-big {
    font-size: map-get($font-sizes, big);
    line-height: calc(#{map-get($font-sizes, big)} + 0.3rem);
}

.fs-bigger {
    font-size: map-get($font-sizes, bigger);
}

p {
    margin: 0;
}

hr {
    margin: 0.5rem 0;
}

.pointer {
    cursor: pointer;
}

.nowrap {
    white-space: nowrap;
}

.color-white {
    color: $white !important;
}

.color-gray1 {
    color: $gray1 !important;
}

.color-gray2 {
    color: $gray2 !important;
}

.color-gray3 {
    color: $gray3 !important;
}

.color-black-light {
    color: $black-light !important;
}

.color-gray4 {
    color: $gray4 !important;
}

.color-primary {
    color: $primary-color !important;
}

.color-secondary {
    color: $secondary-color !important;
}

.txt-uppercase {
    text-transform: uppercase;
}

.p-medium {
    padding: 0 3rem;
}

.b-right {
    border-right: 1px solid $gray2;
}

.b-top {
    border-top: 1px solid $gray2;
}

.b-left {
    border-left: 1px solid $gray2;
}

.b-bottom {
    border-bottom: 1px solid $gray2;
}

.underline {
    border-bottom: 1px solid;
}

a {
    &:hover,
    &.selected {
        border-bottom: 1px solid;
        color: $primary-color;
        font-weight: 600;
        text-decoration: none;
    }
}

.searchbar {
    align-items: center;
    background-color: $gray1;
    border-radius: $border-input;
    display: none;
    width: 30%;
    &.show {
        display: flex;
    }
    span {
        font-size: 1rem;
        margin: 0 12px;
    }
    input {
        background-color: initial;
        border: 0;
        border-left: 1px solid $gray3;
        padding: 7px 15px;
        width: -webkit-fill-available;
    }
}

.navbar {
    background-color: $black-blue;
    color: $soft-blue;
    // position: fixed;
    width: 100%;
    z-index: 1000;
}

.nav-link {
    color: $soft-blue;
}

.medidor {
    background-color: $green;
    width: 40%;
    height: 500px;
    float: right;
    border-radius: 0px 0px 0px 200px;
    overflow: hidden;
    .text_cloud {
        p {
            font-size: 30px;
        }
    }
    .text_aqui_tittle {
        top: 7%;
        left: 57%;
        p {
            font-size: 30px;
        }
    }
    .text_quality_tittle {
        p {
            font-size: 15px;
        }
    }
    .text_quality_face {
        p {
            font-size: 2rem;
        }
    }
    .text_quality_p {
        // position: absolute;
        top: 23em;
        left: 5em;
        overflow: hidden;
        text-overflow: ellipsis;
        p {
            font-size: 15px;
        }
    }
}

.face {
    background-color: $green;
    width: 140px;
    height: 140px;
    float: right;
    z-index: 0;
    border-radius: 50%;
    position: absolute;
    top: 44%;
    left: 60%;
    background-image: url(../../assets/images/icons/face/face_bueno.svg);
}

.map {
  width: calc(100% - 116px);
  height: calc(100vh - 10%);
  float: right;
  z-index: 0;
  border-radius: 0px 0px 0px 480px;
  position: absolute;
  left: 116px;
}

.medidor_landing {
    width: 40%;
    max-height: calc(81vh);
    height: auto;
    float: right;
    border-radius: 0px 0px 0px 200px;
    overflow: hidden;
    position: relative;
  z-index: 1;
    .text_cloud {
        p {
            font-size: 30px;
        }
    }
    .text_aqui_tittle {
        p {
            font-size: 30px;
        }
    }
    .text_quality_tittle {
        p {
            font-size: 15px;
        }
    }
    .text_quality_face {
        p {
          font-size: 2rem;
          line-height: 1;
        }
    }
    .text_quality_p {
        // position: absolute;
        top: 23em;
        left: 5em;
        overflow: hidden;
        text-overflow: ellipsis;
        p {
            font-size: 15px;
        }
    }
}

.face_map {
    width: 8em;
    height: 7em;
    float: left;
    z-index: 1;
    border-radius: 50%;
    .p {
        font-size: 4rem;
    }
}

.face_map_landing {
    width: 10em;
    float: right;
    z-index: 1;
    border-radius: 50%;
    position: relative;
    left: 5em;
    top: 13em;
    .p {
        font-size: 4rem;
    }
}

.cloud_map {
    // background-color: $green;
    width: 94px;
    height: 63px;
    z-index: 0;
    // position: absolute;
    // left: 3em;
    // top: 1em;
    background-image: url(../../assets/images/icons/nube_aqi.svg);
    background-repeat: no-repeat;
    .p {
        font-size: 4rem;
        position: absolute;
        left: 15%;
        top: 100px;
    }
}

.ul_map_landing {
    position: absolute;
    left: 10em;
}

.more_detail {
    position: absolute;
    top: 28em;
    left: 2em;
}

.chart_icon {
    background-color: $white;
    border-radius: 5px;
}

.badge-primary {
    color: $white;
    background-color: $soft-blue;
}

.badge {
    font-size: 100%;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    width: 21px;
    height: 21px;
}

.f-subtitle {
    font-family: 'Poppins-regular';
    align-self: center;
    margin-left: 5px;
}

.card_title-bitacora {
    font-family: Calibri;
}

.card_text-bitacora {
    font-family: Calibri;
    color: $gray3;
    font-size: 1rem;
}

.bitacora-icons {
    color: $gray2;
    i:hover {
        color: $gray4;
    }
}

.badge_bitacora {
    top: -27%;
    left: 8.5em;
}

.btn-send {
    background-color: $soft-blue;
    color: $white;
}

.btn-send:hover {
    background-color: $blue;
    color: $white;
    // border-color: $soft-blue;
}

.sidebar {
    .form-control::placeholder {
        color: $gray3;
        font-size: 1rem;
        font-family: 'Poppins-regular';
    }
}

.bg-form_input {
    background-color: $gray1;
}

.btn .badge_comment {
    top: -0.7em;
}

.btn .badge {
    position: relative;
    /* top: -1px; */
}

.back {
    margin-top: 78px;

  .container_map {
    background: $black-blue;
    min-height: 860px !important;
  }

  .container-know-us {
    margin-top: 200px;
  }
}

.w-10{
    width: 10%;
}
.material_particulate {
    // background: $black-blue;
    // z-index: -9;
    // height: 100%;
    // width: 100%;
    // position: absolute;
    // top: 9%;
    /* z-index: -2; */
    /* height: 100%; */
    /* width: 100%; */
    position: absolute;
    /* display: flex; */
    display: block;
    /* left: 100%; */
    bottom: -144em;
}

.data {
    // top: 72%;
    background: $black-blue;
    z-index: -2;
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    .smock {
      width: 47%;
      height: auto;
    }
    .circle {
        width: 200px;
        height: 200px;
        bottom: 0%;
        position: absolute;
        left: 16%;
    }
    .particle {
        width: 300px;
        height: 300px;
        bottom: 10%;
        position: absolute;
        right: 0%;
    }
    .text {
        width: 100%;
        // height: 25%;
        // top: 18%;
        // position: relative;
    }
    .description {
        max-width: 300px;
    }
}

.title_emoticon{
    color: $black-light;
    font-size: 1rem;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.city {
    top: 127%;
    position: relative;
    overflow: hidden;
    .section-form {
        background-color: $primary-color;
        /* height: 100%; */
        /* width: 100%; */
        /* top: 143%; */
        position: relative;
        overflow: hidden;
        padding-bottom: 8rem;
        padding-top: 7rem;
        .contact_text {
            .about-title {
                font-size: 4.5rem;
            }
            p {
                font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
              font-size: 1.5rem;
              max-width: 300px;
            }
        }
        .btn-send-form {
            background-color: $black-blue;
            border-color: $black-blue;
            font-size: 1rem;
            width: 256px;
            height: 50px;
        }
        form {
            label {
                color: $black-light;
                font-size: 1rem;
                font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
            }
            .form-control {
                background-color: $light-blue;
                background: $light-blue;
                border-color: $light-blue;
                // opacity: 0.15;
                color: $gray2;
            }
            .form-control::placeholder {
                color: $gray2;
            }
        }
    }
}

.prom-dashboard{
    width: 8%;
    margin: 2%;
}
.content-gray{
    height: 100vh;

    .p_description{
        position: absolute;
        bottom: 70%;
    }
}
.opinion {
    // top: 72%;
    // background-image: url(../../assets/images/forma_circulo.svg);
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    overflow-x: clip;
    .smock {
        width: 600px;
        height: 600px;
    }
    .circle {
        width: 200px;
        height: 200px;
        top: 0%;
        position: absolute;
        left: 16%;
    }
    .particle {
        width: 300px;
        height: 300px;
        bottom: -12%;
        position: absolute;
        right: 9%;
    }
    .cloud {
        width: 100%;
        height: 20%;
        top: 119%;
        position: absolute;
    }
    .material_text {
        width: 30%;
        height: 20%;
        top: 200%;
        left: 39%;
        position: absolute;
        p {
            font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
            font-size: 1.9rem;
        }
    }
    .material_title {
        width: 30%;
        height: 20%;
        top: 159%;
        left: 29%;
        position: absolute;
        p {
            font-size: 4.9rem;
        }
    }
    .circle_2 {
        width: 100%;
        height: 35%;
        top: 209%;
        position: absolute;
        right: 24%;
    }
    .text {
        top: 322px;
        color: $black-blue;
    }
}

// .footer {
//     position: fixed;
//     width: 100%;
//     background-color: $black-blue;
//     color: $primary-color;
//     text-align: center;
//     min-height: 30%;
//     bottom: 0;
//     left: 0;
//     display: flex;
//     flex-direction: row;
//     flex-wrap: nowrap;
//     align-content: stretch;
//     justify-content: space-between;
//     align-items: center;
//     overflow: hidden;
// }
.footer {
    position: relative;
    width: 100%;
    background-color: #0F4561;
    color: #739FCB;
    text-align: center;
    min-height: 30%;
    bottom: -123%;
    left: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: stretch;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    img {
        top: 0;
    }
    .responsability {
        max-width: 300px;
    }
}


/* width */

::-webkit-scrollbar {
    width: 4px;
    border: none !important;
}


/* Track */

 ::-webkit-scrollbar-track {
    border-radius: 10px;
    border: none !important;
}


/* Handle */

 ::-webkit-scrollbar-thumb {
    background: $primary-color;
    border-radius: 10px;
    border: none !important;
}
.theme-green .bs-datepicker-body table td.week span{
    display: none !important;
  }

// .d-sm-none{
//     display: none;
// }