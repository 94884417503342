.container-air-scale {
  max-height: calc(81vh);
  border-radius: 0px 0px 0px 200px;
  right: 0;
  z-index: 1;
  top: 0;
  width: 40%;
  margin-top: 78px;

  .header-air-scale {
    background-color: $success;

    .la-long-arrow-alt-left {
      background-color: #b2eba8;
      border-radius: 5px;
      padding: 3.5px;
      font-size: 1.5rem;
    }

    .place {
      color: $black-light;
      font-size: 1.3rem;
    }

    .qualification {
      color: $white;
      font-size: 1.3rem;
    }

    .face-emoticon {
      width: 40px;
      height: 40px;
    }
  }

  .body-air-scale {
    .filters-air-scale {
      .form-group {
        label {
          font-size: 0.8rem;
          max-width: 170px;
        }

        .form-control {
          border-radius: 5px;
          background-color: $gray1;
          font-size: 0.8rem;
        }

        .form-control::placeholder {
          color: $gray3;
        }
      }
    }

    .chart-air-scale {
      .faces-air-scale {
        .face-scale {
          width: 20px;
          height: 20px;
        }
      }

      .box{
        height: 60%;
      }

      p{
        position: relative;
        top: -7rem;
      }
    }
    #chart-air-perception{
      position: relative;
      top: -3rem;
    }
  }
}

@media (max-width:991px) {
  .container-air-scale {
    margin-top: 72px;
  }
}

@media (max-width:768px) {
  .container-air-scale {
    min-height: 500px;
    height: auto;
    max-height: unset;
    width: auto !important;
    float: unset;
    border-radius: 10px;
    overflow: hidden;
    position: relative !important;
    margin: 0 20px 50px 20px;
    top: unset;
  }
}

@media (max-width:540px) {
  .container-air-scale {

  }
}
