.table {
    thead {
        th {
            // border: 1px solid $gray2;
            border-top: none;
        }
    }
    tbody {
        td {
            margin: 0;
            padding: 0;
            padding: 0.75rem;
            // border: 1px 1px 1px 1px solid red;
            border-bottom: 0px;
            border-left: 1px solid $gray1;
            border-right: 1px solid $gray1;
            color: $gray3;
            a {
                color: $gray3;
                //                 font-size: 1.2rem;
                text-decoration: none !important;
                transform-origin: center;
            }
        }
        tr {
          td {
            a {
              i {
                font-size: 1.5rem;
              }
            }
          }

          .td-permissions {
            padding: 10px 20px;
          }
            // border-bottom: 1px solid $gray1;
            &:hover:not(.active),
            &.selected:not(.active) {
                background-color: $gray1;
                border-radius: 10px;
                // border-bottom: 1px solid $gray1;
                td {
                    color: $gray4;
                    a {
                        i {
                            color: $gray4;
                            transform: scale(1);
                        }
                    }
                }
            }

          &:hover:not(.active) {
            .td-hover {
              border-radius: 10px 0px 0px 10px;
            }
          }
            &.selected {
                position: relative;
                td {
                    &:first-child {
                      border-radius: 10px 0px 0px 10px;
                    }
                }
                &:after {
                    background-color: $soft-blue;
                    position: absolute;
                    content: "";
                    height: 1.8rem;
                    margin-top: 7px;
                    transform: translateX(-3px);
                    width: 4px;
                    right: 0;
                }
            }
        }
      .non-hover {
        &:hover:not(.active) {
          background-color: unset;
          border-radius: 0px;

          td:first-child {
            border-radius: 0;
          }
        }
      }
    }
    &.no-border-top {
        thead {
            border-top: 0;
          border-bottom: 1px solid $gray2;
        }
    }
    &__image {
        border-radius: 50%;
        height: 2rem;
        margin: 0 auto;
        width: 2rem;
    }
}

.table_properties {
    thead {
        th {
            border: 1px solid $gray2;
            border-top: none;
        }
    }
    tbody {
        td {
            margin: 0;
            padding: 0;
            padding: 8px 0rem;
            // border: 1px 1px 1px 1px solid red;
            border-bottom: 0px;
            border-left: 1px solid $gray1;
            border-right: 1px solid $gray1;
            a {
                color: $gray2;
                //                 font-size: 1.2rem;
                text-decoration: none !important;
                transform-origin: center;
            }
        }
        tr {
            // border-bottom: 1px solid $gray1;
            &:hover:not(.active),
            &.selected:not(.active) {
                background-color: $gray1;
                // border-bottom: 1px solid $gray1;
                td {
                    color: $gray4;
                    a {
                        i {
                            transform: scale(1.1);
                        }
                    }
                }
            }
            &.selected {
                position: relative;
                td {
                    &:first-child {
                        background-color: $gray2;
                    }
                }
                &:after {
                    background-color: $gray2;
                    position: absolute;
                    content: "";
                    height: 1.8rem;
                    margin-top: 7px;
                    transform: translateX(-3px);
                    width: 4px;
                }
            }
        }
    }
    &.no-border-top {
        thead {
            border-top: 0;
        }
    }
    &__image {
        border-radius: 50%;
        height: 2rem;
        margin: 0 auto;
        width: 2rem;
    }
}

.table-responsive {
    display: block;
    width: 100%;
    // height: 70vh;
    overflow-x: auto;
    // overflow: scroll;
    -webkit-overflow-scrolling: touch;
}

.table-responsive>.table-bordered {
    // border-left: 1px solid $gray1;
    // border-right: 1px solid $gray1;
    th {
        border: 0px solid $gray1;
        color: $primary-color;
        // border-bottom: 1px solid $gray1;
        border-bottom: 1px solid $gray2;
        border-top: 1px solid $gray2;
    }
    td {
        border-bottom: 0px solid $gray2;
        border-top: 0px solid $gray2;
        border-left: 0px solid $gray1;
        border-right: 0px solid $gray1;

      a {
        i {
          font-size: 1.5rem;
        }
      }
    }
}
