@include media-breakpoint-down(sm) {
	#bg-login {

		.column-1{
			height: 80vh;
			width: 100%;
		}

		.column-2{
			height: 20vh;
			width: 100%;
			top: 80%;
		}
	}
	.d-xs-none{
        display: none;
    }
}

@include media-breakpoint-down(md) {

}

@include media-breakpoint-down(lg) {

}

@include media-breakpoint-down(xl) {

}

@include media-breakpoint-down(xxl) {

}


@include media-breakpoint-up(sm) {
	.content-info{
		span{
			font-size: 2.5rem !important;
		}
		h2{
			font-size: 1.6rem;
		}
	}

	.form-content{
		display: flex;
	}

}

@include media-breakpoint-up(md) {
  #bg-login {
    .site-data{
      min-height: 100vh;
    }
  }
}

@media (max-width: 768px){
	bs-daterangepicker-container{
		transform: translate3d(-17px, 418px, 0px) !important;
	}
}


