.inn-form {

  .form-group {
    margin-bottom: 0.5rem;

    label {
      color: $primary-color;
      font-size: map-get($font-sizes, x-small);
      margin-bottom: 0;
    }

    .form-control {
      background-color: $bg-input;
      border-color: $bg-input;
      border-radius: $border2;
      font-size: $body-size;
      height: $hg-input;
    }
  }

  &--filters {
    .form-group {
      .form-control {
        background-color: $gray1;
      }
    }
  }
}

.ck-editor__editable_inline {
  min-height: 500px;
}

/* Hide the browser's default checkbox */
.container-check {
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-bottom: 12px;
  padding-left: 28px;
  padding-top: 1px;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover input ~ .checkmark {
    background-color: #fff;
    box-shadow: 0px 4px 10px #0000001F;
  }

  /* Create a custom checkbox */
  .checkmark {
    background-color: $bg-input;
    border-radius: 4px;
    height: 20px;
    left: 0;
    position: absolute;
    top: 1px;
    width: 20px;

    &:after {
      border: solid white;
      border-width: 0 2px 2px 0;
      content: "";
      display: none;
      height: 12px;
      left: 7px;
      position: absolute;
      top: 2px;
      width: 5px;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  input {
    cursor: pointer;
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;

    &:checked ~ .checkmark {
      background-color: $primary-color;

      &:after {
        display: block;
      }
    }
  }
}

.onoffswitch {
  position: relative;
  width: 35px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.onoffswitch-checkbox {
  opacity: 0;
  position: absolute;
  pointer-events: none;
}

.onoffswitch-label {
  border-radius: 18px;
  cursor: pointer;
  display: block;
  overflow: hidden;
}

.onoffswitch-inner {
  display: block;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
  width: 200%;
}

.onoffswitch-inner:before, .onoffswitch-inner:after {
  box-sizing: border-box;
  color: white;
  display: block;
  font-family: Trebuchet, Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  float: left;
  height: 18px;
  line-height: 18px;
  padding: 0;
  width: 50%;
}

.onoffswitch-inner:before {
  content: "";
  padding-left: 29px;
  background-color: rgba($primary-color, 15%);
}

.onoffswitch-inner:after {
  background-color: rgba($gray3, 30%);
  content: "";
  padding-right: 29px;
  text-align: right;
}

.onoffswitch-switch {
  background: $gray3 !important;
  border-radius: 18px;
  bottom: 0;
  display: block;
  height: 17px;
  position: absolute;
  right: 18px;
  top: 0;
  transition: all 0.3s ease-in 0s;
  width: 17px;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  background-color: $primary-color !important;
  right: 0px;
}
