@media (max-width:1024px) {
    .medidor_landing {
        .text_quality_face p {
            font-size: 2.5rem;
        }
    }
    .p_description {
        margin-bottom: 2rem;
    }
    .medidor .text_quality_face p {
        font-size: 2rem;
    }
}

@media (max-width:991px) {
  .back {
    margin-top: 72px;
  }
}

@media (max-width:768px) {
    .data {
        background: #0F4561;
        z-index: 0;
        height: 100%;
        width: 100%;
        position: relative;
        display: flex;
    }
    .back {
        margin-top: 72px;
    }
    .map {
        height: 500px;
        float: unset;
        position: static;
        left: unset;
        width: 100%;
        padding: 0 20px;
        background-color: $black-blue;
        border-radius: 0px;
    }
    .medidor_landing {
        min-height: 500px;
        height: auto;
        max-height: unset;
        width: auto;
        float: unset;
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        margin: 0 20px;
    }
    .ul_map {
        position: static;
        left: unset;
    }
    .face_map_landing {
        float: unset;
        left: 7em;
        top: -5em;
    }
    .cloud_map {
        float: unset;
        left: 0;
        width: 94px;
        height: 63px;
    }
    .city {
        .section-form {
            .contact_text {
                .about-title {
                    font-size: 3rem;
                }
                p {
                    font-size: 1rem;
                }
            }
        }
    }
    .city .section-form .btn-send-form {
        width: 100%;
    }
    .data .smock {
        width: 400px;
        height: 400px;
    }
    .data {
        padding-top: 100px !important;
    }
    .data {
        .container {
            .monitor {
                padding-top: 200px !important;
            }
        }
    }
    .data .particle {
        bottom: unset;
        right: -152px;
        left: unset;
        top: 10%;
    }
    .opinion .circle {
        width: 200px;
        height: 200px;
        top: -4%;
        position: absolute;
        left: 11%;
    }
    .opinion .material_title p {
        font-size: 1.8rem;
    }
    .opinion .material_text p {
        font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
        font-size: 1.1rem;
    }
    .opinion .material_title {
        top: 185%;
        width: 100%;
        left: 0%;
        p {
            text-align: center !important;
        }
    }
    .opinion .material_text {
        width: 100%;
        top: 206%;
        left: 0;
        padding: 0 15px;
        p {
            text-align: center !important;
        }
    }
    .opinion .circle_2 {
        width: 100%;
        height: 35%;
        top: 231%;
        position: absolute;
        right: 24%;
    }
    .opinion .particle {
        width: 300px;
        height: 300px;
        bottom: -12%;
        position: absolute;
        right: 9%;
    }
    .testimonials {
        img {
            width: 50% !important;
        }
    }
}

@media (max-width:540px) {
    .map {
        height: 500px;
        float: unset;
        position: static;
        left: unset;
        width: 100%;
        padding: 0 20px;
        background-color: $black-blue;
        border-radius: 0px;
    }
    .medidor_landing {
        min-height: 500px;
        height: auto;
        width: 100%;
        float: unset;
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        margin: 0;
    }
    .container_map {
        background-color: $black-blue;
    }
    .medidor_landing .text_quality_face p {
        font-size: 2.3rem;
    }
    .p_description {
        margin-bottom: 2rem;
    }
    .ul_map {
        position: static;
        left: unset;
    }
    .face_map_landing {
        float: unset;
        left: 7em;
        top: -5em;
    }
    .cloud_map {
        float: unset;
        left: 0;
        width: 94px;
        height: 63px;
    }
    .city {
        .section-form {
            .contact_text {
                .about-title {
                    font-size: 3rem;
                }
                p {
                    font-size: 1.2rem;
                }
            }
        }
    }
    .data .smock {
        width: 200px;
        height: 200px;
    }
    .data {
        padding-top: 100px !important;
    }
    .data {
        .container {
            .monitor {
                padding-top: 200px !important;
            }
        }
    }
    .data .particle {
        bottom: unset;
        right: -206px;
        left: unset;
        top: 10%;
    }
    .opinion .circle {
        width: 200px;
        height: 200px;
        top: 9%;
        position: absolute;
        left: 16%;
    }

  .back .container-know-us {
    margin-top: 0px;
  }
}
