.container-drawer-notifications {
  transform: translateX(150%);

  .drawer-notifications {
    .drawer-header {
      padding: 20px 25px;
      background-color: $secondary-color;

      .txt-title {
        color: $base-color;
      }
    }

    .info-notifications {
      border-bottom: 1px solid $gray4;
      padding: 20px 25px;

      .title-info-notifications {
        color: $black;
      }

      .txt-info-notifications {
        color: #989898;
      }
    }

    .la-long-arrow-alt-right, .la-map {
      background: $gray1;
      border-radius: $border1;
      padding: 3px;
      font-size: 1.5rem;
      color: $black;
    }
  }
}

.container-drawer-notifications.active {
  transform: translateX(15.5%);
}

.container-drawer-notifications .drawer-overlay {
  transform: translateX(-15.5%);
}
