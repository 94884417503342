.bg-error-found{
    background-color: #739FCB;
    height: 100vh;
    overflow: hidden;
    background-image: url(../../assets/images/error-404.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 0em 50%;

    .content{
        background-color: rgba($color: #4C4C9F, $alpha: 0.15);
        border-radius: 50%;
        height: 100vh;
        align-items: center;
        text-align: center;

        .content-info{
            position:relative;
            top: 45%;

            h1{
                font-size: 4rem;
                letter-spacing: 2px;
            }

            h6{
                font-size: 0.7rem;
                width: 70%;
                margin: auto;
            }
        }
    }

}

.bg-error-service{
    background-color: #0F4561;
    height: 100vh;
    overflow: hidden;

   .content{
    position: relative;
    top: 20%;

    img{
        width: 100%;
        position: relative;
        bottom: 3rem;
    }

    .error{
        font-size: 4rem;
        position: absolute;
        bottom: 0;
        color: #ACACEA;
        opacity: 0.15;
    }
    .color{
        width: 100%;
        height: 100%;
        background-color: #ACACEA;
        opacity: 0.15;
    }

    h6{
        font-size: 0.8rem;
        width: 50%;
    }
   }

}