header {
	align-items: center;
    background-color: #fff;
    border-radius: $border2;
    box-shadow: $shadow1;
    display: flex;
    height: $header-height;
    justify-content: space-between;
    left: calc(#{$menu-width} + 2rem);
    padding: 0 2rem;
    position: fixed;
    top: 1.5rem;
    transition: all 1s;
    width: calc(100% - #{$menu-width} - 4rem);
    z-index: 3;

    .user-menu {
    	align-items: center;
        background-color: $gray1;
        border-radius: $border2;
    	cursor: pointer;
    	display: flex;
        padding-right: 1rem;
    	position: relative;

    	img {
    		border-radius: 50%;
    		height: 30px;
            object-fit: cover;
    	}

        p{
            color: $primary-color;
        }

        .user-icon{
            align-items: center;
            background-color: $primary-color;
            border-radius: $border2;
            display: flex;
            height: 2.2rem;
            justify-content: center;
            margin-right: 0.5rem;
            width: 2.2rem;

            i{
                color: $white;
                font-size: 1.5rem;
            }
        }

    	.dropdown-profile {
    		background-color: $base-color;
    		box-shadow: $shadow1;
    		border-radius: $border1;
    		display: none;
    		max-width: 100vw;
    		padding: 2rem 1.2rem;
    		position: absolute;
    		right: -25px;
    		top: calc(100% + 25px);
    		transition: all 1s;
    		width: 250px;

    		&.open {
    			display: block;
    		}

            .user-icon{
                align-items: center;
                display: flex;
                height: 100px;
                justify-content: center;
                margin: 0 auto;
                width: 100px;

                i{
                    font-size: 67px;
                }
            }

    		img {
    			height: auto;
    			width: 40%;
    		}

    		&:before {
			    border-color: transparent transparent #fff transparent;
			    border-style: solid;
			    border-width: 0 15px 12px 15px;
    			content: "";
			    position: absolute;
			    right: 25px;
			    top: -12px;
    		}

    		.btn-profile {
			    align-items: center;
    			border-radius: 2px;
			    border: 1px solid;
			    box-shadow: none;
			    color: $gray1;
			    display: flex;
			    font-weight: 100;
			    justify-content: center;
    		}
    	}
    }

    &.aside-xs {
        left: calc(#{$menu-width-xs} + 2rem);
        width: calc(100% - #{$menu-width-xs} - 4rem);
	}
}

@media (max-width: 575.98px) {
    header {
        &.aside-xs {
            p:first-child {
                visibility: hidden;
            }
        }
    }
}
