.partners {
  .circle {
    width: 200px;
    height: 200px;
    top: 0;
    left: 16%;
    z-index: 1;
  }

  .background-image {
    z-index: 0;
  }

  .container-info {
    margin-top: 350px;
    z-index: 1;

    .testimonials {
      .opinion_title {
        color: $black-blue;
        font-size: 1.5rem;
        margin: auto;
        width: 60%;
      }
      .lead {
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        font-size: 1.4rem;
        max-width: 355px;
      }
    }

    h3 {
      color: $black-blue;
    }
  }
}

@media (max-width:768px) {
  .partners {
    .container-info {
      h3 {
        font-size: 3rem;
      }

      .testimonials {
        .opinion_title {
          font-size: 1.8rem;
        }
        .lead {
          font-size: 1.2rem;
        }
      }
    }
  }
}
