.particle {
  margin-top: 120px;
  height: 580px;

  .img-particle {
    width: 300px;
    height: 300px;
    right: 0;
    top: -230px;
    z-index: 1;
  }

  .cloud {
    width: 75%;
    min-width: 1000px;
  }

  .circle {
    width: 200px;
    height: 200px;
    bottom: -133px;
    left: 193px;
  }

  .container-info {
    padding-top: 150px;

    .material_title {
      margin-left: 150px;
      margin-bottom: 100px;

      p:first-child {
        font-size: 3rem;
      }

      p:last-child {
        font-size: 4rem;
      }
    }

    .material_text {
      p {
        font-size: 1.3rem;
        max-width: 360px;
      }
    }
  }
}

@media (max-width:768px) {
  .particle {
    .circle {
      bottom: -215px;
      left: 117px;
    }

    .container-info {

      .material_title {
        margin-left: 0;
      }
    }
  }
}

@media (max-width:540px) {
  .particle {

    .container-info {
      .material_title {
        p:first-child {
          font-size: 1.8rem;
        }

        p:last-child {
          font-size: 2.5rem;
        }
      }

      .material_text {
        p {
          font-size: 1.2rem;
        }
      }
    }
  }
}
