.box {
    background-color: $white;
    border-radius: $border3;
    //overflow: hidden;
    &--permissions {
        &__permission {
            align-items: center;
            border-radius: $border1;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            padding: 10px 20px;

          .la-angle-down {
            transform: rotate(-90deg);
          }
        }
        &__list {
            display: none;
            list-style: none;
            margin: 5px 1.5rem;
            padding: 5px 0;
            li {
                display: flex;
                justify-content: space-between;
                position: relative;
                &:before {
                    background-color: $gray2;
                    bottom: 5px;
                    content: "";
                    height: 1px;
                    left: 5%;
                    position: absolute;
                    width: 92%;
                }
                .permission-name,
                .onoffswitch {
                    background-color: $base-color;
                    z-index: 1;
                }
                .permission-name {
                    padding-right: 5px;
                }
            }
        }
        .active {
            .box--permissions__permission {
                background-color: $gray1;
                color: $gray4;

              .la-angle-down {
                transform: rotate(0deg);
              }
            }
            .box--permissions__list {
                display: block;
            }
        }
    }
    &--pagination {
        align-items: center;
        display: flex;
        justify-content: space-between;
        span {
            width: 7rem;
            white-space: nowrap;
        }
    }

  .box--permissions__permission:hover {
    background-color: $gray1;
    border-radius: 10px;
    padding: 10px 20px;
    color: $gray4;
  }
}
